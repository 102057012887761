import { Injectable } from '@angular/core';
import { menuPages } from '../../app/navigation/navigation.model';
import { Feature, FeatureFlagService } from './../feature-flag/feature-flag.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private featureFlagService: FeatureFlagService) {}

  public getDefaultRoute(): string | null {
    for (const page of menuPages) {
      const isPageAllowed = page?.data?.featureFlags.every((feature: Feature) => {
        return this.featureFlagService.isFeatureEnabled(feature);
      });
      if (isPageAllowed) {
        return page.path;
      }
    }
  }
}
