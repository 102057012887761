import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActionButton } from '@shiftpixy/data';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pixy-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionButtonComponent implements OnDestroy, AfterViewInit {
  @Input() public actions: ActionButton[];
  @Input() public appearance: 'outline' | 'filled' = 'outline';
  @Input() public size: 'giant' | 'medium' = 'medium';
  @Input()
  set disabled(disabled: boolean) {
    disabled ? this.action.disable() : this.action.enable();
  }
  @Input() public type: 'submit' | 'button' = 'button';
  public action: FormControl = new FormControl('');
  public CONFIRM_TIMEOUT = 1750;
  public completed = false;
  public completedText: string;
  private subscription: Subscription;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    if (!this.actions) this.action.disable();
    this.cdr.detectChanges();
  }

  public endAction(): void {
    this.completed = !this.completed;
    this.completedText = this.action.value.completed?.text || 'Complete!';
    this.action.reset();
    this.cdr.detectChanges();
    setTimeout(() => {
      this.completed = !this.completed;
      this.cdr.markForCheck();
    }, this.CONFIRM_TIMEOUT);
  }

  public doAction(selection: ActionButton): void {
    (window as any).dataLayer.push({
      event: 'Click',
      action: 'click',
      label: selection.value,
      category: 'action-button',
    });

    this.action.setValue(selection);
    this.action.markAsTouched();

    this.subscription = selection.callback().subscribe(
      () => {
        this.endAction();
      },
      () => {
        this.completed = false;
        this.action.reset();
        this.cdr.markForCheck();
      }
    );
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
