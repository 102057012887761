export const environment = {
  production: false,
  apiUrl: 'https://temp-api.shiftpixy.com/dev/',
  newApiUrl: 'https://dev.shiftpixy.io/',
  humanityApiURL: 'https://dev-humanity.shiftpixy.io/',
  humanityURL: 'https://shiftpixydev.humanity.com/',
  bgCheckApiURL: 'https://dev-turning.shiftpixy.io/',
  onboardingApiURL: 'https://shiftpixy-onboarding-staging.shiftpixy.dev/',
  globalExportApiURL: 'https://shiftpixy-admin-data-exporter-staging.shiftpixy.dev/',
  adminApiCluster: 'https://stg-admin-api.shiftpixy.dev/',
  auth: {
    providers: {
      cognito: {
        customProvider: 'AzureAD',
        apiUrl: 'https://cognito-idp.us-west-2.amazonaws.com/',
        identityPoolId: 'us-west-2:c577f596-4889-43e4-a630-791830ff805d',
        region: 'us-west-2',
        userPoolId: 'us-west-2_bGfGW2V2k',
        userPoolWebClientId: '1gs7p3mtdo36odsom8450qcqpf',
        mandatorySignIn: false,
        authenticationFlowType: 'CUSTOM_AUTH',
        oauth: {
          domain: 'shiftpixy.auth.us-west-2.amazoncognito.com',
          scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: 'https://dev-admin.shiftpixy.com/',
          redirectSignOut: 'https://dev-admin.shiftpixy.com/',
          responseType: 'token',
        },
      },
    },
    featureFlagEndpoint: 'https://gitlab.com/api/v4/projects/17309824/feature_flags?per_page=50',
    featureFlagInstanceId: 'q-MW77AmeGVzbVxh54do',
  },

  agGridKey:
    'CompanyName=ShiftPixy Inc,LicensedGroup=SPX,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=0,AssetReference=AG-011645,ExpiryDate=6_November_2021_[v2]_MTYzNjE1NjgwMDAwMA==2a544785934991ff32303f6a879aa801',
};
