import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NbLayoutScrollService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Shifter, Theme } from '@shiftpixy/data';
import { UtilsService } from '@shiftpixy/utils';
import { Observable, of } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { StorageService } from '../services/storage/storage.service';
import { AuthService } from './auth/services/auth.service';
import { ClientHttpService } from './clients/clients-http/clients-http.service';
import { ShiftersHttpService } from './shifters/shifters-http/shifters-http.service';
import { TimesheetHttpService } from './timesheets/timesheets-http/timesheets-http.service';

@UntilDestroy()
@Component({
  selector: 'shiftpixy-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  public isMobile = this.utilsService.isMobile();
  private theme: Theme;
  public client: any;
  public backButtonObject: {
    text: string;
    route: string;
  };
  public timesheetEnabled: boolean;
  public routes = {
    clientsDetails: '',
    clientsPage: this.clientService.getClientsPageRoute(),
    timesheetsPage: this.timesheetsService.getTimesheetsPageRoute(),
    benefitsPage: '/benefits',
    globalShiftersPage: '/global-shifters',
  };
  public searchClientsField = new FormControl('');
  public searchDisplayMessage = '';
  public foundShifters$: Observable<Shifter[]> = of([]);
  public searchShiftersParams = {
    sort: 'firstName',
    name: '',
    limit: 0,
    order: 'asc',
    page: 1,
  };

  constructor(
    private themeService: NbThemeService,
    public authService: AuthService,
    private router: Router,
    public storage: StorageService,
    private cdr: ChangeDetectorRef,
    private shiftersService: ShiftersHttpService,
    private scrollService: NbLayoutScrollService,
    private clientService: ClientHttpService,
    private timesheetsService: TimesheetHttpService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private sidebarService: NbSidebarService,
    private utilsService: UtilsService
  ) {
    this.theme = this.storage.getTheme() || Theme.Light;
    this.themeService.changeTheme(this.theme);

    this.router.events.subscribe(() => {
      this.storage.setCachedRoute(this.location.path());
    });

    this.activatedRoute.fragment.pipe(untilDestroyed(this)).subscribe((fragment: string) => {
      this.authService.validateAzureLogin(fragment);
    });
  }

  public ngOnInit(): void {
    this.storage.clientSet.pipe(untilDestroyed(this)).subscribe((response) => {
      this.client = null;
      if (response) {
        this.getClient()
          .pipe(untilDestroyed(this))
          .subscribe((res: any) => {
            this.client = res;
            this.routes.clientsDetails = this.clientService.getClientsDetailRoute(this.getClientId());
          });
      }
    });
    this.foundShifters$ = this.searchClientsField.valueChanges.pipe(
      debounceTime(300),
      untilDestroyed(this),
      switchMap(() => this.shiftersService.searchList(this.getSearchShiftersParams())),
      map((res) => this.updateShiftersResults(res))
    );

    this.storage.backButtonSet.pipe(untilDestroyed(this)).subscribe((response) => {
      this.backButtonObject = response;
    });
  }

  public ngAfterViewInit(): void {
    this.router.events.pipe(untilDestroyed(this)).subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.cdr.detectChanges();
        this.scrollService.scrollTo(0, 0);
      }
    });

    if (this.storage.hasClientId()) {
      this.storage.setClientId(this.storage.getClientId());
    }
    this.cdr.detectChanges();
  }

  public toggleTheme(): void {
    this.theme = this.theme === Theme.Light ? Theme.Dark : Theme.Light;
    this.themeService.changeTheme(this.theme);
    this.storage.setTheme(this.theme);
  }

  public logOut(): void {
    this.authService.logout();
  }

  public getClient(): Observable<any> {
    return this.clientService.getDetail(this.storage.getClientId()).pipe(map((response) => response.message));
  }

  public getClientId() {
    return this.storage.getClientId();
  }

  private getSearchShiftersParams(): any {
    this.searchDisplayMessage = 'Searching...';
    const searchQuery = this.searchClientsField.value as string;
    const name = searchQuery.trim().length >= 3 ? searchQuery : null;
    return { ...this.searchShiftersParams, name: name };
  }

  private updateShiftersResults(response: any) {
    const shifters = response.shifters;
    this.searchDisplayMessage = '';
    if (this.searchClientsField.value.length) {
      this.searchDisplayMessage = shifters.length
        ? `Shifters that match "${this.searchClientsField.value}"`
        : `No results found for "${this.searchClientsField.value}"`;
    }
    return shifters;
  }

  public onShifterClick(shifter: any, event: any) {
    let route;
    switch (shifter.type) {
      case 'Transitional':
        route = `shifters/${shifter._id}`;
        break;
      case 'Organic':
        route = `global-shifters/organic/${shifter._id}`;
        break;
      case 'Legacy':
        route = `global-shifters/legacy-shifters/${shifter._id}`;
        break;
    }

    const openInNewTab = event.metaKey || event.ctrlKey;
    openInNewTab ? window.open(route) : this.router.navigate([route]);
    this.searchClientsField.setValue('');
    this.collapseSidebar();
  }

  public isDarkTheme() {
    return this.theme === Theme.Dark;
  }

  public toggleSidebar() {
    this.sidebarService.toggle();
  }

  public collapseSidebar() {
    this.sidebarService.collapse();
  }
}
