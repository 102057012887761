import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ChangeDetectorRef,
  HostListener,
  forwardRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { FilterOption } from '@shiftpixy/data';
import { NbPopoverDirective } from '@nebular/theme';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'pixy-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterButtonComponent),
      multi: true,
    },
  ],
})
export class FilterButtonComponent implements ControlValueAccessor {
  @Input() public filters: FilterOption[];
  @ViewChild(NbPopoverDirective, { static: false }) public popover: NbPopoverDirective;
  @Output() public filterSelected: EventEmitter<{ [index: number]: FilterOption }> = new EventEmitter();
  public icon = 'arrow-ios-downward-outline';
  public selectedFilters: { [index: number]: FilterOption } = {};

  constructor(private cdr: ChangeDetectorRef) {}

  @HostListener('document:click', ['$event.target'])
  public handleClick(target: HTMLElement): void {
    if (
      !target.closest('[nbpopovertrigger="noop"]') &&
      !target.closest('.eva-arrow-ios-downward-outline') &&
      !target.closest('.cdk-overlay-pane.filter') &&
      this.popover.isShown
    ) {
      this.togglePopover();
    }
  }

  public selectFilter(filter: FilterOption): void {
    if (filter.selected && !this.selectedFilters[filter.index]) {
      this.selectedFilters[filter.index] = filter;
    } else {
      delete this.selectedFilters[filter.index];
    }
  }

  public setFilter(filter: FilterOption): void {
    filter.selected = !filter.selected;
    this.selectFilter(filter);

    this.propagateChange(this.selectedFilters);
  }

  public togglePopover(event?: Event): void {
    (window as any).dataLayer.push({
      event: 'Click',
      action: 'toggle',
      category: 'filter-button',
      label: `Is Shown: ${this.popover.isShown}`,
    });

    if (event) {
      event.preventDefault();
    }

    if (this.popover.isShown) {
      this.popover.hide();
      this.icon = 'arrow-ios-downward-outline';
      this.filterSelected.emit(this.selectedFilters);
      this.cdr.detectChanges();
      return;
    }

    this.popover.show();
    this.icon = 'arrow-ios-upward-outline';
  }

  public propagateChange = (_: any) => {};

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(): void {}

  public writeValue(selectedFilters: FilterOption[]): void {
    if (selectedFilters.length) {
      selectedFilters.forEach((filter) => this.selectFilter(filter));
    }
  }
}
