<textarea
  nbInput
  fullWidth
  #addressInput
  [formControl]="formControl"
  [formlyAttributes]="field"
  [placeholder]="to.placeholder"
  type="text"
  [status]="showError ? 'danger' : 'primary'"
  [attr.autocomplete]="'new-password'"
  onfocus="this.setAttribute('autocomplete', 'new-password');"
></textarea>
