import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WrapperComponent extends FieldWrapper implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: 'Scheduler Admin Event',
      category: this.field.type,
      action: 'formly-init',
      label: this.field.key,
    });
  }
}
