import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbMenuService } from '@nebular/theme';
import { UntilDestroy } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { Feature, FeatureFlagService } from '../../services/feature-flag/feature-flag.service';
import { StorageService } from '../../services/storage/storage.service';
import { MenuPage, menuPages } from './navigation.model';

@UntilDestroy()
@Component({
  selector: 'shiftpixy-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements OnInit {
  public rootMenuPages: MenuPage[];

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private nbMenuService: NbMenuService,
    private featureFlagService: FeatureFlagService,
    public storage: StorageService
  ) {}

  ngOnInit() {
    this.featureFlagService.updated.subscribe((updated) => {
      if (updated) {
        this.updateRootMenuPages();
      }
    });

    this.storage.adminEmailSet.subscribe(() => {
      this.updateRootMenuPages();
    });

    this.nbMenuService
      .onItemClick()
      .pipe(map(({ item }) => item))
      .subscribe((item: any) => this.navigate(`${item.path}`));
  }

  navigate(route: string) {
    return this.router.navigateByUrl(route);
  }

  private updateRootMenuPages() {
    this.rootMenuPages = menuPages
      .filter((page) => {
        if (page.children) {
          return page?.children
            .map((child) => child.data?.featureFlags)
            ?.some((featureFlag: Feature) => this.featureFlagService.isFeatureEnabled(featureFlag));
        }

        return page?.data?.featureFlags?.every((featureFlag: Feature) =>
          this.featureFlagService.isFeatureEnabled(featureFlag)
        );
      })
      .filter((page: MenuPage) => page.title)
      .map((menuPage: MenuPage) => {
        if (menuPage.children) {
          const filteredChildren = menuPage.children.filter((child) => {
            return child?.data?.featureFlags?.every((featureFlag: Feature) =>
              this.featureFlagService.isFeatureEnabled(featureFlag)
            );
          });
          return {
            ...menuPage,
            children: filteredChildren.map((page: MenuPage) => {
              return { ...page, path: menuPage.path + '/' + page.path };
            }),
          };
        }

        return menuPage;
      });

    this.cdr.detectChanges();
  }
}
