import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { single } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { StorageService } from './../storage/storage.service';

export const enum Feature {
  ShifterExportCSV = 'shifter_export_csv',
  OfferLetterReport = 'offer_letter_report',
  ClientsTab = 'clients_tab',
  BenefitsTab = 'benefits_tab',
  TimesheetsTab = 'timesheets_tab',
  TimesheetsTab2 = 'timesheets_v2',
  GlobalManagersTab = 'global_managers_tab',
  GlobalShiftersTab = 'global_shifters_tab',
  OrganicShiftersTab = 'organic_shifters_tab',
  GlobalWorksitesTab = 'global_worksites_tab',
  MessageLogsTab = 'message_logs_tab',
  NewClientsEndpoint = 'node_clients_endpoint',
  NewManagersEndpoint = 'node_managers_endpoint',
  NewWorksitesEndpoint = 'node_worksites_endpoint',
  NewEmbeddedSigningEndpoint = 'node_embeddedsigning_endpoint',
  VaccineMandateDocs = 'vaccine_mandate_docs',
  VaccineMandateToggle = 'vaccine_mandate_toggle',
  NewShifterPutEndpoint = 'node_shifter_put_endpoint',
  CertificatesTab = 'certificates_tab',
  VideoProfileTab = 'video_profile_tab',
  VideoProfileActions = 'video_profile_actions',
  LegacyShiftersTab = 'legacy_shifters_tab',
}

const enum Strategy {
  AllUsers = 'default',
  UserWithId = 'userWithId',
}

export interface FeatureFlag {
  active: boolean;
  [Strategy.AllUsers]?: {
    enabled: boolean;
    userIds: string[];
  };
  [Strategy.UserWithId]?: {
    enabled: boolean;
    userIds: string[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private featureFlagsInitialized = false;
  private features: { [id: string]: FeatureFlag } = {};
  public updated: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private http: HttpClient, private storage: StorageService) {}

  public setFeatureFlags() {
    if (!this.featureFlagsInitialized) {
      this.featureFlagsInitialized = true;
      this.http
        .get(environment.auth.featureFlagEndpoint, {
          headers: { 'Private-Token': environment.auth.featureFlagInstanceId },
        })
        .pipe(single())
        .subscribe((res: any) => {
          res.forEach((feature: any) => this.setFeature(feature));
          this.updated.next(true);
        });
    }
  }

  public setFeature(feature: any) {
    const featureName: string = feature.name;
    this.features[featureName] = { active: feature.active };
    feature.strategies.forEach((strategy: any) => {
      const enabled = (strategy.scopes?.map((scope: any) => scope.environment_scope) || []).includes('enabled');
      const userIds = (strategy.parameters?.userIds?.split(',') || []).map((userId: string) => userId.toLowerCase());
      this.features[featureName][strategy.name as Strategy] = { enabled, userIds };
    });
  }

  public isFeatureEnabled(featureName: Feature) {
    if (this.featureFlagsInitialized && Object.keys(this.features).length) {
      const feature: FeatureFlag = this.features[featureName];
      return feature?.active && (this.allUsersIsEnabled(feature) || this.userWithIdIsEnabled(feature));
    }
    return false;
  }

  private allUsersIsEnabled(feature: FeatureFlag): boolean {
    return feature.hasOwnProperty(Strategy.AllUsers) && feature[Strategy.AllUsers].enabled;
  }

  private userWithIdIsEnabled(feature: FeatureFlag): boolean {
    const adminEmail = this.storage.getAdminEmail()?.toLowerCase();
    return (
      feature.hasOwnProperty(Strategy.UserWithId) &&
      feature[Strategy.UserWithId].enabled &&
      feature[Strategy.UserWithId].userIds.includes(adminEmail)
    );
  }
}
