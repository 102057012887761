<div class="mab-wrapper">
  <nb-select
    *ngIf="actions === undefined || actions.length > 1"
    id="mab-select"
    placeholder="Actions"
    [appearance]="appearance"
    [size]="size"
    status="control"
    shape="rectangle"
    status="primary"
    [formControl]="action"
  >
    <nb-option *ngFor="let action of actions" [value]="action" (click)="doAction(action)" [disabled]="action.disabled">
      <div class="d-flex align-items-center justify-content-between">
        {{ action.text }}
        <nb-icon [icon]="action.icon"></nb-icon>
      </div>
    </nb-option>
  </nb-select>

  <button
    *ngIf="actions && actions.length === 1"
    nbButton
    fullWidth
    [appearance]="appearance"
    [size]="size"
    (click)="doAction(actions[0])"
    [formControl]="action"
    status="primary"
    ngDefaultControl
    [type]="type"
    [disabled]="actions[0].disabled || false"
  >
    <nb-icon *ngIf="actions[0].icon" [icon]="actions[0].icon"></nb-icon>
    {{ actions[0].text }}
  </button>

  <!-- Action Status -->
  <div *ngIf="action.value || completed" class="pending" [ngClass]="{ completed: completed }">
    <span class="status">{{ completed ? completedText : action.value?.pending?.text || 'Waiting...' }}</span>
    <nb-icon *ngIf="action.value?.pending?.icon" [icon]="action.value.pending.icon"></nb-icon>
  </div>
</div>
