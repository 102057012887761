<ng-container *ngFor="let step of steps; let i = index; let last = last">
  <div class="step" [ngClass]="{ current: isCurrent(i, last), complete: isComplete(i, last) }">
    <div class="bubble" [ngClass]="{ current: isCurrent(i, last), complete: isComplete(i, last) }">
      <div class="step-popover" *ngIf="step.src && i === currentStep">
        <img width="60px" src="../../assets/{{ step.src }}" [alt]="step.title" />
        <p>{{ step.description }}</p>
      </div>
      <nb-icon
        status="control"
        [icon]="
          isCurrent(i, last) ? 'loader-outline' : isComplete(i, last) ? 'checkmark-outline' : 'minus-circle-outline'
        "
      ></nb-icon>
    </div>
    <div>
      <span class="title">{{ step.title }}</span>
      <p class="paragraph-2 timestamp">
        <label class="caption-2 text-hint" *ngFor="let meta of getMetaTimestamp(i)">
          {{ meta.timestamp | date: "MM/dd/yyyy '\u25CF' h:mma" }}
          <b>{{ meta.label }}</b>
        </label>
      </p>
    </div>
  </div>
</ng-container>
