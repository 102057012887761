import { environment } from '../../../environments/environment';

const awsConfig = {
  Auth: {
    identityPoolId: environment.auth.providers.cognito.identityPoolId,
    region: environment.auth.providers.cognito.region,
    userPoolId: environment.auth.providers.cognito.userPoolId,
    userPoolWebClientId: environment.auth.providers.cognito.userPoolWebClientId,
    mandatorySignIn: environment.auth.providers.cognito.mandatorySignIn,
    authenticationFlowType: environment.auth.providers.cognito.authenticationFlowType,
    oauth: environment.auth.providers.cognito.oauth,
  },
};

export default awsConfig;
