import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'transformData' })
export class TransformDataPipe implements PipeTransform {
  transform(value: any, callback?: any): string {
    if (typeof callback === 'function') {
      return callback(value);
    }

    return `${value}`;
  }
}
