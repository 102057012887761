import { CdkColumnDef, CdkTable } from '@angular/cdk/table';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DataTableColumn, DataTableSortRequest } from '@shiftpixy/data';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'pixy-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableComponent implements OnChanges, AfterViewChecked {
  @Input() id = 'collection-list';
  @Input() currentPage: number;
  @Input() itemsPerPage: number;
  @Input() totalItems: number;

  @Input() collection: Array<any>;
  @Input() isLoading = true;
  @Input() columns: DataTableColumn[];
  @Input() sortColumn: string;
  @Input() sortDirection: 'asc' | 'desc' = 'asc';

  @Output() rowClick = new EventEmitter<any>();
  @Output() sortChange = new EventEmitter<DataTableSortRequest>();
  @Output() pageChange = new EventEmitter<number>();

  @ViewChild(CdkTable, { static: false }) dataTable: any;
  @ContentChildren(CdkColumnDef) cdkColumns: QueryList<CdkColumnDef>;

  public paginationConfig: PaginationInstance = {
    id: this.id,
    itemsPerPage: 6,
    currentPage: 1,
    totalItems: 0,
  };

  public allColumns: any = [];

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    // if it was in a empty state before reset all the columns
    if (simpleChanges?.collection?.previousValue?.length === 0) {
      this.allColumns = [];
    }

    if (simpleChanges?.id?.currentValue) {
      this.paginationConfig.id = simpleChanges.id.currentValue;
    }

    if (simpleChanges?.itemsPerPage?.currentValue) {
      this.paginationConfig.itemsPerPage = simpleChanges.itemsPerPage.currentValue;
    }

    if (simpleChanges?.totalItems?.currentValue) {
      this.paginationConfig.totalItems = simpleChanges.totalItems.currentValue;
    }

    if (simpleChanges?.currentPage?.currentValue) {
      this.paginationConfig.currentPage = simpleChanges.currentPage.currentValue;
    }
  }

  ngAfterViewChecked() {
    if (!this.dataTable) {
      return;
    }

    this.allColumns = this.columns.map((column) => column.key);
    this.cdkColumns.toArray().forEach((item) => {
      if (this.allColumns.indexOf(item.name) === -1) {
        this.dataTable.addColumnDef(item);
        this.allColumns.push(item.name);
      }
    });

    this.changeDetectorRef.detectChanges();
  }

  public onSortChange(column: DataTableColumn): void {
    if (!column.sortable) {
      return;
    }

    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';

    // if is a different column always start with asc order
    if (this.sortColumn !== column.key) {
      this.sortDirection = 'asc';
    }

    this.sortColumn = column.key;
    this.sortChange.emit({
      column: column.key,
      direction: this.sortDirection,
    });
  }

  public onPageChange(page: number): void {
    // to trigger a change in the pipe. For some reason changeDetector.detectChanges() doesn't do the job
    this.paginationConfig = { ...this.paginationConfig, currentPage: page };
    this.pageChange.emit(page);
  }

  public onRowClick(row: any, event: any): void {
    (window as any).dataLayer.push({
      event: 'Click',
      action: 'click',
      label: row._id,
      openInNewTab: event.metaKey || event.ctrlKey,
      category: 'table-row',
    });
    this.rowClick.emit(row);
  }
}
