import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StatusStep } from '@shiftpixy/data';

@Component({
  selector: 'pixy-status-stepper',
  templateUrl: './status-stepper.component.html',
  styleUrls: ['./status-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusStepperComponent {
  @Input() public type: 'onboarding' | 'offerLetter' | 'backgroundCheck' = 'onboarding';
  @Input() public steps: StatusStep[];
  @Input() public currentStep: number;
  @Input() public i9Verified: boolean;
  @Input() public status: any;
  @Input() public statusTimestamps: any;

  constructor() {}

  public isCurrent(i: number, last: boolean): boolean {
    if (this.type === 'backgroundCheck') {
      const timestamp = this.getMetaTimestamp(i)[0]?.timestamp;
      const backgroundCheckStarted = this.steps.length > 1;
      const approvedStep = this.steps[i].key === 'approved';

      if (!backgroundCheckStarted && timestamp) {
        return true;
      }

      return i === this.currentStep && last && !approvedStep && backgroundCheckStarted;
    }
    return i === this.currentStep && !last;
  }

  public isComplete(i: number, last: boolean): boolean {
    if (this.type === 'backgroundCheck') {
      return !(i === this.currentStep && last) || this.steps[i].key === 'approved';
    }
    if (this.type === 'offerLetter') {
      return i < this.currentStep || (last && this.status?.currentStatus === 'Accepted');
    }

    return (
      i < this.currentStep ||
      (last && this.i9Verified) ||
      (this.status && this.status[this.steps[i].key] === 'Complete')
    );
  }

  public getMetaTimestamp(i: number) {
    return this.statusTimestamps[i].timestamps;
  }
}
