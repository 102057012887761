import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

export const enum MODAL_TYPE {
  FORM = 'form',
}

export const labelMaps = {
  caCcpaAck: 'CCPA Acknowledgment',
  i9: 'Federal I9',
  basicInfo: 'Additional Information',
  residence: 'Address',
  certs: 'Certifications',
  driveAuth: 'Driver Agreement',
  emContacts: 'Emergency Contacts',
  driverInfo: 'Driver Details',
  payPref: 'Payment Preference',
  w4: 'Federal W4',
  reqNotices: 'Notice Acknowledgements',
  alA4: 'Alabama State Tax',
  arAr4ec: 'Arkansas State Tax',
  azA4: 'Arizona State Tax',
  caDe4: 'California State Tax',
  ilIlW4: 'Illinois State Tax',
  maM4: 'Massachusetts State Tax',
  deW4: 'Delaware State Tax',
  hiHw4: 'Hawaii State Tax',
  iaIaW4: 'Iowa State Tax',
  idW4: 'Idaho State Tax',
  inWh4: 'Indiana State Tax',
  ksK4: 'Kansas State Tax',
  kyK4: 'Kentucky State Tax',
  laL4: 'Louisiana State Tax',
  mdMw507: 'Maryland State Tax',
  meW4Me: 'Maine State Tax',
  miMiW4: 'Michigan State Tax',
  mnW4Mn: 'Minnesota State Tax',
  moMoW4: 'Missouri State Tax',
  'ms89-350': 'Mississippi State Tax',
  mtMw4: 'Montana State Tax',
  ncNc4: 'North Carolina State Tax',
  neW4N: 'Nebraska State Tax',
  okOkW4: 'Oklahoma State Tax',
  orOrW4: 'Oregon State Tax',
  riRiw4: 'Rhode Island State Tax',
  scW4: 'South Carolina State Tax',
  vtW4Vt: 'Vermont State Tax',
  wiWt4: 'Wisconsin State Tax',
  wvWvIt104: 'West Virginia State Tax',
  ctCtW4: 'Connecticut State Tax',
  dcD4: 'Washington D.C. Tax',
  ilIlw4: 'Illinois State Tax',
  njNjW4: 'New Jersey State Tax',
  nyIt2104: 'New York State Tax',
  ohIt4: 'Ohio State Tax',
  paResCert: 'Pennsylvania Resident Certificate',
  vaVa4: 'Virginia State Tax',
  td1: 'Canada Federal Tax',
  td1Ab: 'Alberta Province Tax',
  td1On: 'Ontario Province Tax',
  td1Bc: 'British Columbia Province Tax',
  chatbotVersion: 'Chatbot Version',
  defaultLanguage: 'Default Language',
  i9RecoveryChat: 'I-9 Recovery Chat',
  activationCode: 'Worksite Code',
  wantToJoinWaitlist: 'Want to Join Waitlist',
  firstName: 'First Name',
  lastName: 'Last Name',
  emailAddress: 'Email Address',
  mobileNumber: 'Mobile Phone',
  streetAddress: 'Address Line One',
  streetAddress2: 'Address Line Two',
  city: 'City',
  state: 'State',
  zip: 'Zip',
  country: 'Country',
  ssn: 'SSN',
  dob: 'Date of Birth',
  ppDd1Name: 'Bank Name (1)',
  ppDd1Route: 'Transit Number (1)',
  ppDd1Account: 'Account Number (1)',
  ppDd1CheckOrSave: 'Account Type (1)',
  ppDd2Name: 'Bank Name (2)',
  ppDd2Route: 'Transit Number (2)',
  ppDd2Account: 'Account Number (2)',
  ppDd2CheckOrSave: 'Account Type (2)',
  w4FilingStatus: 'Filing / Marital Status',
  w4Exempt: 'Exempt',
  w4ExemptExpDate: 'Exempt Expiration Date',
  w4NumAllowances: 'Allowances',
  w4WithholdExtra: 'Additional Amount',
  w4MultipleJobs: 'Multiple Jobs Flag',
  w4SplitForTwoJobs: 'Split for Two Checkbox',
  w4QualDependents: 'Qualified Dependents',
  w4QualDepAmount: 'Qualified Dependents Credit',
  w4OtherDependents: 'Other Dependents',
  w4OtherDepAmount: 'Other Dependents Credit',
  w4TotalDepAmount: 'Claim Dependents Credit ($)',
  w4OtherIncome: 'Other Income',
  w4ItemizedDeduction: 'Deductions ($)',
  caFilingStatus: 'Filing Status',
  caClaimExempt: 'Exempt (1)',
  caClaimExempt2: 'Exempt (2)',
  caAllowancesNum: 'Regular Withholding Allowances',
  caAddlWithholdingsAmount: 'Additional Withholding Amount',
  i9OptShown: 'I-9 Hyperlink Shown',
  i9Status: 'I9 Citizenship Status',
  i9ArUscisNum: 'Alien Registration Number',
  i9ArUscisAlienNum: 'Alien Registration Number',
  i9AlienExpDate: 'I9 Renew Date',
  i9I94AdmissionNum: 'Form I-94 Admission Number',
  i9ForeignPassNum: 'Foreign Passport Number',
  i9CountryOfIssuance: 'Country of Issuance',
  i9TranslatorUsed: 'Translator Used',
  i9TranslatorLastName: 'Translator Last Name',
  i9TranslatorFirstName: 'Translator First Name',
  i9TranslatorStreetAddress: 'Translator Street Address',
  i9TranslatorCity: 'Translator City',
  i9TranslatorState: 'Translator State',
  i9TranslatorZip: 'Translator Zip',
  i9ListADocTitle: 'Document Title (List A)',
  i9ListAIssuingAuthority: 'Issuing Authority (List A)',
  i9ListADocNum: 'Document Number (List A)',
  i9ListADocExpDate: 'Expiration Date (List A)',
  i9ListBDocTitle: 'Document Title (List B)',
  i9ListBIssuingAuthority: 'Issuing Authority (List B)',
  i9ListBDocNum: 'Document Number (List B)',
  i9ListBDocExpDate: 'Expiration Date (List B)',
  i9ListCDocTitle: 'Document Title (List C)',
  i9ListCIssuingAuthority: 'Issuing Authority (List C)',
  i9ListCDocNum: 'Document Number (List C)',
  i9ListCDocExpDate: 'Expiration Date (List C)',
  gaG4: 'Georgia State Tax',
};
@Component({
  selector: 'pixy-modal-shell',
  templateUrl: './modal-shell.component.html',
  styleUrls: ['./modal-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalShellComponent {
  @Input() public title: string;
  @Input() public type: MODAL_TYPE;
  @Input() public questions: any;
  @Input() public form: any;

  public labelMaps = labelMaps;
  public errorMessages = {
    ssn: 'Please enter a 9 digit numeric ssn',
  };

  constructor(protected ref: NbDialogRef<ModalShellComponent>) {}

  public close(): void {
    this.ref.close();
  }

  public save(): void {
    if (this.form.valid) {
      this.ref.close({ form: this.form });
    }
  }
}
