<nb-card class="login">
  <nb-card-body>
    <div [formGroup]="loginForm">
      <div class="mb-4 row justify-content-center align-items-center">
        <div class="col-12 mb-4 d-flex justify-content-center align-items-center">
          <img class="logo" src="assets/icons/pixy-wings.svg" alt="Pixy flying" />
        </div>
        <h1 class="text-center">Admin Sign In</h1>
      </div>

      <div id="loginFormInputs" *ngIf="isLoginFormVisible">
        <label class="d-block mb-2">User:</label>
        <nb-form-field class="mb-4">
          <nb-icon nbPrefix icon="at-outline" pack="eva"></nb-icon>
          <input
            type="text"
            autofocus
            formControlName="username"
            fullWidth
            fieldSize="giant"
            placeholder="User"
            name="email"
            autocomplete="email"
            nbInput
          />
        </nb-form-field>

        <label class="d-block mb-2">Password:</label>
        <nb-form-field class="mb-6">
          <nb-icon nbPrefix icon="lock-outline" pack="eva"></nb-icon>
          <input
            formControlName="password"
            [type]="getInputType()"
            fullWidth
            fieldSize="giant"
            placeholder="Password"
            name="password"
            autocomplete="password"
            nbInput
            (keyup.enter)="login()"
          />
          <button nbSuffix nbButton ghost (click)="toggleShowPassword()">
            <nb-icon
              [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'"
              [attr.aria-label]="showPassword ? 'hide password' : 'show password'"
            ></nb-icon>
          </button>
        </nb-form-field>

        <pixy-action-button appearance="filled" size="giant" [actions]="action"></pixy-action-button>
      </div>

      <p align="center" *ngIf="!isLoginFormVisible">
        <pixy-action-button appearance="filled" size="giant" [actions]="ssoAction"></pixy-action-button>
      </p>
      <p align="center">
        <button ghost nbButton (click)="toggleLoginFormVisibility()">{{ switchLoginText }}</button>
      </p>
    </div>
  </nb-card-body>
</nb-card>
