<nb-form-field>
  <nb-icon *ngIf="to.icon" nbPrefix [icon]="to.icon" [pack]="to.iconPack"></nb-icon>
  <input
    nbInput
    [formControl]="formControl"
    [mask]="to.mask"
    [formlyAttributes]="field"
    [type]="to.type"
    fullWidth
    [placeholder]="to.placeholder || ''"
    [attr.readOnly]="to.readonly"
    [attr.autocomplete]="to.autoComplete"
    [attr.inputmode]="to.keyboardType"
    [attr.autocapitalize]="to.autoCapitalize"
    [status]="showError ? 'danger' : 'primary'"
  />
</nb-form-field>
