import { EventEmitter, Injectable, Output } from '@angular/core';
import { RequestFilters, Theme } from '@shiftpixy/data';

// constants
const CLIENT_ID_KEY = 'client_id';
const TOKEN_KEY = 'token';
const TOKEN_EXPIRY_KEY = 'tokenExpiry';
const ADMIN_EMAIL_KEY = 'admin_email';
const THEME_KEY = 'theme';
const PAGE_FILTERS = 'filters';
const CACHED_ROUTES = 'cached_routes';
const UNKNOWN_USER = 'unknown_user';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  @Output() clientSet = new EventEmitter<any>();
  @Output() backButtonSet = new EventEmitter<{
    text: string;
    route: string;
  }>();
  @Output() adminEmailSet = new EventEmitter<string>();

  constructor() {
    this.adminEmailSet.emit(this.getAdminEmail());
  }

  clear() {
    const clientId = localStorage.getItem(CLIENT_ID_KEY);
    const cachedRoutes = localStorage.getItem(CACHED_ROUTES);
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem(CLIENT_ID_KEY, clientId);
    localStorage.setItem(CACHED_ROUTES, cachedRoutes);
  }

  setClientId(clientId: string): void {
    if (clientId) {
      sessionStorage.setItem(CLIENT_ID_KEY, clientId);
      localStorage.setItem(CLIENT_ID_KEY, clientId);
      this.clientSet.emit(clientId);
    }
  }

  setBackButtonText(data: { text: string; route: string }) {
    this.backButtonSet.emit(data);
  }

  getClientId() {
    return sessionStorage.getItem(CLIENT_ID_KEY);
  }

  getClientIdFromSessionOrLocal() {
    const clientId = sessionStorage.getItem(CLIENT_ID_KEY)
      ? sessionStorage.getItem(CLIENT_ID_KEY)
      : localStorage.getItem(CLIENT_ID_KEY);

    this.checkClientId(clientId);

    return clientId;
  }

  removeClientId() {
    sessionStorage.removeItem(CLIENT_ID_KEY);
    this.clientSet.emit(null);
  }

  removeBackButtonText() {
    this.backButtonSet.emit(null);
  }

  hasClientId() {
    return this.getClientId() !== 'null';
  }

  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  }

  getTokenExpireKey() {
    return localStorage.getItem(TOKEN_EXPIRY_KEY);
  }

  setToken(tokenVal: string, tokenExpiry: Date) {
    localStorage.setItem(TOKEN_KEY, tokenVal);
    localStorage.setItem(TOKEN_EXPIRY_KEY, tokenExpiry.toString());
  }

  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(TOKEN_EXPIRY_KEY);
  }

  setAdminEmail(email: string) {
    localStorage.setItem(ADMIN_EMAIL_KEY, email);
    this.adminEmailSet.emit(email);
  }

  getAdminEmail() {
    return localStorage.getItem(ADMIN_EMAIL_KEY);
  }

  checkClientId(clientId: string) {
    if (this.getClientId() !== clientId) {
      this.setClientId(clientId);
    }
  }

  setTheme(theme: Theme) {
    return localStorage.setItem(THEME_KEY, theme);
  }

  getTheme(): Theme {
    return localStorage.getItem(THEME_KEY) as Theme;
  }

  setRequestFilters(pageName: string, requestFilters: RequestFilters) {
    return localStorage.setItem(`${pageName}_${PAGE_FILTERS}`, JSON.stringify(requestFilters));
  }

  getRequestFilters(pageName: string): RequestFilters {
    return JSON.parse(localStorage.getItem(`${pageName}_${PAGE_FILTERS}`)) as RequestFilters;
  }

  public setCachedRoute(route: string): void {
    const routes = this.getAllCachedRoutes();
    const user = this.getAdminEmail() || UNKNOWN_USER;
    if (route && route !== '/login') {
      this.setAllCachedRoutes({ ...routes, [user]: route });
    }
  }

  public getCachedRoute(): string {
    this.handleUnknownUserCachedRoute();
    const routes = this.getAllCachedRoutes();
    const user = this.getAdminEmail();
    return routes.hasOwnProperty(user) ? routes[user] : '';
  }

  public handleUnknownUserCachedRoute() {
    const unknownCachedRoute = this.getUnknownUserCachedRoute();
    if (unknownCachedRoute) {
      this.setCachedRoute(unknownCachedRoute);
    }
  }

  private getUnknownUserCachedRoute() {
    const routes = this.getAllCachedRoutes();
    let unknownUserCachedRoute = '';
    if (routes.hasOwnProperty(UNKNOWN_USER)) {
      unknownUserCachedRoute = routes[UNKNOWN_USER];
      delete routes[UNKNOWN_USER];
      this.setAllCachedRoutes(routes);
    }
    return unknownUserCachedRoute;
  }

  private getAllCachedRoutes(): { [id: string]: string } {
    return JSON.parse(localStorage.getItem(CACHED_ROUTES)) || {};
  }

  private setAllCachedRoutes(routes: { [id: string]: string }): void {
    localStorage.setItem(CACHED_ROUTES, JSON.stringify(routes));
  }
}
