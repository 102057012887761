import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent extends FieldType implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}

  public getSelectedOption(question: any, property: 'icon' | 'iconPack' | 'value'): string {
    let icon = '';
    if (question.options) {
      question.options.find((option: any) => {
        if (option.key === this.field.formControl.value) {
          icon = option[property];
        }
      });
      return icon;
    }
  }
}
