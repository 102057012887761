import { Validators } from '@angular/forms';
import { NotEmptyValidator } from '../validators/not-empty';
import { IFormField } from './form-field';

export const FORM_CONFIG: { [key: string]: IFormField } = {
  firstName: {
    autoComplete: 'given-name',
    key: 'firstName',
    label: 'First Name',
    type: 'text',
    dataTest: '',
    placeholder: 'First Name',
    autoCapitalize: 'words',
    validators: [Validators.required, NotEmptyValidator],
    class: 'col-12 col-md-6 col-lg-4',
  },
  lastName: {
    autoComplete: 'family-name',
    key: 'lastName',
    label: 'Last Name',
    type: 'text',
    dataTest: '',
    placeholder: 'Last Name',
    autoCapitalize: 'words',
    validators: [Validators.required, NotEmptyValidator],
    class: 'col-12 col-md-6 col-lg-4',
  },
  email: {
    key: 'email',
    label: 'Email',
    type: 'text',
    keyboardType: 'email',
    autoCapitalize: 'none',
    autoComplete: 'email',
    dataTest: '',
    placeholder: 'email@example.com',
    validators: [Validators.required, Validators.email],
    class: 'col-12 col-md-6 col-lg-4',
    errorMessage: 'Please enter a valid email address',
  },
  protocolURL: {
    key: 'protocolURL',
    label: 'Website URL',
    type: 'url-protocol',
    placeholder: 'https://',
    validators: [Validators.nullValidator],
    class: 'col-4 col-md-2 col-lg-1 protocol',
  },
  domainURL: {
    key: 'domainURL',
    label: '',
    type: 'url-text',
    autoCapitalize: 'none',
    dataTest: '',
    placeholder: 'www.example.com',
    validators: [Validators.nullValidator, Validators.pattern('([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w# .-]*/?')],
    class: 'col-8 col-md-4 col-lg-3 input-url',
    errorMessage: 'Please enter a valid website domain address',
  },
  streetAddress: {
    key: 'streetAddress',
    label: 'Street Address',
    type: 'text',
    autoCapitalize: 'words',
    autoComplete: 'address-line1',
    dataTest: '',
    placeholder: 'Street Address',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
  city: {
    key: 'city',
    label: 'City',
    type: 'text',
    dataTest: '',
    placeholder: 'City',
    autoComplete: 'address-level2',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
  county: {
    key: 'county',
    label: 'County',
    type: 'text',
    dataTest: '',
    placeholder: 'County',
    validators: [Validators.nullValidator],
    class: 'col-12 col-md-6 col-lg-4',
  },
  zip: {
    key: 'zipCode',
    label: 'Zip Code',
    type: 'text',
    mask: '9{5}?-0{4}',
    keyboardType: 'numeric',
    autoComplete: 'postal-code',
    dataTest: '',
    placeholder: '00000',
    validators: [Validators.required, Validators.pattern('^[0-9]{5}(?:-?[0-9]{4})?$')],
    class: 'col-12 col-md-6 col-lg-4',
    errorMessage: 'Please enter a 5 digit or 9 digit Zip Code',
    ca: {
      key: 'zipCode',
      label: 'Postal Code',
      mask: 'S0S 0S0',
      keyboardType: 'text',
      dataTest: '',
      placeholder: 'S0S 0S0',
      autoCapitalize: 'characters',
      validators: [Validators.required, Validators.pattern('^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$')],
      class: 'col-12 col-md-6 col-lg-4',
      errorMessage: 'Please enter a 6 character postal code.',
    },
  },
  phoneNumber: {
    key: 'phoneNumber',
    label: 'Phone Number',
    type: 'text',
    mask: '(000) 000-0000',
    dataTest: '',
    placeholder: 'Phone Number',
    keyboardType: 'numeric',
    autoComplete: 'tel',
    validators: [Validators.required, Validators.minLength(10)],
    class: 'col-12 col-md-6 col-lg-4',
  },
  date: {
    key: 'date',
    label: 'Date',
    type: 'date',
    dataTest: '',
    placeholder: 'Select Date',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
  payRate: {
    key: 'payRate',
    label: 'Pay Rate',
    mask: 'separator.2',
    type: 'text-w-icon',
    icon: 'dollar',
    iconPack: 'pixy',
    dataTest: '',
    placeholder: 'Pay Rate',
    validators: [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{1,2})?$')],
    class: 'col-12 col-md-6 col-lg-4',
  },
  status: {
    key: 'status',
    label: 'Access Status',
    type: 'select',
    dataTest: '',
    placeholder: 'Select Status',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
    options: [
      {
        key: 'active',
        value: 'Active',
        icon: 'active',
        iconPack: 'pixy',
      },
      {
        key: 'onLeave',
        value: 'On Leave',
        icon: 'onLeave',
        iconPack: 'pixy',
      },
      {
        key: 'terminated',
        value: 'Terminated',
        icon: 'terminated',
        iconPack: 'pixy',
      },
    ],
  },
  payType: {
    key: 'payType',
    label: 'Pay Type',
    type: 'select',
    dataTest: '',
    placeholder: 'Select Pay Type',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
    options: [
      {
        key: 'Hourly',
        value: 'Hourly',
      },
      {
        key: 'Salary',
        value: 'Salary',
      },
    ],
  },
  payMethod: {
    key: 'payMethod',
    label: 'Pay Method',
    type: 'select',
    dataTest: '',
    placeholder: 'Select Pay Method',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
    options: [
      {
        key: 'Live Check',
        value: 'Live Check',
      },
      {
        key: 'Direct Deposit Only',
        value: 'Direct Deposit Only',
      },
      {
        key: 'Pay Card Only',
        value: 'Pay Card Only',
      },
    ],
  },
  country: {
    key: 'country',
    label: 'Country',
    type: 'select',
    dataTest: '',
    placeholder: 'Country',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
    value: 'US',
    options: [
      {
        key: 'US',
        value: 'United States',
        icon: 'us-flag',
        iconPack: 'pixy',
      },
      {
        key: 'CA',
        value: 'Canada',
        icon: 'ca-flag',
        iconPack: 'pixy',
      },
    ],
  },
  state: {
    key: 'state',
    label: 'State',
    type: 'select',
    dataTest: '',
    placeholder: 'Select State',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
    options: [
      { value: 'Alabama - AL', key: 'AL' },
      { value: 'Alaska - AK', key: 'AK' },
      { value: 'Arizona - AZ', key: 'AZ' },
      { value: 'Arkansas - AR', key: 'AR' },
      { value: 'California - CA', key: 'CA' },
      { value: 'Colorado - CO', key: 'CO' },
      { value: 'Connecticut - CT', key: 'CT' },
      { value: 'District of Columbia - DC', key: 'DC' },
      { value: 'Delaware - DE', key: 'DE' },
      { value: 'Florida - FL', key: 'FL' },
      { value: 'Georgia - GA', key: 'GA' },
      { value: 'Hawaii - HI', key: 'HI' },
      { value: 'Idaho - ID', key: 'ID' },
      { value: 'Illinois - IL', key: 'IL' },
      { value: 'Indiana - IN', key: 'IN' },
      { value: 'Iowa - IA', key: 'IA' },
      { value: 'Kansas - KS', key: 'KS' },
      { value: 'Kentucky - KY', key: 'KY' },
      { value: 'Louisiana - LA', key: 'LA' },
      { value: 'Maine - ME', key: 'ME' },
      { value: 'Maryland - MD', key: 'MD' },
      { value: 'Massachusetts - MA', key: 'MA' },
      { value: 'Michigan - MI', key: 'MI' },
      { value: 'Minnesota - MN', key: 'MN' },
      { value: 'Mississippi - MS', key: 'MS' },
      { value: 'Missouri - MO', key: 'MO' },
      { value: 'Montana - MT', key: 'MT' },
      { value: 'Nebraska - NE', key: 'NE' },
      { value: 'Nevada - NV', key: 'NV' },
      { value: 'New Hampshire - NH', key: 'NH' },
      { value: 'New Jersey - NJ', key: 'NJ' },
      { value: 'New Mexico - NM', key: 'NM' },
      { value: 'New York - NY', key: 'NY' },
      { value: 'North Carolina - NC', key: 'NC' },
      { value: 'North Dakota - ND', key: 'ND' },
      { value: 'Ohio - OH', key: 'OH' },
      { value: 'Oklahoma - OK', key: 'OK' },
      { value: 'Oregon - OR', key: 'OR' },
      { value: 'Pennsylvania - PA', key: 'PA' },
      { value: 'Rhode Island - RI', key: 'RI' },
      { value: 'South Carolina - SC', key: 'SC' },
      { value: 'South Dakota - SD', key: 'SD' },
      { value: 'Tennessee - TN', key: 'TN' },
      { value: 'Texas - TX', key: 'TX' },
      { value: 'Utah - UT', key: 'UT' },
      { value: 'Vermont - VT', key: 'VT' },
      { value: 'Virginia - VA', key: 'VA' },
      { value: 'Washington - WA', key: 'WA' },
      { value: 'West Virginia - WV', key: 'WV' },
      { value: 'Wisconsin - WI', key: 'WI' },
      { value: 'Wyoming - WY', key: 'WY' },
    ],
    ca: {
      key: 'state',
      label: 'Province',
      type: 'select',
      dataTest: '',
      placeholder: 'Select Province',
      validators: [Validators.required],
      class: 'col-12 col-md-6 col-lg-4',
      options: [
        { value: 'Alberta - AB', key: 'AB' },
        { value: 'British Columbia - BC', key: 'BC' },
        { value: 'Manitoba - MB', key: 'MB' },
        { value: 'New Brunswick - NB', key: 'NB' },
        { value: 'Newfoundland and Labrador - NL', key: 'NL' },
        { value: 'Northwest Territories - NT', key: 'NT' },
        { value: 'Nova Scotia - NS', key: 'NS' },
        { value: 'Nunavut - NU', key: 'NU' },
        { value: 'Ontario - ON', key: 'ON' },
        { value: 'Prince Edward Island - PE', key: 'PE' },
        { value: 'Québec - QC', key: 'QC' },
        { value: 'Saskatchewan - SK', key: 'SK' },
        { value: 'Yukon - YT', key: 'YT' },
      ],
    },
  },
  payPeriod: {
    key: 'payPeriod',
    label: 'Pay Period',
    type: 'select',
    dataTest: '',
    placeholder: 'Pay Period',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
    options: [
      {
        key: 'Weekly',
        value: 'Weekly',
      },
      {
        key: 'Bi-Weekly',
        value: 'Bi-Weekly',
      },
      {
        key: 'Semi-Monthly',
        value: 'Semi-Monthly',
      },
      {
        key: 'Monthly',
        value: 'Monthly',
      },
    ],
  },
  positions: {
    key: 'positions',
    label: 'Positions',
    type: 'quick-tag',
    dataTest: '',
    placeholder: 'Positions',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
    positions: [],
  },
  federalTaxID: {
    key: 'federalTaxID',
    label: 'Federal Tax ID',
    dataTest: '',
    placeholder: 'Federal Tax ID',
    type: 'text',
    mask: '00-0000000',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
    ca: {
      key: 'federalTaxID',
      validators: [Validators.nullValidator],
      class: 'col-12 col-md-6 col-lg-4',
    },
  },
  locationId: {
    key: 'locationID',
    label: 'Location ID',
    type: 'text',
    dataTest: '',
    placeholder: 'Location ID',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
  name: {
    key: 'name',
    label: 'Worksite name',
    type: 'text',
    dataTest: '',
    placeholder: 'Worksite name',
    validators: [Validators.required, NotEmptyValidator],
    class: 'col-12 col-md-6 col-lg-4',
  },
  businessNumber: {
    key: 'businessNumber',
    label: 'Business Number',
    dataTest: '',
    placeholder: 'Business Number',
    type: 'text',
    mask: '000000000',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
  emptyField: {
    key: 'emptyField',
    validators: [Validators.nullValidator],
    class: 'col-12 col-md-6 col-lg-4',
  },
  autocompleteAddress: {
    key: 'streetAddress',
    country: 'US',
    label: 'Street Address',
    type: 'autocomplete-address',
    placeholder: 'Street Address',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
    ca: {
      key: 'streetAddress',
      country: 'CA',
      label: 'Street Address',
      type: 'autocomplete-address',
      placeholder: 'Street Address',
      validators: [Validators.required],
      class: 'col-12 col-md-6 col-lg-4',
    },
  },
  latitude: {
    key: 'latitude',
    label: 'Latitude',
    type: 'text',
    dataTest: '',
    placeholder: 'Latitude',
    class: 'col-12 col-md-6 col-lg-4',
    validators: [Validators.nullValidator],
  },
  longitude: {
    key: 'longitude',
    label: 'Longitude',
    type: 'text',
    dataTest: '',
    placeholder: 'Longitude',
    class: 'col-12 col-md-6 col-lg-4',
    validators: [Validators.nullValidator],
  },
  templateSelect: {
    key: 'templateSelect',
    label: 'Select Template',
    type: 'multi-select',
    placeholder: 'Templates',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
    options: [],
  },
  timezone: {
    key: 'timezone',
    label: 'Timezone',
    type: 'text',
    dataTest: '',
    placeholder: 'Timezone',
    class: 'col-12 col-md-6 col-lg-4',
    validators: [Validators.nullValidator],
  },
};

export const SHIFTER_CONFIG = {
  worksiteManager: {
    key: 'worksiteManager',
    label: 'Worksite Manager',
    type: 'select',
    dataTest: 'Shifter_WorksiteManager',
    placeholder: 'Worksite Manager',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
  employmentType: {
    key: 'employmentType',
    label: 'Employment Type',
    type: 'select',
    dataTest: 'Shifter_EmploymentType',
    placeholder: 'Employment Type',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
    options: [
      {
        key: 'PartTime',
        value: 'Part Time',
      },
      {
        key: 'FullTime',
        value: 'Full Time',
      },
    ],
  },
  positions: {
    key: 'position',
    label: 'Positions',
    type: 'select',
    dataTest: 'Shifter_Positions',
    placeholder: 'Positions',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
  locationId: {
    key: 'locationID',
    label: 'Location ID',
    type: 'select',
    dataTest: 'Shifter_LocationID',
    placeholder: 'Location ID',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
  onboardingType: {
    key: 'onboardingType',
    label: 'Onboarding Type',
    type: 'select',
    dataTest: 'Shifter_OnboardingType',
    placeholder: 'Select Onboarding Type',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
    value: 'New',
    options: [
      {
        key: 'New',
        value: 'New',
      },
      {
        key: 'Transitional',
        value: 'Transitional',
      },
    ],
  },
  gender: {
    key: 'gender',
    label: 'Gender',
    type: 'select',
    dataTest: 'Shifter_Gender',
    placeholder: 'Select your gender',
    validators: [Validators.nullValidator],
    class: 'col-12 col-md-6 col-lg-4',
    options: [
      {
        key: 'M',
        value: 'Male',
      },
      {
        key: 'F',
        value: 'Female',
      },
      {
        key: 'U',
        value: 'Declined to Respond',
      },
    ],
  },
  isDriver: {
    key: 'isDriver',
    label: 'Is this shifter going to be a ZiPixy driver?',
    type: 'toggle',
    value: false,
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
  payType: {
    ...FORM_CONFIG.payType,
    key: 'payType',
    dataTest: 'Shifter_PayType',
  },
  payGroup: {
    key: 'payGroup',
    label: 'Pay Group',
    type: 'select',
    dataTest: 'Shifter_PayGroup',
    placeholder: 'Pay Group',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
    options: [
      {
        key: 'weekly',
        value: 'Weekly',
      },
      {
        key: 'bi-weekly',
        value: 'Bi-Weekly',
      },
      {
        key: 'semi-monthly',
        value: 'Semi-Monthly',
      },
      {
        key: 'monthly',
        value: 'Monthly',
      },
    ],
  },
  documentPath: {
    key: 'documentPath',
    label: 'Document File',
    placeholder: 'Upload file',
    type: 'text',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
  documentType: {
    key: 'documentType',
    label: 'Document Type',
    type: 'select',
    placeholder: 'Select Document\u0027s Type First',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
    options: [
      {
        key: 'autoInsuranceFront',
        value: 'Auto Insurance (Front)',
      },
      {
        key: 'autoRegistrationFront',
        value: 'Auto Registration (Front)',
      },
      {
        key: 'birthCertificateFront',
        value: 'Birth Certificate (Front)',
      },
      {
        key: 'birthCertificateBack',
        value: 'Birth Certificate (Back)',
      },
      {
        key: 'certFront0',
        value: 'Cert (Front 0)',
      },
      {
        key: 'certFront1',
        value: 'Cert (Front 1)',
      },
      {
        key: 'certFront2',
        value: 'Cert (Front 2)',
      },
      {
        key: 'certFront3',
        value: 'Cert (Front 3)',
      },
      {
        key: 'ddAuthFront1',
        value: 'Direct Deposit Auth (Front 1)',
      },
      {
        key: 'ddAuthFront2',
        value: 'Direct Deposit Auth (Front 2)',
      },
      {
        key: 'driversLicenseFront',
        value: 'Driver\u0027s License (Front)',
      },
      {
        key: 'driversLicenseBack',
        value: 'Driver\u0027s License (Back)',
      },
      {
        key: 'driversLicenseFront2',
        value: 'Driver\u0027s License (Front 2)',
      },
      {
        key: 'driversLicenseBack2',
        value: 'Driver\u0027s License (Back 2)',
      },
      {
        key: 'employmentAuthDocFront',
        value: 'Employment Auth Doc (Front)',
      },
      {
        key: 'employmentAuthDocBack',
        value: 'Employment Auth Doc (Back)',
      },
      {
        key: 'foreignPassportFront',
        value: 'Foreign Passport (Front)',
      },
      {
        key: 'foreignPassportBack',
        value: 'Foreign Passport (Back)',
      },
      {
        key: 'leaveAndEarningsStatement',
        value: 'Leave And Earnings Statement',
      },
      {
        key: 'militaryDependentIdFront',
        value: 'Military Dependent ID (Front)',
      },
      {
        key: 'militaryDependentIdBack',
        value: 'Military Dependent ID (Back)',
      },
      {
        key: 'militaryIdCardFront',
        value: 'Military ID Card (Front)',
      },
      {
        key: 'militaryIdCardBack',
        value: 'Military ID Card (Back)',
      },
       {
        key: 'newHirePacket',
        value: 'New Hire Packet (Manual)',
      },
      {
        key: 'otherListBIdFront',
        value: 'Other List B Id (Front)',
      },
      {
        key: 'otherListBIdBack',
        value: 'Other List B Id (Back)',
      },
      {
        key: 'otherListCIdFront',
        value: 'Other List C Id (Front)',
      },
      {
        key: 'otherListCIdBack',
        value: 'Other List C Id (Back)',
      },
      {
        key: 'passportFront',
        value: 'Passport (Front)',
      },
      {
        key: 'passportBack',
        value: 'Passport (Back)',
      },
      {
        key: 'permanentResidentCardFront',
        value: 'Permanent Resident Card (Front)',
      },
      {
        key: 'permanentResidentCardBack',
        value: 'Permanent Resident Card (Back)',
      },
      {
        key: 'schoolIdCardFront',
        value: 'School Id Card (Front)',
      },
      {
        key: 'schoolIdCardBack',
        value: 'School Id Card (Back)',
      },
      {
        key: 'socialSecurityCardFront',
        value: 'Social Security Card (Front)',
      },
      {
        key: 'socialSecurityCardBack',
        value: 'Social Security Card (Back)',
      },
      {
        key: 'stateIdCardFront',
        value: 'State ID Card (Front)',
      },
      {
        key: 'stateIdCardBack',
        value: 'State ID Card (Back)',
      },
      {
        key: 'usPassportFront',
        value: 'US Passport (Front)',
      },
      {
        key: 'usPassportBack',
        value: 'US Passport (Back)',
      },
      {
        key: 'voidedCheckFront1',
        value: 'Voided Check (Front 1)',
      },
      {
        key: 'voidedCheckFront2',
        value: 'Voided Check (Front 2)',
      },
      {
        key: 'vaccineMandate',
        value: 'Vaccine Docs',
      },
    ],
  },
  templateToggle: {
    key: 'templateToggle',
    label: 'Send Offer Letter?',
    type: 'toggle',
    value: false,
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
  templateSelect: {
    key: 'templateSelect',
    label: 'Select Template',
    type: 'select',
    placeholder: 'Templates',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
    options: [
      {
        key: '',
        value: '',
      },
    ],
  },
  vaccineStatus: {
    key: 'vaccineStatus',
    label: 'Vaccine Status',
    type: 'select',
    dataTest: 'Shifter_VaccineStatus',
    placeholder: 'Vaccine Status',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
    options: [
      {
        key: 'pending review',
        value: 'Pending Review',
      },
      {
        key: 'rejected',
        value: 'Rejected',
      },
      {
        key: 'approved',
        value: 'Approved',
      },
    ],
  },
  department: {
    key: 'department',
    label: 'Department',
    type: 'text',
    dataTest: '',
    placeholder: 'Department',
    autoCapitalize: 'words',
    validators: [] as any,
    class: 'col-12 col-md-6 col-lg-4',
  },
  division: {
    key: 'division',
    label: 'Division',
    type: 'text',
    dataTest: '',
    placeholder: 'Division',
    autoCapitalize: 'words',
    validators: [] as any,
    class: 'col-12 col-md-6 col-lg-4',
  },
  employeeNumber: {
    key: 'employeeNumber',
    label: 'Employee Number',
    type: 'text',
    dataTest: '',
    placeholder: 'Employee Number',
    autoCapitalize: 'words',
    validators: [] as any,
    class: 'col-12 col-md-6 col-lg-4',
  },
  workGroup: {
    key: 'workGroup',
    label: 'Work Group',
    type: 'text',
    dataTest: '',
    placeholder: 'Work Group',
    autoCapitalize: 'words',
    validators: [] as any,
    class: 'col-12 col-md-6 col-lg-4',
  },
  shift: {
    key: 'shift',
    label: 'Shift',
    type: 'select',
    placeholder: 'Shift',
    validators: [] as any,
    class: 'col-12 col-md-6 col-lg-4',
    options: [
      {
        key: 'AM',
        value: 'AM',
      },
      {
        key: 'AM',
        value: 'PM',
      },
      {
        key: 'MID',
        value: 'MID',
      },
    ],
  },
};

export const WORKSITE_CONFIG: { [key: string]: IFormField } = {
  vaccineMandate: {
    key: 'vaccineMandate',
    label: 'Enable Vaccine Workflow?',
    type: 'toggle',
    dataTest: 'Worksite_VaccineMandate',
    value: false,
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
  isBillingAddressTheSame: {
    key: 'isBillingAddressTheSame',
    label: 'Is the worksite billing address the same as their street address?',
    type: 'toggle',
    dataTest: 'Worksite_BillingAddress',
    value: true,
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
  shiftPixyScheduler: {
    key: 'shiftPixyScheduler',
    label: 'Will this location use the ShiftPixy Scheduler?',
    type: 'radio',
    value: 'no',
    options: [
      {
        key: 'no',
        value: 'No',
      },
      {
        key: 'openSimSim',
        value: 'OpenSimSim',
      },
    ],
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
  ecosystem: {
    key: 'ecosystem',
    label: 'Will this location participate in the Public Ecosystem?',
    type: 'toggle',
    value: false,
    validators: [Validators.nullValidator],
    class: 'col-12',
  },
  ziPixy: {
    key: 'ziPixy',
    label: 'Will this location use ZiPixy?',
    type: 'toggle',
    dataTest: 'Worksite_LocationPixy',
    value: false,
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
  addShifters: {
    key: 'addShifters',
    label: 'Ready to add shifter(s)?',
    type: 'toggle',
    value: false,
    validators: [Validators.nullValidator],
    class: 'col-12 col-md-6 col-lg-4',
  },
  onboardingRequired: {
    key: 'onboardingRequired',
    label: 'Onboarding process required?',
    type: 'radio',
    value: 'yes',
    options: [
      {
        key: 'yes',
        value: 'Yes',
      },
      {
        key: 'no',
        value: 'No',
      },
    ],
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
  physicalCountry: {
    ...FORM_CONFIG.country,
    key: 'physicalCountry',
    dataTest: 'Worksite_PhysicalCountry',
    ca: {
      ...FORM_CONFIG.country.ca,
      key: 'physicalCountry',
      dataTest: 'Worksite_PhysicalCountry',
    },
  },
  physicalStreetAddress: {
    ...FORM_CONFIG.streetAddress,
    key: 'physicalStreetAddress',
    dataTest: 'Worksite_PhysicalStreetAddress',
    ca: {
      ...FORM_CONFIG.streetAddress.ca,
      key: 'physicalStreetAddress',
      dataTest: 'Worksite_PhysicalStreetAddress',
    },
  },
  physicalAutocompleteStreetAddress: {
    ...FORM_CONFIG.autocompleteAddress,
    key: 'physicalStreetAddress',
    dataTest: 'Worksite_PhysicalStreetAddress',
    ca: {
      ...FORM_CONFIG.autocompleteAddress.ca,
      key: 'physicalStreetAddress',
      dataTest: 'Worksite_PhysicalStreetAddress',
    },
  },
  physicalCity: {
    ...FORM_CONFIG.city,
    key: 'physicalCity',
    dataTest: 'Worksite_PhysicalCity',
    ca: {
      ...FORM_CONFIG.city.ca,
      key: 'physicalCity',
      dataTest: 'Worksite_PhysicalCity',
    },
  },
  physicalState: {
    ...FORM_CONFIG.state,
    key: 'physicalState',
    dataTest: 'Worksite_PhysicalState',
    ca: {
      ...FORM_CONFIG.state.ca,
      key: 'physicalState',
      dataTest: 'Worksite_PhysicalState',
    },
  },
  physicalZipCode: {
    ...FORM_CONFIG.zip,
    key: 'physicalZipCode',
    dataTest: 'Worksite_PhysicalZipCode',
    ca: {
      ...FORM_CONFIG.zip.ca,
      key: 'physicalZipCode',
      dataTest: 'Worksite_PhysicalZipCode',
    },
  },
  physicalCounty: {
    ...FORM_CONFIG.county,
    key: 'physicalCounty',
    dataTest: 'Worksite_PhysicalCounty',
    ca: {
      ...FORM_CONFIG.county.ca,
      key: 'physicalCounty',
      dataTest: 'Worksite_PhysicalCounty',
    },
  },
  stateTaxID: {
    key: 'stateTaxID',
    label: 'State Tax ID',
    dataTest: 'Worksite_StateTaxID',
    placeholder: 'State Tax ID',
    type: 'text',
    mask: '99-9999999',
    validators: [Validators.nullValidator],
    class: 'col-12 col-md-6 col-lg-4',
  },
  billingCountry: {
    ...FORM_CONFIG.country,
    key: 'billingCountry',
    dataTest: 'Worksite_BillingCountry',
    ca: {
      ...FORM_CONFIG.country.ca,
      key: 'billingCountry',
      dataTest: 'Worksite_BillingCountry',
    },
  },
  billingAutocompleteStreetAddress: {
    ...FORM_CONFIG.autocompleteAddress,
    key: 'billingStreetAddress',
    dataTest: 'Worksite_BillingStreetAddress',
    ca: {
      ...FORM_CONFIG.autocompleteAddress.ca,
      key: 'billingStreetAddress',
      dataTest: 'Worksite_BillingStreetAddress',
    },
  },
  billingCity: {
    ...FORM_CONFIG.city,
    key: 'billingCity',
    dataTest: 'Worksite_BillingCity',
    ca: {
      ...FORM_CONFIG.city.ca,
      key: 'billingCity',
      dataTest: 'Worksite_BillingCity',
    },
  },
  billingState: {
    ...FORM_CONFIG.state,
    key: 'billingState',
    dataTest: 'Worksite_BillingState',
    ca: {
      ...FORM_CONFIG.state.ca,
      key: 'billingState',
      dataTest: 'Worksite_BillingState',
    },
  },
  billingZipCode: {
    ...FORM_CONFIG.zip,
    key: 'billingZipCode',
    dataTest: 'Worksite_BillingZipCode',
    ca: {
      ...FORM_CONFIG.zip.ca,
      key: 'billingZipCode',
      dataTest: 'Worksite_BillingZipCode',
    },
  },
  billingCounty: {
    ...FORM_CONFIG.county,
    key: 'billingCounty',
    dataTest: 'Worksite_BillingCounty',
    ca: {
      ...FORM_CONFIG.county.ca,
      key: 'billingCounty',
      dataTest: 'Worksite_BillingCounty',
    },
  },
  documentPath: {
    key: 'documentPath',
    label: 'Document File',
    placeholder: '',
    type: 'text',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
};

export const CLIENT_CONFIG: { [key: string]: IFormField } = {
  clientId: {
    key: 'clientId',
    label: 'Client ID',
    mask: '0*',
    type: 'text',
    keyboardType: 'numeric',
    dataTest: 'Client_ID',
    placeholder: 'Client ID',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
  name: {
    key: 'name',
    label: 'Client Name',
    type: 'text',
    dataTest: 'Client_Name',
    placeholder: 'Client Name',
    validators: [Validators.required, NotEmptyValidator],
    class: 'col-12 col-md-6 col-lg-4',
  },
  dba: {
    key: 'dba',
    label: 'DBA',
    type: 'text',
    dataTest: 'Client_DBA',
    placeholder: 'DBA',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
  corporateStructure: {
    key: 'corporateStructure',
    label: 'Corporate Structure',
    type: 'select',
    dataTest: 'Client_CorporateStructure',
    placeholder: 'Corporate Structure',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
    options: [
      { key: 'CCorp', value: 'C Corp' },
      { key: 'LLC', value: 'LLC' },
      { key: 'Partnership', value: 'Partnership' },
      { key: 'SoleProprietorship', value: 'Sole Proprietorship' },
      { key: 'SCorp', value: 'S Corp' },
    ],
    ca: {
      key: 'corporateStructure',
      label: 'Corporate Structure',
      dataTest: 'Client_CorporateStructure',
      placeholder: 'Corporate Structure',
      validators: [Validators.required],
      class: 'col-12 col-md-6 col-lg-4',
      options: [
        { key: 'Corporation', value: 'Corporation' },
        { key: 'GeneralPartnership', value: 'General Partnership' },
        { key: 'LimitedPartnership', value: 'Limited Partnership' },
        { key: 'SoleProprietorship', value: 'Sole Proprietorship' },
      ],
    },
  },
  businessType: {
    key: 'businessType',
    label: 'Business Type',
    type: 'text',
    dataTest: 'Client_BusinessType',
    placeholder: 'Business Type',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
  naicsCode: {
    key: 'naicsCode',
    label: 'NAICS Code',
    type: 'text',
    mask: '000000',
    keyboardType: 'numeric',
    dataTest: 'Client_NAICSCode',
    placeholder: 'NAICS Code',
    errorMessage: 'Must be 6 digits',
    validators: [
      Validators.maxLength(6),
      Validators.minLength(1),
      Validators.pattern('^[0-9]{6}$'),
      Validators.required,
    ],
    class: 'col-12 col-md-6 col-lg-4',
  },
  sicCode: {
    key: 'sicCode',
    label: 'SIC Code',
    type: 'text',
    mask: '0000',
    keyboardType: 'numeric',
    dataTest: 'Client_SICCode',
    placeholder: 'SIC Code',
    errorMessage: 'Must be 4 digits',
    validators: [
      Validators.maxLength(4),
      Validators.minLength(1),
      Validators.pattern('^[0-9]{4}$'),
      Validators.required,
    ],
    class: 'col-12 col-md-6 col-lg-4',
  },
  engagementType: {
    key: 'engagementType',
    label: 'Engagement Type',
    type: 'select',
    dataTest: 'Client_EngagementType',
    placeholder: 'Engagement',
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
    options: [
      {
        key: 'ShiftPixyStaffing',
        value: 'ShiftPixy Staffing',
      },
      {
        key: 'ReThinkHCM',
        value: 'Rethink HCM',
      },
      {
        key: 'RethinkAdmin',
        value: 'Rethink Admin',
      },
    ],
  },
  templateToggle: {
    key: 'templateToggle',
    label: 'Enable Offer Letter Template?',
    type: 'toggle',
    value: false,
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
  humanityToggle: {
    key: 'humanityToggle',
    label: 'Will this Client use Humanity?',
    type: 'toggle',
    value: false,
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
  humanityId: {
    key: 'humanityId',
    label: 'Humanity ID',
    type: 'text',
    mask: '000000',
    placeholder: '384799',
    errorMessage: 'This is a required field. Enter a 6 digit ID.',
    class: 'col-12 col-md-6 col-lg-4',
    validators: [Validators.required, Validators.pattern('^(None|none|\\d{6})$')],
  },
  humanityDomain: {
    key: 'humanityDomain',
    label: 'Humanity Domain',
    type: 'text',
    placeholder: 'shiftpixy',
    class: 'col-12 col-md-6 col-lg-4',
    validators: [Validators.required],
  },
};

export const BG_CHECK_PACKAGE_CONFIG: { [key: string]: IFormField } = {
  backgroundChecks: {
    key: 'shifterBackgroundChecks',
    label: 'Require background checks for new shifters?',
    type: 'toggle',
    value: false,
    validators: [Validators.required],
    class: 'col-12 col-md-6 col-lg-4',
  },
  packageId: {
    key: 'packageId',
    label: 'Package',
    type: 'select',
    dataTest: 'Shifter_PayGroup',
    placeholder: 'Select package',
    validators: [],
    class: 'col-12',
    options: [],
  },
  driverEnabled: {
    key: 'driverEnabled',
    label: 'Driver Enabled',
    type: 'toggle',
    value: false,
    validators: [],
    class: 'col-12 col-md-6 col-lg-4',
  },
};

// NotEmptyValidator;
export const MERCHANT_CONFIG: { [key: string]: IFormField } = {
  baseInput: {
    key: 'name',
    label: 'Worksite name',
    type: 'text',
    placeholder: 'Worksite name',
    validators: [Validators.required, NotEmptyValidator],
    class: 'col-12 col-md-6 col-lg-4',
  },
  latitude: {
    key: 'latitude',
    label: 'Latitude',
    type: 'text',
    keyboardType: 'numeric',
    placeholder: 'Latitude',
    validators: [
      Validators.required,
      Validators.pattern(/^(\+|-)?(?:90(?:(?:\.0{1,7})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,7})?))$/),
    ],
    errorMessage: 'Please enter a valid latitude value',
    class: 'col-12 col-md-6 col-lg-4',
  },
  longitude: {
    key: 'longitude',
    label: 'Longitude',
    type: 'text',
    keyboardType: 'numeric',
    placeholder: 'Longitude',
    validators: [
      Validators.required,
      Validators.pattern(/^(\+|-)?(?:180(?:(?:\.0{1,7})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,7})?))$/),
    ],
    errorMessage: 'Please enter a valid longitude value',
    class: 'col-12 col-md-6 col-lg-4',
  },
};
