import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UiModule } from '@shiftpixy/ui';
import { FeatureFlagGuard } from '../../services/feature-flag/feature-flag.guard';
import { AuthGuard } from '../auth/services/auth.guard';
import { NavigationComponent } from './navigation.component';
import { MenuPage, menuPages } from './navigation.model';

const configRoute = (routeConfig: MenuPage) => {
  const config = {
    ...routeConfig,
    canActivate: [AuthGuard, ...(routeConfig.data?.featureFlags?.length ? [FeatureFlagGuard] : [])],
  };
  config.children = config.children?.map(configRoute);
  delete config.title;
  return config;
};
export const menuPagesRoutes = menuPages.map(configRoute);
const routes: Routes = [];

@NgModule({
  declarations: [NavigationComponent],
  imports: [CommonModule, UiModule, HttpClientModule, RouterModule.forChild(routes)],
  exports: [NavigationComponent, RouterModule],
})
export class NavigationModule {}
