import { MapsAPILoader } from '@agm/core';
import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AddressComponent extends FieldType implements AfterViewInit, ControlValueAccessor {
  @ViewChild('addressInput') addressInput: any;

  constructor(private mapsAPILoader: MapsAPILoader) {
    super();
  }

  ngAfterViewInit() {
    this.initMap();
  }

  async initMap() {
    await this.mapsAPILoader.load();

    this.addressInput = new google.maps.places.Autocomplete(this.addressInput.nativeElement, {
      types: ['geocode'],
      fields: ['address_components', 'geometry', 'formatted_address'],
    });

    google.maps.event.addListener(this.addressInput, 'place_changed', () => {
      // Grab the values
      const { address_components, formatted_address } = this.addressInput.getPlace();
      // Set the country
      this.country = address_components;
      // Set the address
      this.address = formatted_address;
      // Propagate the reactive form change
      this.propagateChange(this.address);
      // Mark for check with Formly's change detector
      (this.options as any)._markForCheck(this.field);
    });
  }

  set country(address: any) {
    const country = address.find((component: any) => component.types.includes('country'));
    this.form.get('country').patchValue(country.short_name.toLowerCase());
  }

  set address(address: any) {
    this.formControl.patchValue(address);
  }

  public propagateChange = (_: any) => {};

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(): void {}

  public writeValue(address: any): void {
    this.country = address;
    this.address = address.formatted_address;
  }
}
