import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
@Component({
  selector: 'pixy-modal-html-content',
  templateUrl: './content-modal.component.html',
  styleUrls: ['./content-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentModalComponent implements OnInit {
  @Input() innerContent: string;
  @Input() headerText: string;
  constructor(protected ref: NbDialogRef<ContentModalComponent>) {}

  ngOnInit(): void {}

  cancel() {
    this.ref.close();
  }
}
