import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { Observable } from 'rxjs';
@Component({
  selector: 'pixy-modal-message-form',
  templateUrl: './send-message-form.component.html',
  styleUrls: ['./send-message-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendMessageFormComponent implements OnInit {
  public items: any = [{}];
  public filteredItems: Observable<any> = null;
  public requestFilters = {
    limit: '6',
    page: 1,
  };
  public isLoading = false;
  public totalItems = 0;
  public sendAppNotificationField = new FormControl(false);
  public sendMailField = new FormControl(true);
  public titleField = new FormControl('');
  public messageField = new FormControl('');
  public emailList = new FormControl([]);
  public sendMailStatus = true;
  public sendAppNotificationStatus = true;
  public titleFieldStatus = true;
  public messageFieldStatus = true;
  public emailListStatus = true;
  public messageSent = false;
  public sendingMessage = false;
  @Input() selectedItems: any[] = [];
  @Input() headerText = 'Message Selected';
  @Input() emailCallback: (args: any) => Observable<any>;
  @Input() notificationCallback: (args: any) => Observable<any>;

  constructor(
    protected ref: NbDialogRef<SendMessageFormComponent>,
    protected cd: ChangeDetectorRef,
    private toastrService: NbToastrService
  ) {}

  ngOnInit(): void {
    this.emailList.setValue(this.selectedItems);
    this.refresh();
  }

  cancel() {
    this.ref.close();
  }

  submit() {
    this.sendingMessage = false;
    this.messageSent = false;
    this.sendMailStatus = this.sendMailField.value;
    this.sendAppNotificationStatus = this.sendAppNotificationField.value;
    this.titleFieldStatus = this.titleField.value.length;
    this.messageFieldStatus = this.messageField.value.length;
    this.emailListStatus = this.emailList.value.length;
    if (
      (this.sendMailStatus || this.sendAppNotificationStatus) &&
      this.titleFieldStatus &&
      this.messageFieldStatus &&
      this.emailListStatus
    ) {
      this.sendingMessage = true;
      if (this.sendMailStatus) {
        const emailMessage = {
          to: this.emailList.value.map((item: any) => item.email),
          from: 'no-reply@shiftpixy.com',
          subject: this.titleField.value,
          text: this.messageField.value,
        };
        this.emailCallback(emailMessage).subscribe(
          () => {
            this.messageSent = true;
            this.cd.detectChanges();
            this.toastrService.success(null, 'Message Sent!');
            this.ref.close();
          },
          (error) => {
            console.log(error);
            this.sendingMessage = false;
            this.cd.markForCheck();
          }
        );
      }
      if (this.sendAppNotificationStatus) {
        const notificationMessage = {
          identities: this.emailList.value.map((item: any) => `shifter-${item._id}`),
          title: this.titleField.value,
          body: this.messageField.value,
        };
        this.notificationCallback(notificationMessage).subscribe(
          () => {
            this.messageSent = true;
            this.cd.detectChanges();
            this.toastrService.success(null, 'Message Sent!');
            this.ref.close();
          },
          (error) => {
            console.log(error);
            this.toastrService.success(null, 'Message could not be sent, please try again or contact support!');
            this.sendingMessage = false;
            this.cd.markForCheck();
          }
        );
      }
    }
  }

  refresh() {
    this.cd.detectChanges();
  }
}
