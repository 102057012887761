import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UtilsService } from '@shiftpixy/utils';

@Component({
  selector: 'pixy-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent {
  public isMobile = this.utilsService.isMobile();
  @Input() id: string;
  @Input() maxSize = this.isMobile ? 5 : 8;
  @Output() pageChange = new EventEmitter<number>();
  @Output() pageBoundsCorrection = new EventEmitter<number>();

  constructor(private utilsService: UtilsService) {}
}
