<nb-card class="">
  <nb-card-header class="py-5">
    <h5>{{ headerText }}</h5>
  </nb-card-header>
  <nb-card-body>
    <nb-form-field class="py-5">
      <nb-icon nbPrefix icon="search"></nb-icon>
      <input nbInput [formControl]="searchItemsField" [placeholder]="'Search ' + (searchPlaceholder || '')" />
    </nb-form-field>

    <div class="">
      <pixy-data-table
        id="manager-worksites-collection"
        [collection]="filteredItems || items"
        [isLoading]="isLoading"
        [totalItems]="totalItems"
        [columns]="tableColumns"
        [currentPage]="requestFilters.page"
        (pageChange)="onPageChange($event)"
      >
        <ng-container cdkColumnDef="actions">
          <th cdk-header-cell *cdkHeaderCellDef>
            <div nbButton (click)="addAllItems()">Add</div>
          </th>
          <td cdk-cell *cdkCellDef="let item">
            <div (click)="addSelectedItem(item._id)" class="text-center">
              <nb-icon
                #addIcon
                status="primary"
                icon="plus-circle-outline"
                *ngIf="!isSelected(item._id).isSelected"
              ></nb-icon>
              <nb-icon
                #removeIcon
                status="primary"
                icon="checkmark-circle-2"
                *ngIf="isSelected(item._id).isSelected"
              ></nb-icon>
            </div>
          </td>
        </ng-container>
      </pixy-data-table>
      <h6 class="card-subtitle" *ngIf="selectedItems.length > 0">Selected:</h6>
      <div class="py-5">
        <ng-container *ngFor="let item of selectedItems">
          <button
            class="chip text-capitalize eva-parent-hover"
            nbButton
            shape="round"
            size="small"
            status="primary"
            (click)="removeSelected(item._id)"
          >
            <span>{{ item[tableColumns[0].key] }}</span>
            <nb-icon icon="close-outline" [options]="{ animation: { type: 'shake' } }"></nb-icon>
          </button>
        </ng-container>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer class="py-5 d-flex justify-content-end align-items-center">
    <button
      nbButton
      [appearance]="'outline'"
      class="me-4"
      (click)="cancel()"
      [attr.Data-Test]="'Manager_Worksite_Cancel'"
    >
      Cancel
    </button>
    <button nbButton status="primary" (click)="submit(selectedItems)" [attr.Data-Test]="'Manager_Worksite_Submit'">
      Submit
    </button>
  </nb-card-footer>
</nb-card>
