import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { DataTableColumn } from '@shiftpixy/data';
import { FormField, SHIFTER_CONFIG } from '@shiftpixy/form';
import { UploaderOptions, UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';

@Component({
  selector: 'pixy-modal-docs',
  templateUrl: './add-document-form.component.html',
  styleUrls: ['./add-document-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddDocumentFormComponent implements OnInit {
  public documentType = new FormField({ ...SHIFTER_CONFIG.documentType });
  public documentPath = new FormField({
    ...SHIFTER_CONFIG.documentPath,
  });
  public typeSelected = false;
  @Input() excludeFromSearchList: any[];
  @Input() searchPlaceholder: string;
  @Input() headerText: string;
  @Input() selectedItems: any[] = [];
  @Input() tableColumns: DataTableColumn[] = [];
  options: UploaderOptions;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  dragOver: boolean;
  output: any;
  fileSelected = false;

  constructor(protected ref: NbDialogRef<AddDocumentFormComponent>, protected cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.options = { concurrency: 1, maxUploads: 1, maxFileSize: 0 };
    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.documentType.options = this.documentType.options.filter(
      (i) => this.excludeFromSearchList.indexOf(i.key) === -1
    );
  }

  setOption(value: any) {
    this.documentType.value = value;
    this.typeSelected = true;
  }

  cancel() {
    this.ref.close();
  }

  submit() {
    this.ref.close(this.getDocument());
  }

  refresh() {
    this.cd.detectChanges();
  }

  onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case 'allAddedToQueue':
        break;
      case 'addedToQueue':
        if (typeof output.file !== 'undefined') {
          this.files.push(output.file);
          this.fileSelected = true;
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          const index = this.files.findIndex(
            (file) => typeof output.file !== 'undefined' && file.id === output.file.id
          );
          this.files[index] = output.file;
        }
        break;
      case 'removed':
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'done':
        break;
    }
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  private getDocument(): FormData {
    const formData = new FormData();
    if (this.files.length) {
      formData.append('document', this.files[0].nativeFile);
      formData.append('documentName', `${this.documentType.value}`);
    }
    return formData;
  }
}
