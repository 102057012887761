import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pixy-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateComponent implements OnInit {
  @Input() title = 'No results found';
  @Input() message = 'It seems we can\u0027t find any results based on your search.';
  public params: any;

  constructor() {}

  ngOnInit(): void {}

  public agInit(params: any): void {
    this.params = params;
  }
}
