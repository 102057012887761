<nb-radio-group
  [formControl]="formControl"
  [formlyAttributes]="field"
  [class]="to.class"
  [status]="showError ? 'danger' : 'primary'"
>
  <nb-radio *ngFor="let opt of to.options" [value]="opt.key">
    {{ opt.value }}
  </nb-radio>
</nb-radio-group>
