<nb-card>
  <nb-card-body class="p-6">
    <div class="mb-6">
      <div class="h3">{{ title }}</div>
      <p class="subtitle">{{ description }}</p>
    </div>
    <div class="d-flex justify-content-end align-items-center">
      <button class="cancel" nbButton (click)="cancel()" class="me-4">{{ buttonNoText }}</button>
      <button nbButton [status]="danger ? 'danger' : 'primary'" (click)="submit()">{{ buttonYesText }}</button>
    </div>
  </nb-card-body>
</nb-card>
