import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { UiModule } from '@shiftpixy/ui';
import Amplify, { Auth } from 'aws-amplify';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { LoginPageComponent } from './login-page/login-page.component';
import awsConfig from './providers/aws-cognito';

Amplify.configure(awsConfig);
Auth.configure(awsConfig.Auth.oauth);

@NgModule({
  declarations: [LoginPageComponent],
  imports: [CommonModule, UiModule, AuthRoutingModule],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
})
export class AuthModule {}
