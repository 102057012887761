import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService, ContentType, MiddleWare, RequestConfig } from './../../../services/api/api.service';

const GET_OVERTIME_LIST = `${MiddleWare.Admin}/overtimes`;
const OVERTIME_REVIEW_INFO = `${MiddleWare.Admin}/overtimeReview`;
const SEND_OVERTIME_LOGS = `${MiddleWare.Admin}/overtimeLogs`;

export interface PrismMWResponse {
  employeeIds: Array<string>;
}

@Injectable({
  providedIn: 'root',
})
export class TimesheetHttpService {
  constructor(private apiService: ApiService) {}

  public getTimesheetsPageRoute(): string {
    return 'timesheets';
  }

  getList(_params?: any) {
    const params = {
      ..._params,
    };
    return this.apiService.get(GET_OVERTIME_LIST, params).pipe(map((res: any) => res));
  }

  getOvertimeReviewInfo(_params?: any) {
    const params = {
      ..._params,
    };
    return this.apiService.get(OVERTIME_REVIEW_INFO, params).pipe(map((res: any) => res));
  }

  sendOvertimeReviewInfo(data: any) {
    return this.apiService.post(OVERTIME_REVIEW_INFO, data).pipe(map((res: any) => res));
  }

  sendOvertimeLogs(logs: any[]) {
    return this.apiService.post(SEND_OVERTIME_LOGS, logs).pipe(map((res: any) => res));
  }

  saveOvertimeFile(overtimeData: FormData) {
    const config: RequestConfig = { contentType: ContentType.None };
    return this.apiService.post(GET_OVERTIME_LIST, overtimeData, {}, {}, config).pipe(map((res: any) => res));
  }

  getOvertimeData(overtimeId: string) {
    return this.apiService.get(`${GET_OVERTIME_LIST}?_id=${overtimeId}&page=1`).pipe(map((res: any) => res));
  }

  getOvertimeLogs(overtimeId: string) {
    return this.apiService.get(`${SEND_OVERTIME_LOGS}?overtime=${overtimeId}`).pipe(map((res: any) => res));
  }
}
