import { ChangeDetectionStrategy, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'pixy-quick-tag',
  templateUrl: './quick-tag.component.html',
  styleUrls: ['./quick-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuickTagComponent),
      multi: true,
    },
  ],
})
export class QuickTagComponent implements ControlValueAccessor {
  @Input() public positions: string[];
  @Input() public status: boolean;
  @ViewChild('autoInput') autoInput: any;
  public selectedPositions: string[] = [];
  public filteredPositions$: any;
  public search: FormControl = new FormControl('');
  public collapsed = false;

  constructor() {}

  public setPosition(value: string): void {
    (window as any).dataLayer.push({
      event: 'Click',
      action: 'set',
      category: 'quick-tag',
      label: `Set Position: ${value}`,
    });

    if (this.selectedPositions.includes(value)) {
      this.selectedPositions.splice(this.selectedPositions.indexOf(value), 1);
    } else {
      this.search.setValue('');
      this.filteredPositions$ = [];
      this.selectedPositions.push(value);
    }
    this.propagateChange(this.selectedPositions);
  }

  public onSelectionChange(value: string): void {
    if (value) {
      this.setPosition(value);
      this.filteredPositions$ = this.filter('');
    } else {
      this.autoInput?.nativeElement?.blur();
    }
  }

  public onChange(): void {
    this.filteredPositions$ = this.filter(this.search.value);
  }

  private filter(value: string): string[] {
    return this.positions.filter(
      (position) => position.toLowerCase().includes(value?.toLowerCase()) && !this.selectedPositions.includes(position)
    );
  }

  public clearSelected(): void {
    this.search.reset();
    this.selectedPositions = [];
    this.filteredPositions$ = this.positions;
    this.propagateChange(this.selectedPositions);
  }

  public propagateChange = (_: any) => {};

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(): void {}

  public toggleCollapse(collapse: boolean): void {
    this.collapsed = collapse;
  }

  public writeValue(selectedPositions: string[]): void {
    if (selectedPositions?.length) {
      selectedPositions?.forEach((position) => this.setPosition(position));
      this.collapsed = true;
    }
  }
}
