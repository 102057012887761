<nb-actions class="p-3" *ngIf="state === 'show-actions'" size="small" [fullWidth]="true">
  <nb-action
    *ngFor="let icon of actionIcons; let i = index"
    [icon]="icon.icon"
    [nbPopover]="icon.title"
    nbPopoverAdjustment="noop"
    nbPopoverPlacement="top"
    nbPopoverTrigger="hover"
    nbPopoverClass="delayed"
    status="primary"
    disabled="{{ !icon.callback }}"
    (click)="handleClick(icon, $event)"
  ></nb-action>
</nb-actions>

<ng-container *ngIf="state === 'enter-email'">
  <div class="d-flex email-container p-3">
    <input
      #autoInput
      nbInput
      type="email"
      [formControl]="email"
      (input)="onChange()"
      placeholder="Enter value"
      [nbAutocomplete]="auto"
      [fullWidth]="true"
      (keyup.enter)="doAction()"
    />
    <button class="ms-2" nbButton (click)="doAction()">Send</button>
  </div>
  <nb-autocomplete #auto (selectedChange)="onSelectionChange($event)">
    <nb-option *ngFor="let option of filteredOptions$ | async" [value]="option">
      {{ option }}
    </nb-option>
  </nb-autocomplete>
  <nb-icon class="close-email" icon="close-outline" (click)="returnToActions()"></nb-icon>
</ng-container>

<ng-container *ngIf="state === 'pending'">
  <div class="pending-container d-flex justify-content-center align-items-center p-3">
    <span>{{ currentAction.pending }}</span>
    <nb-icon icon="loader-outline"></nb-icon>
  </div>
</ng-container>

<ng-container *ngIf="state === 'complete'">
  <div class="sent-container d-flex justify-content-center align-items-center p-3">
    <span>{{ currentAction.complete }}</span>
    <nb-icon
      class="ms-2"
      icon="checkmark-circle-2-outline"
      status="success"
      [options]="{ animation: { type: 'pulse' } }"
    ></nb-icon>
  </div>
</ng-container>
