import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormField } from './form-field';

@Injectable()
export class FormService {
  private _isAdmin = true;

  constructor() {}

  public set isAdmin(update: boolean) {
    this._isAdmin = update;
  }

  public getQuestions(mainArray: any[]) {
    const newArray = [];

    for (let i = 0; i < mainArray.length; i++) {
      newArray[i] = { ...mainArray[i] };
    }

    return newArray;
  }

  public toFormGroup(questions: FormField<any>[], validators?: any): FormGroup {
    const group: any = {};

    questions.forEach((question) => {
      group[question.key] = new FormControl(question.value, question.validators, question.asyncValidators);
      if (this._isAdmin) question.autoComplete = 'off';
    });

    return new FormGroup(group, { validators });
  }

  public validateAllFormControl(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.validateAllFormControl(control);
      }
      if (control.enabled) control.markAsTouched({ onlySelf: true });
    });
  }

  public getKeyFromValue(options: any, str: string): string {
    if (!str || !options) return null;
    for (let i = 0; i < options.length; i++) {
      if (options[i].key.toLowerCase() === str.toLowerCase() || options[i].value.toLowerCase() === str.toLowerCase())
        return options[i].key;
    }
    return null;
  }

  public getValueFromKey(options: any, str: string): string {
    if (!str || !options) return null;
    for (let i = 0; i < options.length; i++) {
      if (options[i].key.toLowerCase() === str.toLowerCase()) return options[i].value;
    }
    return null;
  }
  public changeQuestion(
    oldConfig: any,
    newConfig: any,
    questions: any,
    form: any,
    data: any,
    loaded: boolean = false
  ): void {
    const field = questions.find((question: any) => question.key === oldConfig.key);
    Object.keys(newConfig).forEach((key) => {
      if (field.hasOwnProperty(key)) (field as any)[key] = (newConfig as any)[key];
      if (key === 'options') form.controls[newConfig.key].reset();
    });
    if (this.valueToReset(newConfig.key) && loaded)
      form.setControl(oldConfig.key, new FormControl('', newConfig.validators));
    else form.setControl(oldConfig.key, new FormControl(form.controls[newConfig.key].value, newConfig.validators));
    if (newConfig.hasOwnProperty('options')) {
      setTimeout(() => {
        if (this.valueToReset(newConfig.key) && loaded)
          form.patchValue({
            [newConfig.key]: '',
          });
        else
          form.patchValue({
            [newConfig.key]: data ? data[newConfig.key] : '',
          });

        if (newConfig.additionalPatchKey) {
          form.patchValue({
            [newConfig.additionalPatchKey]: data[newConfig.additionalPatchKey],
          });
        }
      }, 0);
    }
  }

  public valueToReset(key: string): boolean {
    if (key.indexOf('tate') >= 0 || key.indexOf('ipCode') >= 0 || key.indexOf('corporate') >= 0) return true;
    return false;
  }

  public insertQuestion(config: any, afterKey: string, questions: any, form: any): boolean {
    if (form.get(config.key)) return false;

    form.addControl(config.key, new FormControl('', config.validators));
    let precedingIndex;
    questions.find((question: any, index: number) => {
      if (question.key === afterKey) {
        precedingIndex = index;
        return question;
      }
    });
    if (precedingIndex) {
      questions.splice(++precedingIndex, 0, new FormField({ ...config }));
    }
    return true;
  }

  public removeQuestion(config: any, afterKey: string, questions: any, form: any): boolean {
    if (!form.get(config.key)) return false;

    form.removeControl(config.key);
    form.updateValueAndValidity();
    let precedingIndex;
    questions.find((question: any, index: number) => {
      if (question.key === afterKey) {
        precedingIndex = index;
        return question;
      }
    });
    if (precedingIndex) {
      questions.splice(++precedingIndex, 1);
    }
    return true;
  }
}
