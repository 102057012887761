import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'pixy-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent extends FieldType implements OnInit, ControlValueAccessor {
  public selection: any;

  constructor() {
    super();
  }

  ngOnInit(): void {}

  public propagateChange = (_: any) => {};

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(): void {}

  public writeValue(selection: any): void {
    this.selection = selection;
  }
}
