import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { AddressDetails } from '@shiftpixy/data';
import { FormField, FormService } from '@shiftpixy/form';

@Component({
  selector: 'pixy-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormService],
})
export class FormComponent implements OnInit {
  @Input() public questions: FormField<string>[] = [];
  @Input() public form: FormGroup;
  @Input() public formName: string;
  @Output() public addressDetails = new EventEmitter<AddressDetails>();
  public requiredValidator = Validators.required;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.cdr.detectChanges();
  }

  public showErrorMessage(question: FormField<string>, isNumeric: boolean = false): boolean {
    const questionKey = question.key;
    if (
      question.autoCapitalize === 'characters' &&
      this.form.controls[questionKey].value &&
      !+this.form.controls[questionKey].value
    ) {
      this.form.controls[questionKey].setValue(this.form.controls[questionKey].value.toUpperCase());
    }
    if (isNumeric && this.form.controls[questionKey].value && this.form.controls[questionKey].value !== '') {
      this.form.controls[questionKey].setValue(Number(this.form.controls[questionKey].value));
    }
    const { touched, valid } = this.form.controls[questionKey];
    return touched && !valid;
  }

  public getSelectedOption(question: FormField<any>, property: 'icon' | 'iconPack' | 'value'): string {
    try {
      return question.options.find((option: any) => option.key === this.form.controls[question.key].value)[property];
    } catch {
      return '';
    }
  }

  public getAddressDetails(addressDetails: AddressDetails) {
    this.addressDetails.emit(addressDetails);
  }
}
