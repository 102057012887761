import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://d258cd9b90da452199e830bf6f04a90b@o288652.ingest.sentry.io/1795573',
  environment: location.hostname,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [
        'https://dev-admin.shiftpixy.com',
        'https://stg-admin.shiftpixy.com',
        'https://admin.shiftpixy.com',
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 0.2,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
