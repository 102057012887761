import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
const lottie: any = require('lottie-web/build/player/lottie.js');

declare var require: any;

@Component({
  selector: 'pixy-lottie',
  templateUrl: './pixy-lottie.component.html',
  styleUrls: ['./pixy-lottie.component.scss'],
})
export class PixyLottieComponent implements AfterViewInit {
  @Input() public path: string;
  @Input() public width: string;
  @Input() public height: string;
  @Input() public autoplay: string;
  @Input() public loop: string;

  public lottieConfig: Object;
  @ViewChild('lottieContainer') lottieContainer: ElementRef;

  constructor() {}

  ngAfterViewInit() {
    this.lottieConfig = {
      container: this.lottieContainer.nativeElement,
      renderer: 'svg',
      loop: this.stringToBoolean(this.loop),
      autoplay: this.stringToBoolean(this.autoplay),
      path: this.path,
      rendererSettings: {},
    };
    lottie.loadAnimation(this.lottieConfig);
  }

  stringToBoolean(string: string) {
    switch (string.toLowerCase().trim()) {
      case 'true':
      case 'yes':
      case '1':
        return true;
      case 'false':
      case 'no':
      case '0':
      case null:
        return false;
      default:
        return true;
    }
  }
}
