import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '@shiftpixy/ui';
import { CustomHeaderComponent } from './custom-header/custom-header.component';
import { DataGridSsrComponent } from './data-grid-ssr-component/data-grid-ssr.component';

@NgModule({
  declarations: [DataGridSsrComponent, CustomHeaderComponent],
  imports: [CommonModule, UiModule, HttpClientModule, FormsModule, ReactiveFormsModule],
  exports: [DataGridSsrComponent, CustomHeaderComponent],
})
export class DataGridModule {}
