<nb-card class="">
  <nb-card-header class="py-5">
    <h5>{{ headerText }}</h5>
  </nb-card-header>
  <nb-card-body>
    <nb-form-field class="py-5">
      <label class="mb-2 d-block fw-bold">
        {{ documentType.label }}
        <span>*</span>
      </label>
      <div class="input-group mb-3">
        <nb-select class="custom-select" [placeholder]="documentType.placeholder">
          <nb-option *ngFor="let opt of documentType.options" [value]="opt.value" (click)="setOption(opt.key)">
            <div class="me-3">
              {{ opt.value }}
            </div>
          </nb-option>
        </nb-select>
      </div>
    </nb-form-field>

    <div class="py-5">
      <ng-container cdkColumnDef="actions">
        <label class="mb-2 d-block fw-bold">
          {{ documentPath.label }}
          <span>*</span>
        </label>
        <label class="form-control d-flex">
          <input
            type="file"
            fullWidth
            nbButton
            ngFileSelect
            status="primary"
            [options]="options"
            (uploadOutput)="onUploadOutput($event)"
            [uploadInput]="uploadInput"
            [disabled]="!typeSelected"
            single
          />
        </label>
      </ng-container>
    </div>
  </nb-card-body>
  <nb-card-footer class="py-5 d-flex justify-content-end align-items-center">
    <button
      nbButton
      [appearance]="'outline'"
      class="me-4"
      (click)="cancel()"
      [attr.Data-Test]="'Shifter_Document_Cancel'"
    >
      Cancel
    </button>
    <button
      nbButton
      status="primary"
      (click)="submit()"
      [disabled]="!fileSelected"
      [attr.Data-Test]="'Shifter_Document_Submit'"
    >
      Submit
    </button>
  </nb-card-footer>
</nb-card>
