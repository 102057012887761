<input
  nbInput
  fullWidth
  #addressInput
  name="addressInput"
  [formControl]="addressForm"
  [placeholder]="placeholder"
  type="text"
  [attr.autocomplete]="'new-password'"
  onfocus="this.setAttribute('autocomplete', 'new-password');"
  [status]="showError ? 'warning' : 'basic'"
/>
