import { Pipe, PipeTransform } from '@angular/core';
import { NbTreeGridDataSource } from '@nebular/theme';
import { PaginatePipe } from 'ngx-pagination';

/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
 */
@Pipe({ name: 'pagination' })
export class PaginationPipe implements PipeTransform {
  constructor(private paginatePipe: PaginatePipe) {}

  public transform(collection: any, args: any): NbTreeGridDataSource<any> {
    if (!collection) {
      return null;
    }

    if (!args) {
      console.error('You have to inform the args argument to the pagination pipe');
      return null;
    }

    return this.paginatePipe.transform(collection, args);
  }
}
