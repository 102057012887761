import { Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { Observable, throwError, of } from 'rxjs';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private dialogService: NbDialogService) {}

  open(data: Partial<ConfirmDialogComponent>): Observable<any> {
    const dialog = this.dialogService.open(ConfirmDialogComponent, { context: data });

    return dialog.onClose.pipe(
      switchMap((isConfirmed: boolean) => {
        if (isConfirmed) {
          return of(isConfirmed);
        }
        return throwError({});
      })
    );
  }

  confirm(data: Partial<ConfirmDialogComponent>): Observable<any> {
    const dialog = this.open({
      danger: false,
      buttonNoText: 'No',
      buttonYesText: 'Yes',
      ...data,
    });

    return dialog;
  }

  confirmDanger(data: Partial<ConfirmDialogComponent>): Observable<any> {
    const dialog = this.open({
      danger: true,
      buttonNoText: 'Cancel',
      ...data,
    });

    return dialog;
  }
}
