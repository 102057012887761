<div class="container d-flex justify-content-center align-items-center">
  <ng-container *ngFor="let page of rootMenuPages">
    <a *ngIf="page.children" outline nbButton ghost [nbContextMenu]="page.children" nbContextMenuTag="shifter-menu">
      {{ page.title }}
    </a>
    <button
      *ngIf="!page.children"
      nbButton
      ghost
      ga-action="click"
      ga-category="header"
      [attr.ga-label]="page.path"
      [routerLink]="page.path"
      routerLinkActive="active-link"
    >
      {{ page.title }}
    </button>
  </ng-container>
</div>
