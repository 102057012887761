<div class="container-table" [nbSpinner]="isLoading" nbSpinnerSize="giant" nbSpinnerStatus="primary">
  <div *ngIf="collection">
    <div *ngIf="collection.length > 0; else sad_pixy">
      <table class="data-table" cdk-table [dataSource]="collection | pagination: paginationConfig">
        <tr cdk-header-row *cdkHeaderRowDef="allColumns"></tr>
        <tr *cdkRowDef="let row; columns: allColumns" (click)="onRowClick(row, $event)" cdk-row></tr>

        <ng-container *ngFor="let column of columns" [cdkColumnDef]="column.key">
          <th cdk-header-cell *cdkHeaderCellDef (click)="onSortChange(column)" [class.sorting]="column.sortable">
            <div class="d-flex justify-content-start align-items-center">
              {{ column.label }}
              <div *ngIf="column.sortable" class="sorting-icon-container">
                <nb-icon
                  icon="chevron-up-outline"
                  class="sorting-icon"
                  [class.disabled]="sortColumn !== column.key"
                  [class.desc]="sortColumn === column.key && sortDirection === 'desc'"
                ></nb-icon>
              </div>
            </div>
          </th>
          <td cdk-cell *cdkCellDef="let row">
            <div class="d-flex justify-content-start align-items-center">
              <img *ngIf="column.image" class="column-image me-2" [src]="row | transformData: column.image" />
              <nb-icon
                *ngIf="column.icon"
                class="column-icon me-2"
                [icon]="row | transformData: column.icon"
                [pack]="column.iconPack || 'eva'"
              ></nb-icon>
              <div class="column-value d-flex justify-content-start align-items-center">
                <ng-container *ngIf="column.transformData">
                  {{ row | transformData: column.transformData }}
                </ng-container>
                <ng-container *ngIf="!column.pipe && !column.transformData">
                  {{ row[column.key] }}
                </ng-container>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-content></ng-content>
      </table>
      <div class="row justify-content-center mt-6">
        <pixy-pagination [id]="paginationConfig.id" (pageChange)="onPageChange($event)"></pixy-pagination>
      </div>
    </div>
  </div>
</div>
<ng-template #sad_pixy>
  <pixy-empty-state></pixy-empty-state>
</ng-template>
