import { Route } from '@angular/router';
import { Feature } from '../../services/feature-flag/feature-flag.service';

export interface MenuPage extends Route {
  title?: string;
  featureFlags?: Feature[];
  children?: MenuPage[];
}

export const menuPages: MenuPage[] = [
  {
    path: 'clients',
    title: 'Clients',
    loadChildren: () => import('../clients/clients.module').then((m) => m.ClientsModule),
    data: {
      featureFlags: [Feature.ClientsTab],
    },
  },
  {
    path: 'global-worksites',
    title: 'Worksites',
    loadChildren: () => import('../global-worksites/global-worksites.module').then((m) => m.GlobalWorksitesModule),
    data: {
      featureFlags: [Feature.GlobalWorksitesTab],
    },
  },
  {
    path: 'global-managers',
    title: 'Managers',
    loadChildren: () => import('../global-managers/global-managers.module').then((m) => m.GlobalManagersModule),
    data: {
      featureFlags: [Feature.GlobalManagersTab],
    },
  },
  {
    path: 'global-shifters',
    title: 'Shifters',
    data: {},
    children: [
      {
        path: 'all',
        title: 'Shifters',
        loadChildren: () => import('../global-shifters/global-shifters.module').then((m) => m.GlobalShiftersModule),
        data: {
          featureFlags: [Feature.GlobalShiftersTab],
        },
      },
      {
        path: 'organic',
        title: 'Organic Shifters',
        loadChildren: () => import('../organic-shifters/organic-shifters.module').then((m) => m.OrganicShiftersModule),
        data: {
          featureFlags: [Feature.OrganicShiftersTab],
        },
      },
      {
        path: 'benefits',
        title: 'Benefits',
        loadChildren: () => import('../benefits/benefits.module').then((m) => m.BenefitsModule),
        data: {
          featureFlags: [Feature.BenefitsTab],
        },
      },
      {
        path: 'message-logs',
        title: 'Message Logs',
        loadChildren: () => import('../message-logs/message-logs.module').then((m) => m.MessageLogsModule),
        data: {
          featureFlags: [Feature.MessageLogsTab],
        },
      },
      {
        path: 'video-profiles',
        title: 'Video Profiles',
        loadChildren: () => import('../video-profiles/video-profiles.module').then((m) => m.VideoProfilesModule),
        data: {
          featureFlags: [Feature.VideoProfileTab],
        },
      },
      {
        path: 'legacy-shifters',
        title: 'Legacy Shifters',
        loadChildren: () => import('../legacy-shifters/legacy-shifters.module').then((m) => m.LegacyShiftersModule),
        data: {
          featureFlags: [Feature.LegacyShiftersTab],
        },
      },
    ],
  },
  {
    path: 'timesheets',
    title: 'Timesheets',
    children: [
      {
        path: 'timesheets2',
        title: 'Payroll Calculations',
        loadChildren: () =>
          import('../payroll-timesheets/payroll-timesheets.module').then((m) => m.PayrollTimesheetsModule),
        data: {
          featureFlags: [Feature.TimesheetsTab2],
        },
      },
      {
        title: 'USW Payroll Calculations',
        path: 'timesheets',
        loadChildren: () => import('../timesheets/timesheets.module').then((m) => m.TimesheetsModule),
        data: {
          featureFlags: [Feature.TimesheetsTab],
        },
      },
    ],
  },
  {
    path: 'certificates',
    title: 'Certificates',
    loadChildren: () => import('../certificates/certificates.module').then((m) => m.CertificatesModule),
    data: {
      featureFlags: [Feature.CertificatesTab],
    },
  },
];
