import { Injectable } from '@angular/core';
import { ClientDetail, EmailExportOptions, GlobalShifter, GlobalWorksite } from '@shiftpixy/data';

const statusNames: any = {
  active: 'Active',
  inactive: 'Inactive',
  onLeave: 'On Leave',
  terminated: 'Terminated',
};

const globalExportOptionsTitle: any = {
  csv: 'CSV',
  xlsx: 'EXCEL',
};

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  public isMobile(): boolean {
    return window.innerWidth < 768;
  }

  public copyToClipboard(value: any): void {
    if (value !== null) {
      const dummyElement = document.createElement('textarea');
      document.body.appendChild(dummyElement);
      dummyElement.value = value;
      dummyElement.select();
      document.execCommand('copy');
      document.body.removeChild(dummyElement);
    }
  }

  public getStatusKey(name: string): string {
    const statusKeys = Object.assign({}, ...Object.entries(statusNames).map(([a, b]) => ({ [b as string]: a })));
    return statusKeys[name] || '';
  }

  public getStatusName(status: string): string {
    return statusNames[status] || '';
  }

  public getStatusNames(): string[] {
    return Object.values(statusNames);
  }

  public getWorksitePrismHR(worksite: GlobalWorksite): string {
    if (worksite) {
      return worksite.prismHR || 'N/A';
    }

    return 'N/A';
  }

  public getClientPrismHR(client: ClientDetail): string {
    if (client) {
      return client.prismHR || 'N/A';
    }

    return 'N/A';
  }

  public getWorksiteAddress(addressType: 'physical' | 'billing', worksite: any) {
    return {
      [`globalWorskiteCountry`]: worksite.address[`${addressType}Address`].country,
      [`globalWorskiteStreetAddress`]: worksite.address[`${addressType}Address`].streetAddress,
      [`globalWorskiteCity`]: worksite.address[`${addressType}Address`].city,
      [`globalWorskiteState`]: worksite.address[`${addressType}Address`].state,
      [`globalWorskiteZipCode`]: worksite.address[`${addressType}Address`].zipCode,
      [`globalWorskiteCounty`]: addressType === 'physical' ? worksite.address[`${addressType}Address`].county : null,
    };
  }

  public getWorksiteOnboardedCount(worksite: any) {
    if (!worksite.onBoardedStatus) {
      return {
        notStartedCount: 0,
        inProgressCount: 0,
        onboardedCount: 0,
        notSentCount: 0,
      };
    }
    return {
      notStartedCount: worksite.onBoardedStatus.notStarted || 0,
      inProgressCount: worksite.onBoardedStatus.inProgress || 0,
      onboardedCount: worksite.onBoardedStatus.onBoarded || 0,
      notSentCount: worksite.onBoardedStatus.pendingForms || 0,
    };
  }

  public getOnboardingStatus(shifter: GlobalShifter): string {
    const statuses = shifter.onboardingStatus;
    let value = [''];
    for (const key in statuses) {
      if (statuses[key] === 'In Progress') {
        value = key.split(/(?=[A-Z])/);
      }
    }
    const displayString = value.join(' ');

    if (displayString === '') {
      return 'N/A';
    }

    return displayString;
  }

  public getGlobalExportOptionsTitle(format: EmailExportOptions): string {
    return globalExportOptionsTitle[format];
  }
}
