import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { DataTableColumn } from '@shiftpixy/data';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'pixy-modal-form',
  templateUrl: './add-entity-form.component.html',
  styleUrls: ['./add-entity-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEntityFormComponent implements OnInit {
  public items: any = [{}];
  public filteredItems: Observable<any> = null;
  public requestFilters = {
    limit: '6',
    page: 1,
  };
  public isLoading = false;
  public totalItems = 0;
  public searchItemsField = new FormControl('');
  @Input() excludeFromSearchList: any[] = [];
  @Input() searchPlaceholder: string;
  @Input() headerText: string;
  @Input() selectedItems: any[] = [];
  @Input() tableColumns: DataTableColumn[] = [];

  constructor(protected ref: NbDialogRef<AddEntityFormComponent>, protected cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.searchItemsField.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe((val: string) => {
      this.searchedText(val);
      this.requestFilters.page = 1;
    });
  }

  public removeSelected(key: any) {
    const updatedList: any[] = [];
    this.selectedItems.forEach((item) => {
      if (item._id !== key) {
        updatedList.push(item);
      }
    });

    this.selectedItems = updatedList;
  }

  addSelectedItem(key: any) {
    const object = this.isSelected(key);
    if (!object.isSelected) {
      this.items.forEach((item: { _id: any }) => {
        if (item._id === key) {
          this.selectedItems.push(item);
        }
      });
    } else {
      this.removeSelected(key);
    }
  }

  addAllItems() {
    let object;
    if (this.allSelected()) {
      this.selectedItems = [];
    } else {
      this.items.forEach((item: { _id: any }) => {
        object = this.isSelected(item._id);
        if (!object.isSelected) this.selectedItems.push(item);
      });
    }
  }

  isSelected(key: any) {
    const object = { isSelected: false, item: {} };
    this.selectedItems.forEach((item: { _id: any }) => {
      if (item._id === key) {
        object.isSelected = true;
        object.item = item;
      }
    });
    return object;
  }

  allSelected(): boolean {
    let areAllSelected = true;
    this.items.forEach((item: { _id: any }) => {
      if (!this.isSelected(item._id).isSelected) {
        areAllSelected = false;
      }
    });
    return areAllSelected;
  }

  searchedText(searchedString: string) {
    if (searchedString.length === 0 || !searchedString.trim()) {
      this.filteredItems = null;
    } else {
      this.filteredItems = this.items.filter((_target: any) => {
        const objectWithoutProperties = this._objectWithoutProperties(_target, this.excludeFromSearchList);
        return (
          Object.values(objectWithoutProperties).filter((el: any) =>
            JSON.stringify(el).toLowerCase().includes(searchedString.toLowerCase())
          ).length > 0
        );
      });
    }
    this.cd.markForCheck();
  }

  private _objectWithoutProperties(obj: any, keys: any) {
    const target: any = {};
    for (const i in obj) {
      if (keys.indexOf(i) >= 0) continue;
      if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
      target[i] = obj[i];
    }
    return target;
  }

  cancel() {
    this.ref.close();
  }

  submit(value: any) {
    this.ref.close(value);
  }

  refresh() {
    this.cd.detectChanges();
  }

  onPageChange(page: number): void {
    this.requestFilters.page = page;
  }
}
