import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActionButton } from '@shiftpixy/data';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StorageService } from '../../../services/storage/storage.service';
import { AuthService } from '../../auth/services/auth.service';

@UntilDestroy()
@Component({
  selector: 'shiftpixy-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent implements OnInit {
  showPassword = false;
  loginForm: FormGroup;
  isLoginFormVisible = false;
  switchLoginText = 'Sign in with email/password';

  public action: ActionButton[] = [
    {
      text: 'Sign In',
      value: 'sign-ing',
      callback: () => this.logIn(),
      pending: {
        text: 'Signing In...',
        icon: 'loader-outline',
      },
      completed: {
        text: 'Signed!',
      },
    },
  ];

  public ssoAction: ActionButton[] = [
    {
      text: 'Sign in with Microsoft',
      value: 'sign-ing',
      callback: () => this.loginWithSSO(),
      pending: {
        text: 'Signing In...',
        icon: 'loader-outline',
      },
      completed: {
        text: 'Signed!',
      },
    },
  ];

  constructor(private authService: AuthService, private fb: FormBuilder, private storage: StorageService) {
    this.storage.clear();
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.navigateLoggedUser();
    }
  }

  getInputType(): String {
    if (this.showPassword) {
      return 'text';
    }
    return 'password';
  }

  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  logIn(): Observable<any> {
    return this.authService.login(this.loginForm.value).pipe(tap(() => this.navigateLoggedUser()));
  }

  login(): any {
    return this.logIn().pipe(untilDestroyed(this)).subscribe();
  }

  loginWithSSO() {
    this.authService.loginWithSSO().then(() => {
      return of(true);
    });

    return new Observable<any>();
  }

  toggleLoginFormVisibility() {
    this.isLoginFormVisible = this.isLoginFormVisible ? false : true;
    this.switchLoginText = this.isLoginFormVisible ? 'Sign in with Microsoft' : 'Sign in with email/password';
  }

  public navigateLoggedUser() {
    this.authService.navigateToDefaultPage();
  }
}
