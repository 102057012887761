// src/app/auth/token.interceptor.ts
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StorageService } from '../../../services/storage/storage.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private toastrService: NbToastrService,
    private storage: StorageService
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isIgnoreToast(request)) {
      return;
    }

    return next.handle(request).pipe(
      catchError((err: any) => {
        let message = err.message;
        if (err.error.message) {
          message = err.error.message;

          if (message.includes('sisCode')) {
            let num = +message.substr(0, 2);
            num++;
            message = message.replace(/sisCode/, ', sicCode');
            message = num + ' ' + message.substr(2);
          }
        }

        if ((err instanceof HttpErrorResponse && err.status === 403) || message === 'jwt expired') {
          if (this.storage.hasClientId()) {
            message = 'Your session has timed out. Please log back in';
          }
          this.authService.logout();
        }

        if (!this.errorsExcluded(err, message, request)) this.toastrService.danger(null, message);

        return throwError(err);
      })
    );
  }

  errorsExcluded(err: any, message: string, request: any): boolean {
    console.error('err', err);
    if (err.status === 500 && /(shifter|prism\/check)/.test(err.url)) {
      return true;
    }

    if (err.status === 400 && err.url.includes('client') && request.body.country === 'CA') {
      if (message.includes('state') || message.includes('zipCode')) {
        message = message.replace(/state/, 'province');
        message = message.replace(/zipCode/, 'postalCode');
      }
      if (request.body.businessNumber === '' && !message.includes('Business Number')) {
        let num = +message.substr(0, 2);
        num++;
        message = num + ' ' + message.substr(2) + ', businessNumber';
      }
      this.toastrService.danger(null, message);
      return true;
    }

    if (
      err.status === 400 &&
      err.url.includes('worksite') &&
      (message.includes('state') || message.includes('zipCode'))
    ) {
      if (request.body.address.physicalAddress.country === 'CA') {
        message = message.replace(/state/, 'province');
        message = message.replace(/physicalZipCode/, 'physicalPostalCode');
      }
      if (request.body.address.billingAddress.country === 'CA') {
        message = message.replace(/billingZipCode/, 'billingPostalCode');
      }
      this.toastrService.danger(null, message);
      return true;
    }

    return false;
  }

  isIgnoreToast(request: HttpRequest<any>): boolean {
    /**
     * Ignore notification for empty status filter
     */
    if (request.method === 'GET' && request.params.has('status') && !request.params.get('status')) {
      return true;
    }

    return false;
  }
}
