import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { Feature, FeatureFlagService } from './feature-flag.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagGuard implements CanActivate {
  constructor(private featureFlagService: FeatureFlagService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.featureFlagService.updated.pipe(
      filter((updated) => updated),
      map(() => {
        if (!route.data?.featureFlags) {
          return true;
        }
        let result = [];
        for (const featureFlag of route.data.featureFlags as Feature[]) {
          result.push(this.featureFlagService.isFeatureEnabled(featureFlag));
        }

        return result.every((r) => r);
      }),
      tap((result) => {
        if (!result) {
          this.router.navigate(['']);
        }
      })
    );
  }
}
