import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { StorageService } from '../storage/storage.service';

class CustomEncoder implements HttpParameterCodec {
  encodeKey = (key: string) => encodeURIComponent(key);
  encodeValue = (value: string) => encodeURIComponent(value);
  decodeKey = (key: string) => decodeURIComponent(key);
  decodeValue = (value: string) => decodeURIComponent(value);
}

export enum MiddleWare {
  Admin = 'admin-portal',
  OfferLetter = 'offer-letter',
  Benefits = 'benefits',
  S3Upload = 's3-upload',
}

export enum MiddleWareUrlEnvSufix {}

export enum MiddleWareUrlEnvInDomain {}

// maps the names for performance
const MiddleWareMap: any = {};
for (const value of Object.values(MiddleWare)) {
  MiddleWareMap[value] = value;
}

const MiddleWareUrlEnvSufixMap: any = {};
for (const value of Object.values(MiddleWareUrlEnvSufix)) {
  MiddleWareUrlEnvSufixMap[value] = value;
}

const MiddleWareUrlEnvInDomainMap: any = {};
for (const value of Object.values(MiddleWareUrlEnvInDomain)) {
  MiddleWareUrlEnvInDomainMap[value] = value;
}

export enum ContentType {
  None = '',
  ApplicationJson = 'application/json',
}

export enum ApiUrl {
  TempApiUrl = 'apiUrl',
  NewApiUrl = 'newApiUrl',
  HumanityApiUrl = 'humanityApiURL',
  OnboardingApiUrl = 'onboardingApiURL',
  GlobalExportApiUrl = 'globalExportApiURL',
  AdminApiCluster = 'adminApiCluster',
}

export interface RequestConfig {
  contentType?: ContentType;
  apiUrl?: ApiUrl;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private defaultRequestConfig: RequestConfig = {
    contentType: ContentType.ApplicationJson,
    apiUrl: ApiUrl.TempApiUrl,
  };

  constructor(private http: HttpClient, private toaster: NbToastrService, private storage: StorageService) {}

  convertToHttpParams(data: any) {
    let params = new HttpParams({ encoder: new CustomEncoder() });

    Object.keys(data).forEach((item) => {
      if (Array.isArray(data[item])) {
        data[item].forEach((value: any) => {
          params = params.append(item, value);
        });
      } else {
        params = params.set(item, data[item]);
      }
    });

    return params;
  }

  getAuthorizationHeader() {
    return { Authorization: 'Bearer ' + this.storage.getToken() };
  }

  getContentTypeHeader(contentType: ContentType = ContentType.ApplicationJson) {
    return contentType === ContentType.None ? {} : { 'Content-Type': contentType };
  }

  getDefaultHeaders() {
    return {
      ...this.getContentTypeHeader(ContentType.ApplicationJson),
      ...this.getAuthorizationHeader(),
    };
  }

  private trackEvent(action: string, label: any): void {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      event: 'Event',
      category: 'http-request',
      action: action,
      label: label,
    });
  }
  get<T = any>(url: string, params?: { [key: string]: string }, _headers?: any, config: RequestConfig = {}) {
    this.trackEvent('get', url);

    const headers = {
      ...this.getDefaultHeaders(),
      ..._headers,
    };

    return this.http.get<T>(
      this.getMiddleWareBaseUrl(url, config?.apiUrl || this.defaultRequestConfig.apiUrl, environment.production),
      {
        params: params ? this.convertToHttpParams(params) : null,
        headers: new HttpHeaders(headers),
      }
    );
  }

  getBlob(url: string, config: RequestConfig = {}) {
    const headers = this.getDefaultHeaders();

    return this.http.get(
      this.getMiddleWareBaseUrl(url, config?.apiUrl || this.defaultRequestConfig.apiUrl, environment.production),
      {
        responseType: 'blob',
        headers: new HttpHeaders(headers),
      }
    );
  }

  post<T = any>(
    url: string,
    body: any,
    params?: { [key: string]: string },
    _headers?: any,
    config: RequestConfig = {}
  ) {
    this.trackEvent('post', url);

    const headers = {
      ...this.getAuthorizationHeader(),
      ...this.getContentTypeHeader(config?.contentType),
      ..._headers,
    };

    return this.http.post<T>(
      this.getMiddleWareBaseUrl(url, config?.apiUrl || this.defaultRequestConfig.apiUrl, environment.production),
      body,
      {
        params: params ? this.convertToHttpParams(params) : null,
        headers: new HttpHeaders(headers),
      }
    );
  }

  postBlob(url: string, body: any, params?: { [key: string]: string }, _headers?: any, config: RequestConfig = {}) {
    this.trackEvent('post', url);

    const headers = {
      ...this.getAuthorizationHeader(),
      ...this.getContentTypeHeader(config?.contentType),
      ..._headers,
    };

    return this.http.post(
      this.getMiddleWareBaseUrl(url, config?.apiUrl || this.defaultRequestConfig.apiUrl, environment.production),
      body,
      {
        params: params ? this.convertToHttpParams(params) : null,
        headers: new HttpHeaders(headers),
        responseType: 'blob' as 'json',
      }
    ) as Observable<Blob>;
  }

  patch<T = any>(
    url: string,
    body: any,
    params?: { [key: string]: string },
    _headers?: any,
    config: RequestConfig = {}
  ) {
    this.trackEvent('post', url);

    const headers = {
      ...this.getAuthorizationHeader(),
      ...this.getContentTypeHeader(config?.contentType),
      ..._headers,
    };

    return this.http.patch<T>(
      this.getMiddleWareBaseUrl(url, config?.apiUrl || this.defaultRequestConfig.apiUrl, environment.production),
      body,
      {
        params: params ? this.convertToHttpParams(params) : null,
        headers: new HttpHeaders(headers),
      }
    );
  }

  delete(url: string, body: any, config: RequestConfig = {}): Observable<any> {
    this.trackEvent('delete', url);

    const options = {
      headers: new HttpHeaders(this.getDefaultHeaders()),
      body,
    };

    return this.http.delete(
      this.getMiddleWareBaseUrl(url, config?.apiUrl || this.defaultRequestConfig.apiUrl, environment.production),
      options
    );
  }

  put(url: string, body: any, params?: { [key: string]: string }, _headers?: any, config: RequestConfig = {}) {
    this.trackEvent('put', url);

    const headers = {
      ...this.getDefaultHeaders(),
      ..._headers,
    };

    return this.http.put(
      this.getMiddleWareBaseUrl(url, config?.apiUrl || this.defaultRequestConfig.apiUrl, environment.production),
      body,
      {
        params: params ? this.convertToHttpParams(params) : null,
        headers: new HttpHeaders(headers),
      }
    );
  }

  handleSuccess(data: any) {
    this.trackEvent('success', JSON.stringify(data));

    if (typeof data.message === 'string') {
      this.toaster.success(null, data.message);
    }
  }

  handleError(error: any) {
    this.trackEvent('error', JSON.stringify(error));
  }

  private getApiUrlMiddleWare(isProduction: boolean, serviceName: MiddleWare): string {
    if (MiddleWareUrlEnvSufixMap[serviceName]) {
      return `https://${serviceName}.shiftpixy.io/${isProduction ? 'prod' : 'stg'}`;
    }

    // This is so we can req
    if (MiddleWareUrlEnvInDomainMap[serviceName]) {
      console.log('in domain');

      return `https://${serviceName}${isProduction ? '' : '-staging'}.shiftpixy.io`;
    }

    return `https://${isProduction ? '' : 'stg-'}${serviceName}.shiftpixy.io`;
  }

  public getMiddleWareBaseUrl(url: string, apiUrl: ApiUrl, isProduction: boolean = false) {
    if (url.includes('http')) return url;

    const serviceName = url.substring(0, url.indexOf('/'));
    if (serviceName === 'localhost') {
      const r = `http://localhost:3000/stg${url.replace(serviceName, '')}`;
      console.log('localhost', r);

      return r;
    }

    if (serviceName === 'localhost_new') {
      let newUrl = url.replace(serviceName, '');
      if (newUrl[0] === '/') {
        newUrl = newUrl.substring(1);
      }
      const r = `http://localhost:3000/${newUrl}`;
      console.log('localhost', r);

      return r;
    }

    if (MiddleWareMap[serviceName]) {
      return url.replace(serviceName, this.getApiUrlMiddleWare(isProduction, MiddleWareMap[serviceName]));
    }

    return `${environment[apiUrl]}${url}`;
  }
}
