import { FormGroup } from '@angular/forms';

export function EitherOr(controlName: string, alternateControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const alternateControl = formGroup.controls[alternateControlName];

    if (control.errors || alternateControl.errors || (!control.value && !alternateControl.value)) {
      return { eitherOr: true };
    }

    return null;
  };
}
