<nb-card>
  <nb-card-header class="py-5">
    <h5>{{ headerText }}</h5>
  </nb-card-header>
  <nb-card-body>
    <div class="py-5">
      <ng-container cdkColumnDef="actions">
        <div class="icon">
          <img width="81px" src="assets/icons/csv.svg" alt="Csv Icon" />
          <h3>Drag and drop</h3>
          <p>your file here</p>
          <p>or</p>
        </div>
        <label class="mb-2 d-block fw-bold">
          {{ documentPath.label }}
          <span>*</span>
        </label>
        <label class="form-control d-flex">
          <input
            type="file"
            fullWidth
            nbButton
            ngFileSelect
            status="primary"
            [options]="options"
            (uploadOutput)="onUploadOutput($event)"
            [uploadInput]="uploadInput"
            single
          />
        </label>
      </ng-container>
    </div>
  </nb-card-body>
  <nb-card-footer class="py-5 d-flex justify-content-end align-items-center">
    <button nbButton [appearance]="'outline'" class="mr-4" (click)="cancel()">Cancel</button>
    <button nbButton status="primary" (click)="submit()" [disabled]="!fileSelected">Submit</button>
  </nb-card-footer>
</nb-card>
