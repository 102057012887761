<input
  nbInput
  fullWidth
  onKeyDown="return false"
  [placeholder]="to.placeholder"
  [formControl]="formControl"
  [nbDatepicker]="datePicker"
  [attr.autocomplete]="to.autoComplete"
  [status]="showError ? 'danger' : 'primary'"
  [formlyAttributes]="field"
  [attr.autocomplete]="'new-password'"
  onfocus="this.setAttribute('autocomplete', 'new-password');"
/>
<nb-datepicker #datePicker></nb-datepicker>
