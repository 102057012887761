<nb-card size="large">
  <nb-card-header class="d-flex justify-content-between align-items-baseline">
    <p class="modal-name">Update Shifter Info</p>
    <button class="ms-auto close" (click)="close()" ghost nbButton>
      <nb-icon icon="close"></nb-icon>
    </button>
  </nb-card-header>
  <nb-card-body *ngIf="type === 'form'">
    <div class="d-flex justify-content-between align-items-baseline">
      <h6>{{ title }}</h6>
      <button class="flex-1" nbButton outline status="primary" (click)="save()">Save</button>
    </div>
    <nb-accordion>
      <nb-accordion-item *ngFor="let group of form.controls | keyvalue">
        <nb-accordion-item-header *ngIf="group.value.controls">
          <div class="row">
            <div class="col-12">
              <h6>{{ labelMaps[group.key] || group.key }}</h6>
            </div>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body *ngIf="group.value.controls">
          <div class="row" [formGroup]="group.value">
            <ng-container *ngFor="let question of group.value.controls | keyvalue">
              <div class="col-12 col-md-6 mb-4">
                <label class="d-block fw-bold" [attr.for]="'modal-' + group.key + '-' + question.key">
                  <small>{{ labelMaps[question.key] || question.key }}</small>
                </label>
                <input
                  [class.error]="question.value.invalid"
                  [attr.disabled]="['email'].includes(question.key.toLowerCase()) ? true : null"
                  nbInput
                  [formControl]="question.value"
                  [id]="'modal-' + group.key + '-' + question.key"
                />
                <small
                  class="d-block text-danger"
                  *ngIf="errorMessages[question.key] && question.value.invalid && question.value.touched"
                >
                  {{ errorMessages[question.key] }}
                </small>
              </div>
            </ng-container>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>

    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="mt-5">
            Issues?
            <a target="_blank" href="mailto:incoming+shiftpixy-shiftpixy-17309824-issue-@incoming.gitlab.com">
              Email us!
            </a>
          </p>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
