import { AgGridModule } from '@ag-grid-community/angular';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { AgmCoreModule } from '@agm/core';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbAccordionModule,
  NbActionsModule,
  NbAutocompleteModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbDialogService,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbMenuModule,
  NbOverlayModule,
  NbPopoverModule,
  NbProgressBarModule,
  NbRadioModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSelectModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbStepperModule,
  NbTableModule,
  NbTabsetModule,
  NbThemeModule,
  NbToastrModule,
  NbToggleModule,
  NbTooltipModule,
  NbTreeGridModule,
  NbUserModule,
} from '@nebular/theme';
import { FormlyModule } from '@ngx-formly/core';
import { UtilsModule } from '@shiftpixy/utils';
import { MaskPipe, NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule, PaginatePipe } from 'ngx-pagination';
import { NgPipesModule } from 'ngx-pipes';
import { NgxUploaderModule } from 'ngx-uploader';
import { ActionBarComponent } from './action-bar/action-bar.component';
import { ActionButtonComponent } from './action-button/action-button.component';
import { AlertModule } from './alert/alert.module';
import { AutocompleteAddressComponent } from './autocomplete-address/autocomplete-address.component';
import { DataGridComponent } from './data-grid/data-grid.component';
import { DataTableComponent } from './data-table/data-table.component';
import { TransformDataPipe } from './data-table/transform-data.pipe';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { FilterButtonComponent } from './filter-button/filter-button.component';
import { FormComponent } from './form/form.component';
import { AddressComponent } from './formly/address/address.component';
import { CheckboxComponent } from './formly/checkbox/checkbox.component';
import { DateRangeComponent } from './formly/date-range/date-range.component';
import { DateComponent } from './formly/date/date.component';
import { InputComponent } from './formly/input/input.component';
import { RadioComponent } from './formly/radio/radio.component';
import { SelectComponent } from './formly/select/select.component';
import { TaggerComponent } from './formly/tagger/tagger.component';
import { TextareaComponent } from './formly/textarea/textarea.component';
import { ToggleComponent } from './formly/toggle/toggle.component';
import { WrapperComponent } from './formly/wrapper/wrapper.component';
import { wrapperConfig } from './formly/wrapper/wrapper.config';
import { PixyIconsPackModule } from './icons-pack/icons-pack.module';
import { ModalShellComponent } from './modal-shell/modal-shell.component';
import { AddDocumentFormComponent } from './modal-trigger/add-document-form/add-document-form.component';
import { AddDroppedFileFormComponent } from './modal-trigger/add-dropped-file-form/add-dropped-file-form.component';
import { AddEntityFormComponent } from './modal-trigger/add-entity-form/add-entity-form.component';
import { ContentModalComponent } from './modal-trigger/content-modal/content-modal.component';
import { ContentModalPipe } from './modal-trigger/content-modal/content-modal.pipe';
import { DocsTriggerComponent } from './modal-trigger/docs-trigger.component';
import { MessageTriggerComponent } from './modal-trigger/message-trigger.component';
import { ModalTriggerComponent } from './modal-trigger/modal-trigger.component';
import { SendMessageFormComponent } from './modal-trigger/send-message-form/send-message-form.component';
import { PaginationComponent } from './pagination/pagination.component';
import { PaginationPipe } from './pagination/pagination.pipe';
import { PixyLottieComponent } from './pixy-lottie/pixy-lottie.component';
import { QuickTagFilterPipe } from './quick-tag/quick-tag-filter.pipe';
import { QuickTagComponent } from './quick-tag/quick-tag.component';
import { StatusOverviewComponent } from './status-overview/status-overview.component';
import { StatusStepperComponent } from './status-stepper/status-stepper.component';

LicenseManager.setLicenseKey(
  'CompanyName=ShiftPixy Inc,LicensedGroup=SPX,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=0,AssetReference=AG-011645,ExpiryDate=6_November_2021_[v2]_MTYzNjE1NjgwMDAwMA==2a544785934991ff32303f6a879aa801'
);

ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule, ServerSideRowModelModule]);

const NB_MODULES = [
  NbCardModule,
  NbLayoutModule,
  NbUserModule,
  NbActionsModule,
  NbAccordionModule,
  NbSearchModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbTableModule,
  NbTreeGridModule,
  NbListModule,
  NbIconModule,
  NbInputModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbEvaIconsModule,
  NbStepperModule,
  NbProgressBarModule,
  NbAutocompleteModule,
  NbTooltipModule,
  NbFormFieldModule,
  NbRadioModule,
  NbToggleModule,
  NbSpinnerModule,
  NbOverlayModule,
  NgPipesModule,
];

const components = [
  ActionButtonComponent,
  QuickTagComponent,
  QuickTagFilterPipe,
  FilterButtonComponent,
  StatusStepperComponent,
  StatusOverviewComponent,
  ActionBarComponent,
  FormComponent,
  PaginationComponent,
  PaginationPipe,
  TransformDataPipe,
  DataTableComponent,
  DocsTriggerComponent,
  ModalTriggerComponent,
  MessageTriggerComponent,
  AddEntityFormComponent,
  SendMessageFormComponent,
  AddDocumentFormComponent,
  AddDroppedFileFormComponent,
  EmptyStateComponent,
  PixyLottieComponent,
  AutocompleteAddressComponent,
  ModalShellComponent,
  WrapperComponent,
  InputComponent,
  DateComponent,
  AddressComponent,
  SelectComponent,
  RadioComponent,
  TaggerComponent,
  ToggleComponent,
  TextareaComponent,
  DataGridComponent,
  CheckboxComponent,
  DateRangeComponent,
  ContentModalComponent,
];

const pipes = [ContentModalPipe];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UtilsModule,
    NbThemeModule.forRoot({ name: 'pixy' }),
    NbDialogModule.forRoot({}),
    NbSidebarModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbMenuModule.forRoot(),
    NgxMaskModule.forRoot(),
    NbToastrModule.forRoot({ duration: 5000, destroyByClick: true, preventDuplicates: true }),
    NgxPaginationModule,
    CdkTableModule,
    PixyIconsPackModule,
    AlertModule,
    NgxUploaderModule,
    ...NB_MODULES,
    FormlyModule.forRoot(wrapperConfig),
    AgGridModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB5FsNxRqD6kVZgbNdss1sXMAQFep_Y_C8',
      libraries: ['places'],
    }),
  ],
  exports: [
    ...NB_MODULES,
    NbThemeModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    NbDialogModule,
    NbSidebarModule,
    NbDatepickerModule,
    NbInputModule,
    NbMenuModule,
    NbOverlayModule,
    NbToastrModule,
    CdkTableModule,
    PixyIconsPackModule,
    NgxUploaderModule,
    AgGridModule,
    AgmCoreModule,
    ...components,
  ],
  providers: [
    MaskPipe,
    NbDialogService,
    QuickTagFilterPipe,
    PaginationPipe,
    PaginatePipe,
    TransformDataPipe,
    QuickTagComponent,
    FilterButtonComponent,
  ],
  declarations: [...components, ...pipes],
  entryComponents: [ModalShellComponent],
})
export class UiModule {}
