import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { EmaillExportParams, OfferLetter, OnboardingStatus, Shifter } from '@shiftpixy/data';
import { AlertService } from '@shiftpixy/ui';
import { UtilsService } from '@shiftpixy/utils';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Feature, FeatureFlagService } from '../../../services/feature-flag/feature-flag.service';
import { StorageService } from '../../../services/storage/storage.service';
import { ApiService, ApiUrl, ContentType, MiddleWare, RequestConfig } from './../../../services/api/api.service';

export const SHIFTERS_ENDPOINT = 'shifter';
const SHIFTERS_NODE_ENDPOINT = `${MiddleWare.Admin}/shifter`;
const PRISM_HR_ENDPOINT = `${MiddleWare.Admin}/prism`;
const EXPORT_ALL_SHIFTER_ENDPOINT = 'export/shifter/all';
const EXPORT_SHIFTER_ENDPOINT = 'export/shifter';
// const EXPORT_CLIENT_ENDPOINT = 'export/client';
const PRESIGNED_URL_ENDPOINT = 'presignedurl';
const NEW_HIRE_PACKET_URL = 'docs';
const RESEND_HIRE_PACKET_URL = 'v2/docs';
const OFFER_LETTER_CREATE = `${MiddleWare.OfferLetter}/admin/create`;
const ADMIN_SHIFTER_URL = `${MiddleWare.Admin}/${SHIFTERS_ENDPOINT}`;
// const ADMIN_SHIFTERS_API_URL = `${MiddleWare.Admin}/shifters`;
// const ORGANIC_SHIFTERS_ENDPOINT = `${MiddleWare.Admin}/shifters/organic`;

// localhost_new
const ADMIN_SHIFTERS_API_URL = `shifters/legacy`;
const ADMIN_EXPORTS_URL = `exports/latest`;
const ADMIN_SHIFTERS_ORGANIC_API_URL = `shifters/organic/legacy`;

// const ADMIN_SHIFTERS_API_URL = `localhost_new/shifters/legacy`;
// const ADMIN_SHIFTERS_ORGANIC_API_URL = `localhost_new/shifters/organic/legacy`;

export interface PrismMWResponse {
  employeeIds: Array<string>;
}

@Injectable({
  providedIn: 'root',
})
export class ShiftersHttpService {
  constructor(
    // private route: ActivatedRoute,
    private apiService: ApiService,
    private alertService: AlertService,
    private storage: StorageService,
    private featureFlagService: FeatureFlagService,
    // private maskPipe: MaskPipe,
    public utilsService: UtilsService,
    private toastrService: NbToastrService
  ) {}

  getList(_params?: any) {
    const params = {
      clientRef: this.storage.getClientIdFromSessionOrLocal(),
      limit: 100,
      ..._params,
    } as any;

    return this.apiService
      .get(
        ADMIN_SHIFTERS_API_URL,
        params,
        {},
        {
          apiUrl: ApiUrl.AdminApiCluster,
        }
      )
      .pipe(
        map(
          (res: {
            results: any[];
            metaData: {
              page: number;
              total_pages: number;
              limit: number;
              count: number;
            };
          }) => res
        )
      );
  }

  searchList(params: any) {
    return this.apiService.get(ADMIN_SHIFTER_URL, params).pipe(map((res: any) => res.message));
  }

  getDetail(id: string) {
    return this.apiService.get(`${SHIFTERS_NODE_ENDPOINT}/${id}`);
  }

  checkPrismHR(id: string) {
    return this.apiService.get(`${PRISM_HR_ENDPOINT}/check/${id}`);
  }

  importToPrismHR(id: string) {
    return this.apiService.post(`${PRISM_HR_ENDPOINT}/import/${id}`, {});
  }

  save(
    data: Shifter,
    id: string = null,
    status?: OnboardingStatus,
    sendOfferLetter?: boolean,
    workflowId?: string,
    worksiteId?: string
  ) {
    data.clientRef = this.storage.getClientIdFromSessionOrLocal();

    if (id) {
      if (this.featureFlagService.isFeatureEnabled(Feature.NewShifterPutEndpoint)) {
        return this.apiService.put(`${SHIFTERS_NODE_ENDPOINT}/${id}`, data);
      } else {
        return this.apiService.put(`${SHIFTERS_ENDPOINT}/${id}`, data);
      }
    }

    data['onboardingStatus'] = status;

    if (sendOfferLetter) {
      return this.sendOfferLetter(data, workflowId, worksiteId);
    }

    return this.apiService.post(SHIFTERS_ENDPOINT, data);
  }

  sendOfferLetter(data: Shifter, workflowId: string, worksiteId: string) {
    const offerLetter: OfferLetter = {
      workflowId,
      managerId: data.worksiteManager,
      firstName: data.firstName,
      lastName: data.lastName,
      worksiteId,
      phoneNumber: data.phoneNumber,
      email: data.email,
      status: data.status,
      position: data.position,
      payType: data.payType,
      payRate: data.payRate,
      employmentType: data.employmentType,
      hireDate: data.hireDate,
      payGroup: data.payGroup,
      payMethod: data.payMethod,
      onboardingType: data.onboardingType,
      isDriver: data.isDriver,
      onboardingStatus: data.onboardingStatus,
      gender: data.gender,
    };
    return this.apiService.post(OFFER_LETTER_CREATE, offerLetter);
  }

  sendExportCSV(status: string = 'all') {
    const clientId = this.storage.getClientIdFromSessionOrLocal();
    const config: RequestConfig = { apiUrl: ApiUrl.GlobalExportApiUrl };
    let template = status;
    if (status === 'all') {
      // TODO: Handle this as if it's "shifter"
      template = 'shifters';
    }

    /*
    const worksiteCode = this.route.snapshot.queryParamMap.get('worksiteId');
    const extra = worksiteCode ? { worksiteCode } : {};
    */

    return this.apiService
      .post(
        'request',
        {
          format: 'csv',
          email: this.storage.getAdminEmail(),
          template,
          query: {
            clientRef: clientId,
            // ...extra,
          },
        } as EmaillExportParams,
        {},
        {},
        config
      )
      .pipe()
      .subscribe((payload: any) => {
        this.toastrService.success(
          null,
          `Download has started for ${this.utilsService.getGlobalExportOptionsTitle({
            template,
            format: 'csv',
          } as any)} file.`
        );

        window.location.href = `${environment.globalExportApiURL}/download/${payload.id}`;
      }); /*
    const clientId = this.storage.getClientIdFromSessionOrLocal();
    const config: RequestConfig = { apiUrl: ApiUrl.NewApiUrl };
    return this.apiService.post(
      `${EXPORT_CLIENT_ENDPOINT}/${clientId}/shifter?csv=${status}`,
      {
        email: this.storage.getAdminEmail(),
      },
      {},
      {},
      config
    );*/
  }

  sendExportSingle(shifterId: string) {
    const config: RequestConfig = { apiUrl: ApiUrl.NewApiUrl };
    return this.apiService.post(
      `${EXPORT_SHIFTER_ENDPOINT}/${shifterId}`,
      {
        email: this.storage.getAdminEmail(),
      },
      {},
      {},
      config
    );
  }

  sendExportAll() {
    const config: RequestConfig = { apiUrl: ApiUrl.NewApiUrl };
    return this.apiService.post(
      `${EXPORT_ALL_SHIFTER_ENDPOINT}`,
      {
        email: this.storage.getAdminEmail(),
      },
      {},
      {},
      config
    );
  }

  resendNewHirePacket(shifterId: string, status: string) {
    if (status === 'terminated') {
      return this.alertService.confirmDanger({
        title: 'Shifter Terminated',
        description: `To resend the New Hire Packet document of this shifter is needed to change the shifter\u0027s status and save the profile first.`,
        buttonYesText: 'Got it!',
      });
    }

    if (this.featureFlagService.isFeatureEnabled(Feature.NewEmbeddedSigningEndpoint)) {
      const config: RequestConfig = { apiUrl: ApiUrl.OnboardingApiUrl };
      return this.apiService.post('document-sign/resend-docs', { sessionId: shifterId }, {}, {}, config);
    } else {
      return this.apiService.post(RESEND_HIRE_PACKET_URL, { sessionId: shifterId });
    }
  }

  sendNewHirePacketReminder(id: string, status: string) {
    if (status === 'terminated') {
      return this.alertService.confirmDanger({
        title: 'Shifter Terminated',
        description: `To send the New Hire Packet reminder to this shifter is needed to change the shifter\u0027s status and save the profile first.`,
        buttonYesText: 'Got it!',
      });
    }

    const config: RequestConfig = { apiUrl: ApiUrl.OnboardingApiUrl };
    return this.apiService.post(`document-sign/resend-nhp-email/${id}`, { sessionId: id }, {}, {}, config);
  }

  sendShifterToHumanity(shifterId: string) {
    const config: RequestConfig = { apiUrl: ApiUrl.OnboardingApiUrl };
    return this.apiService.post(`document-sign/shifter-to-humanity/${shifterId}`, {}, {}, {}, config);
  }

  toggleStatus(shifter: Shifter, id: string): Observable<any> {
    if (shifter.status === 'active') {
      return this.save(shifter, id);
    }

    return this.alertService
      .confirmDanger({
        title: 'Disable Shifter',
        description: `Are you sure you want to disable this shifter?`,
        buttonYesText: 'Disable shifter',
      })
      .pipe(switchMap(() => this.save(shifter, id)));
  }

  getSignedUrl(url: string) {
    return this.apiService.post(`${ADMIN_SHIFTER_URL}/presignedUrl`, { url }, {}, {});
  }

  getNewHirePacketUrl(id: string) {
    if (this.featureFlagService.isFeatureEnabled(Feature.NewEmbeddedSigningEndpoint)) {
      const config: RequestConfig = { apiUrl: ApiUrl.OnboardingApiUrl };
      return this.apiService.get(`document-sign/new-hire-package/${id}`, {}, {}, config);
    }

    return this.apiService.get(`${NEW_HIRE_PACKET_URL}/${id}`);
  }

  private deleteHirePacketRequest(shifter: Shifter, packetId: string) {
    const shifterId = shifter._id;
    shifter.onboardingStatus.newHirePacketSent = 'Not Started';
    shifter.onboardingStatus.newHirePacketSigned = 'Not Started';

    if (this.featureFlagService.isFeatureEnabled(Feature.NewEmbeddedSigningEndpoint)) {
      const config: RequestConfig = { apiUrl: ApiUrl.OnboardingApiUrl };
      return this.apiService.delete(`document-sign/new-hire-package/${shifterId}`, { hirePacket: [packetId] }, config);
    }

    return this.apiService.delete(`internaluser/hire-packet?shifter=${shifterId}`, { hirePacket: [packetId] });
  }

  deleteHirePacket(shifter: Shifter, packetId: string): Observable<any> {
    if (shifter.status === 'terminated') {
      return this.alertService.confirmDanger({
        title: 'Shifter Terminated',
        description: `To delete the New Hire Packet document of this shifter is needed to change the shifter\u0027s status and save the profile first.`,
        buttonYesText: 'Got it!',
      });
    }

    return this.alertService
      .confirmDanger({
        title: 'Permanently Delete File',
        description: `Are you sure you want to delete this file permanently?`,
        buttonYesText: 'Move to Trash',
      })
      .pipe(switchMap(() => this.deleteHirePacketRequest(shifter, packetId)));
  }

  sendDocument(id: string, document: FormData) {
    const config: RequestConfig = { contentType: ContentType.None, apiUrl: ApiUrl.NewApiUrl };
    return this.apiService.post(`${SHIFTERS_ENDPOINT}/${id}/documents`, document, {}, {}, config);
  }

  sendDocumentTerminatedShifter() {
    return this.alertService.confirmDanger({
      title: 'Shifter Terminated',
      description: `To add a new document for this shifter is needed to change the shifter\u0027s status and save the profile first.`,
      buttonYesText: 'Got it!',
    });
  }

  private deleteDocumentRequest(shifter: Shifter, file: any) {
    const shifterId = shifter._id;
    const config: RequestConfig = { apiUrl: ApiUrl.NewApiUrl };
    const document = {
      document: file.key,
    };
    return this.apiService.delete(`${SHIFTERS_ENDPOINT}/${shifterId}/documents`, document, config);
  }

  deleteDocument(shifter: Shifter, file: any): Observable<any> {
    if (shifter.status === 'terminated') {
      return this.alertService.confirmDanger({
        title: 'Shifter Terminated',
        description: `To delete a document of this shifter is needed to change the shifter\u0027s status and save the profile first.`,
        buttonYesText: 'Got it!',
      });
    }

    return this.alertService
      .confirmDanger({
        title: 'Permanently Delete File',
        description: `Are you sure you want to delete this file permanently?`,
        buttonYesText: 'Move to Trash',
      })
      .pipe(switchMap(() => this.deleteDocumentRequest(shifter, file)));
  }

  onboardingRequired() {
    return this.alertService
      .confirm({
        title: 'Onboarding Required',
        description: `Do you want to required the onboarding process for each shifter before uploading?`,
        buttonYesText: 'Yes',
      })
      .pipe(switchMap(() => this.confirmOnboardingRequired()));
  }

  private confirmOnboardingRequired() {
    const url = 'return function to change after BE changes';
    return this.apiService.post(`${PRESIGNED_URL_ENDPOINT}`, { url });
  }

  uploading() {
    return this.alertService.confirm({
      title: '',
      description: `Uploading`,
    });
  }

  uploadComplete() {
    return this.alertService.confirm({
      title: '',
      description: `Upload Complete.`,
      buttonYesText: 'Got it!',
    });
  }

  uploadError() {
    return this.alertService.confirmDanger({
      title: 'Uh oh!',
      description: `We couldn\u0027t upload your file.`,
      buttonYesText: 'Got it!',
    });
  }

  getOrganicShifters(params?: any) {
    return this.apiService
      .get(
        ADMIN_SHIFTERS_ORGANIC_API_URL,
        params,
        {},
        {
          apiUrl: ApiUrl.AdminApiCluster,
        }
      )
      .pipe(
        map(
          (res: {
            results: any[];
            metaData: {
              page: number;
              total_pages: number;
              limit: number;
              count: number;
            };
          }) => res
        )
      );
  }

  getOrganicShiftersExport(params?: any) {
    return this.apiService.postBlob(
      `${ADMIN_SHIFTERS_ORGANIC_API_URL}/export`,
      {
        ...params,
      },
      {},
      {},
      {
        apiUrl: ApiUrl.AdminApiCluster,
      }
    );
  }

  getShiftersExport(params?: any) {
    return this.apiService.postBlob(
      `${ADMIN_SHIFTERS_API_URL}/export`,
      {
        ...params,
      },
      {},
      {},
      {
        apiUrl: ApiUrl.AdminApiCluster,
      }
    );
  }

  /*
  private formatDate(date: Date | string) {
    return date ? new Date(date).toLocaleString() : '';
  }
  */

  getShifterDetails(id: string) {
    return this.getDetail(id).pipe(
      map((res: any) => {
        const shifter = res.message as Shifter;
        shifter.name = `${shifter.firstName} ${shifter.lastName}`;
        shifter.interestedPositions = shifter.interestedPositions.map((p: any) => p.position);

        return shifter;
      })
    );
  }

  getExportUrl(params: { create_link: string }, type: string = "shifters") {
    return this.apiService
      .get(
        ADMIN_EXPORTS_URL,
        {
          ...params,
          type: type,
        },
        {},
        {
          apiUrl: ApiUrl.AdminApiCluster,
        }
      )
      .pipe(
        map(
          (res: {
            result: {
              url: string;
              createdAt: string;
              expiresIn: number;
            };
          }) => res
        )
      );
  }
}
