<nb-card class="dropdown-container mb-0">
  <nb-card-header class="border-0" [ngClass]="{ 'pt-3 pb-0': collapsed, 'pb-3': selectedPositions.length }">
    <span *ngIf="!collapsed">Select Positions</span>
    <div *ngIf="collapsed" class="d-flex align-items-center">
      <span>Positions</span>
      <button
        nbButton
        class="ms-4 text-capitalize"
        appearance="ghost"
        (click)="toggleCollapse(false)"
        ga-action="click"
        ga-category="quick-tag"
        ga-label="edit"
      >
        <nb-icon class="me-2" icon="edit"></nb-icon>
        <span>Edit</span>
      </button>
    </div>
  </nb-card-header>
  <nb-card-body *ngIf="!collapsed">
    <div class="autocomplete-search">
      <input
        nbInput
        type="text"
        (input)="onChange()"
        fullWidth
        placeholder="Search for more positions"
        [nbAutocomplete]="auto"
        [formControl]="search"
        #autoInput
        [status]="status ? 'danger' : 'primary'"
      />

      <nb-autocomplete #auto (selectedChange)="onSelectionChange($event)">
        <nb-option *ngFor="let option of filteredPositions$" [value]="option">
          {{ option }}
        </nb-option>
      </nb-autocomplete>
    </div>
    <div class="checkboxes">
      <nb-checkbox
        *ngFor="let position of positions"
        [checked]="selectedPositions.includes(position)"
        [status]="status ? 'danger' : 'primary'"
        (checkedChange)="setPosition(position)"
      >
        {{ position }}
      </nb-checkbox>
    </div>
  </nb-card-body>
  <nb-card-footer *ngIf="selectedPositions.length" [ngClass]="{ 'border-0': collapsed, 'pb-3 pt-0': collapsed }">
    <ng-container *ngFor="let position of positions">
      <button
        class="chip text-capitalize eva-parent-hover"
        *ngIf="selectedPositions.includes(position)"
        nbButton
        shape="round"
        size="small"
        status="primary"
        (click)="setPosition(position)"
      >
        <span>{{ position }}</span>
        <nb-icon icon="close-outline" [options]="{ animation: { type: 'shake' } }"></nb-icon>
      </button>
    </ng-container>

    <div *ngIf="!collapsed" class="d-flex align-items-center justify-content-between footer-buttons">
      <button
        nbButton
        class="text-capitalize"
        appearance="ghost"
        (click)="clearSelected()"
        ga-action="click"
        ga-category="quick-tag"
        ga-label="clear"
      >
        Clear All
      </button>
      <button
        nbButton
        class="text-capitalize"
        appearance="ghost"
        status="primary"
        (click)="toggleCollapse(true)"
        ga-action="click"
        ga-category="quick-tag"
        ga-label="done"
      >
        Done
      </button>
    </div>
  </nb-card-footer>
</nb-card>
