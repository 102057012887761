import { Injectable } from '@angular/core';
import { NbTreeGridDataSource } from '@nebular/theme';
import { Client } from '@shiftpixy/data';
import { AlertService } from '@shiftpixy/ui';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, ApiUrl, MiddleWare, RequestConfig } from '../../../services/api/api.service';
import { Feature, FeatureFlagService } from '../../../services/feature-flag/feature-flag.service';

const OFFER_LETTER_TEMPLATES = `${MiddleWare.OfferLetter}/templates`;
const EXPORT_OFFER_LETTER_REPORT = `${MiddleWare.OfferLetter}/admin/report`;

const CLIENT_URL = `${MiddleWare.Admin}/client`;
const CLIENT_POSITION_URL = `${MiddleWare.Admin}/clientpositions`;

@Injectable({
  providedIn: 'root',
})
export class ClientHttpService {
  private get clientsUrl() {
    return this.featureFlagService.isFeatureEnabled(Feature.NewClientsEndpoint) ? CLIENT_URL : 'client';
  }

  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    private featureFlagService: FeatureFlagService
  ) {}

  getList(params?: any): Observable<NbTreeGridDataSource<Client>> {
    // 5-30-2023 Per Kristina, Always use stg-admin.shiftpixy.io instead of temp-api.shiftpixy.com/stg and ignore the feature flag
    // https://gitlab.com/shiftpixy/shiftpixy/-/feature_flags/28/edit
    return this.apiService.get(CLIENT_URL, params).pipe(map((res: any) => res.message));
  }

  getDetail(id: string): Observable<any> {
    return forkJoin([this.apiService.get(`${this.clientsUrl}/${id}`), this.getHumanityConfig(id)]).pipe(
      map(([clientResult, humanityResult]: [any, any]) => {
        clientResult.message.humanityId = humanityResult.humanityId;
        clientResult.message.humanityDomain = humanityResult.domain;
        return clientResult;
      })
    );
  }

  getClientPositions(): Observable<any> {
    const url = this.featureFlagService.isFeatureEnabled(Feature.NewClientsEndpoint)
      ? CLIENT_POSITION_URL
      : 'clientpositions';
    return this.apiService.get(url);
  }

  getTemplates(): Observable<any> {
    return this.apiService.get(OFFER_LETTER_TEMPLATES);
  }

  save(data: any, id: string = null) {
    data.clientImage = 'https://www.blobstorage.com/client/FFQQFF';
    const api = id
      ? this.apiService.put(`${this.clientsUrl}/${id}`, data)
      : this.apiService.post(this.clientsUrl, data);

    return api;
  }

  saveHumanityConfig(data: any) {
    const config: RequestConfig = { apiUrl: ApiUrl.HumanityApiUrl };
    return this.apiService.post(`humanity/client/config`, data, {}, {}, config);
  }

  getHumanityConfig(id: string) {
    const config: RequestConfig = { apiUrl: ApiUrl.HumanityApiUrl };
    return this.apiService.get(`humanity/client/config`, { clientRef: id }, {}, config).pipe(
      map((humanityConfig: any) => {
        humanityConfig.humanityId = !humanityConfig.humanityId ? '' : humanityConfig.humanityId;
        humanityConfig.domain = humanityConfig.domain === 'none' ? '' : humanityConfig.domain;

        return humanityConfig;
      })
    );
  }

  toggleStatus(client: Client, id: string, activeComponents: boolean): Observable<any> {
    if (client.status === 'active') {
      return this.save(client, id);
    }

    if (activeComponents) {
      return this.alertService.confirmDanger({
        title: 'Disable Client Profile',
        description: `Are you sure you want to disable this Client’s Profile? To disable the Client it's necessary to disable all active Worksites, Managers, and Shifters first.`,
        buttonYesText: 'Got it!',
      });
    } else {
      return this.save(client, id);
    }
  }

  public getClientsPageRoute(): string {
    return 'clients';
  }

  public getClientsDetailRoute(clientId: string): string {
    return `clients/${clientId}`;
  }

  public exportOfferLetterReport() {
    return this.apiService.getBlob(`${EXPORT_OFFER_LETTER_REPORT}`);
  }
}
