<div [class]="to.wrapperClass" class="mt-3">
  <label [for]="id" [class]="to.labelClass">
    <strong>
      {{ to.label }}
      <span class="ms-n1" *ngIf="to.required"><sup>*</sup></span>
    </strong>
  </label>
  <ng-container #fieldComponent></ng-container>
  <small class="text-danger w-100" *ngIf="showError">{{ to.error }}</small>
</div>
