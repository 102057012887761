<nb-select
  fullWidth
  nbSelect
  [formControl]="formControl"
  [attr.autocomplete]="to.autoComplete"
  [placeholder]="to.placeholder"
  [status]="showError ? 'danger' : 'primary'"
  [formlyAttributes]="field"
  [multiple]="to.multiple"
>
  <nb-select-label *ngIf="getSelectedOption(to, 'icon')">
    <nb-icon
      class="me-3"
      nbPrefix
      [icon]="getSelectedOption(to, 'icon')"
      [pack]="getSelectedOption(to, 'iconPack') || 'eva'"
    ></nb-icon>
    {{ getSelectedOption(to, 'value') }}
  </nb-select-label>
  <nb-option *ngFor="let opt of to.options" [value]="opt.key">
    <div class="d-flex align-items-center">
      <nb-icon *ngIf="opt.icon" class="me-3" nbPrefix [icon]="opt.icon" [pack]="opt.iconPack || 'eva'"></nb-icon>
      {{ opt.value }}
      <span *ngIf="opt.secondaryValue" class="text-primary ms-2">{{ opt.secondaryValue }}</span>
    </div>
  </nb-option>
</nb-select>
