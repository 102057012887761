import { AsyncValidatorFn, ValidatorFn } from '@angular/forms';

export interface IFormField {
  key: string;
  type?: string;
  placeholder?: string;
  label?: string;
  validators?: ValidatorFn[];
  asyncValidators?: AsyncValidatorFn[];
  mask?: string;
  order?: number;
  dataTest?: string;
  errorMessage?: string;
  autoComplete?: string;
  class?: string;
  options?: { key: string; value: string; icon?: string; iconPack?: string; secondaryValue?: string }[];
  positions?: string[];
  keyboardType?: string;
  autoCapitalize?: string;
  icon?: string;
  iconPack?: string;
  value?: any;
  ca?: IFormField;
  readonly?: boolean;
  maxLength?: number;
  country?: string;
  protocolURL?: IFormField;
  domainURL?: IFormField;
  disabled?: boolean;
}

export class FormField<T> {
  public type: string;
  public placeholder: string;
  public value: T;
  public key: string;
  public label: string;
  public validators: ValidatorFn[];
  public asyncValidators: AsyncValidatorFn[];
  public mask: string;
  public order: number;
  public dataTest: string;
  public errorMessage: string;
  public autoComplete: string;
  public class: string;
  public options: { key: string; value: string; icon?: string; iconPack?: string; secondaryValue?: string }[];
  public positions: string[];
  public keyboardType: string;
  public autoCapitalize: string;
  public icon: string;
  public iconPack: string;
  public ca: IFormField;
  public readonly: boolean;
  public maxLength?: number;
  public country?: string;
  public protocolURL: IFormField;
  public domainURL: IFormField;
  public disabled: boolean;

  constructor(config: IFormField) {
    this.type = config.type;
    this.placeholder = config.placeholder;
    this.value = config.value;
    this.key = config.key || '';
    this.label = config.label || '';
    this.validators = config.validators;
    this.asyncValidators = config.asyncValidators;
    this.mask = config.mask;
    this.order = config.order === undefined ? 1 : config.order;
    this.dataTest = config.dataTest;
    this.errorMessage = config.errorMessage;
    this.autoComplete = config.autoComplete;
    this.class = config.class;
    this.options = config.options;
    this.positions = config.positions;
    this.keyboardType = config.keyboardType;
    this.autoCapitalize = config.autoCapitalize;
    this.icon = config.icon;
    this.iconPack = config.iconPack || 'eva';
    this.ca = config.ca;
    this.readonly = config.readonly || null;
    this.maxLength = config.maxLength || null;
    this.country = config.country || '';
    this.protocolURL = config.protocolURL;
    this.domainURL = config.domainURL;
    this.disabled = config.disabled || false;
  }
}
