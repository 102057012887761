import { Pipe, PipeTransform } from '@angular/core';
import { QuickTag } from '@shiftpixy/data';
@Pipe({
  name: 'filter'
})
export class QuickTagFilterPipe implements PipeTransform {
  public transform(items: QuickTag[], searchText: string, max: number): QuickTag[] {
    searchText = searchText?.toLowerCase();

    return items.filter((item) => {
      return searchText ? item.value.toLowerCase().includes(searchText) && !item.selected : !item.selected;
    }).slice(0,max);
  }
}
