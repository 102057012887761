<pagination-template
  #p="paginationApi"
  [id]="id"
  [maxSize]="maxSize"
  (pageChange)="pageChange.emit($event)"
  (pageBoundsCorrection)="pageBoundsCorrection.emit($event)"
>
  <div class="d-flex justify-content-center align-items-center">
    <button
      *ngIf="!p.isFirstPage()"
      (click)="p.previous()"
      nbButton
      ghost
      status="basic"
      size="small"
      ga-action="click"
      ga-category="pagination"
      ga-label="prev"
    >
      Prev
    </button>

    <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
      <button
        [class.me-2]="!isMobile"
        *ngIf="p.getCurrent() !== page.value"
        (click)="p.setCurrent(page.value)"
        nbButton
        ghost
        status="basic"
        size="small"
        ga-action="click"
        ga-category="pagination"
        [attr.ga-label]="page.label"
      >
        {{ page.label }}
      </button>
      <button
        [class.me-2]="!isMobile"
        *ngIf="p.getCurrent() === page.value"
        nbButton
        ghost
        disabled
        status="basic"
        size="small"
        outline
      >
        {{ page.label }}
      </button>
    </div>

    <button
      *ngIf="!p.isLastPage()"
      (click)="p.next()"
      nbButton
      ghost
      status="basic"
      size="small"
      ga-action="click"
      ga-category="pagination"
      ga-label="next"
    >
      Next
    </button>
  </div>
</pagination-template>
