<nb-card class="">
  <nb-card-header class="py-5">
    <h5>{{ headerText }}</h5>
  </nb-card-header>
  <nb-card-body>
    <div class="py-5" [innerHTML]="innerContent | safeHtml"></div>
  </nb-card-body>
  <nb-card-footer class="py-5 d-flex justify-content-end align-items-center">
    <button nbButton [appearance]="'outline'" class="me-4" (click)="cancel()">Cancel</button>
  </nb-card-footer>
</nb-card>
