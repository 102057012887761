import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FeatureFlagGuard } from '../services/feature-flag/feature-flag.guard';
import { Feature } from '../services/feature-flag/feature-flag.service';
import { AuthGuard } from './auth/services/auth.guard';
import { menuPagesRoutes } from './navigation/navigation.module';
import { RedirectComponent } from './redirect.component';

const routes: Routes = [
  {
    path: '',
    component: RedirectComponent,
  },
  ...menuPagesRoutes,
  {
    path: 'worksites',
    loadChildren: () => import('./worksites/worksites.module').then((m) => m.WorksitesModule),
    canActivate: [AuthGuard, FeatureFlagGuard],
    data: {
      featureFlags: [Feature.ClientsTab],
    },
  },
  {
    path: 'managers',
    loadChildren: () => import('./managers/managers.module').then((m) => m.ManagersModule),
    canActivate: [AuthGuard, FeatureFlagGuard],
    data: {
      featureFlags: [Feature.ClientsTab],
    },
  },
  {
    path: 'shifters',
    loadChildren: () => import('./shifters/shifters.module').then((m) => m.ShiftersModule),
    canActivate: [AuthGuard, FeatureFlagGuard],
    data: {
      featureFlags: [Feature.ClientsTab],
    },
  },
  { path: '**', redirectTo: 'clients' }, // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
