import { ComponentType } from '@angular/cdk/overlay';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CellClickedData, SelectedRowsAction } from '@shiftpixy/data';
import { PaginationInstance } from 'ngx-pagination';
import { EmptyStateComponent } from '../empty-state/empty-state.component';

@Component({
  selector: 'pixy-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataGridComponent implements OnChanges, AfterViewChecked, OnInit {
  @Input() id = 'collection-list';
  @Input() isLoading = true;
  @Input() totalItems: number;
  @Input() currentPage: number;
  @Input() columnDefs: any[] = [];
  @Input() selectedRows: any[] = [];
  @Input() selectedActionLabel = '';
  @Input() selectedActionKey = 'delete';
  @Input() rowData: any[] = [];
  @Input() initialGridFilters: any;
  @Input() checkboxSelection = false;
  @Input() enableFilter = true;
  @Input() deleteSelection = false;
  @Input() isModalAction = false;
  @Input() itemsPerPage: number = 100;
  @Input() domLayout = 'autoHeight';
  @Input() customFrameworkComponents: any;
  @Input() modalType: ComponentType<any>;
  @Output() cellClick = new EventEmitter<CellClickedData>();
  @Output() deleteClick = new EventEmitter<any>();
  @Output() selectedRowsAction = new EventEmitter<SelectedRowsAction>();
  @Output() pageChange = new EventEmitter<number>();
  @Output() filterChanged = new EventEmitter<any>();
  @Output() sortChanged = new EventEmitter<any>();

  public gridApi: any;
  public defaultColDef: any;
  public rowSelection: any;
  public frameworkComponents = {
    customNoRowsOverlay: EmptyStateComponent,
  };

  public paginationConfig: PaginationInstance = {
    id: this.id,
    itemsPerPage: this.itemsPerPage,
    currentPage: 1,
    totalItems: 0,
  };

  public customFilterParams = {
    filterOptions: ['contains'],
    textCustomComparator: () => true,
    debounceMs: 1000,
  };

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initializeDefaultConfig();
    this.frameworkComponents = {
      customNoRowsOverlay: EmptyStateComponent,
      ...this.customFrameworkComponents,
    };
  }

  private initializeDefaultConfig() {
    this.selectedRows = [];
    this.defaultColDef = {
      editable: false,
      sortable: true,
      minWidth: 100,
      filter: this.enableFilter,
      floatingFilter: this.enableFilter,
      resizable: true,
      filterParams: this.customFilterParams,
    };
  }

  public onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.initializeGridFilters();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (this.columnDefs.length) {
      this.columnDefs[0] = {
        headerCheckboxSelection: this.checkboxSelection,
        checkboxSelection: this.checkboxSelection,
        suppressMenu: true,
        filter: false,
        sortable: false,
        ...this.columnDefs[0],
      };
    }

    if (simpleChanges?.id?.currentValue) {
      this.paginationConfig.id = simpleChanges.id.currentValue;
    }

    if (simpleChanges?.itemsPerPage?.currentValue) {
      this.paginationConfig.itemsPerPage = simpleChanges.itemsPerPage.currentValue;
    }

    if (simpleChanges?.totalItems?.currentValue) {
      this.paginationConfig.totalItems = simpleChanges.totalItems.currentValue;
    }

    if (simpleChanges?.currentPage?.currentValue) {
      this.paginationConfig.currentPage = simpleChanges.currentPage.currentValue;
    }
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  private initializeGridFilters() {
    if (this.initialGridFilters) {
      Object.keys(this.initialGridFilters).forEach((key) => {
        const filterComponent = this.gridApi.getFilterInstance(key);
        if (filterComponent) {
          filterComponent.setModel({
            filter: this.initialGridFilters[key],
            filterType: 'text',
            type: 'contains',
          });
        }
      });
      this.gridApi.onFilterChanged();
    }
  }

  public onSelectionChanged() {
    this.selectedRows = this.gridApi.getSelectedRows();
  }

  public onDeleteClicked() {
    this.rowsSelectionAction();
  }

  public rowsSelectionAction() {
    const rows = this.gridApi.getSelectedRows();
    this.selectedRowsAction.emit({ rows, action: this.selectedActionKey });
  }

  public csvExport() {
    if (this.selectedRows.length) {
      this.gridApi.exportDataAsCsv({ onlySelected: true });
    } else {
      this.gridApi.exportDataAsCsv();
    }
  }

  public excelExport() {
    if (this.selectedRows.length) {
      this.gridApi.exportDataAsExcel({ onlySelected: true });
    } else {
      this.gridApi.exportDataAsExcel();
    }
  }

  public onCellClicked(params: any) {
    const column = params.column.colId;
    const row = params.data;
    (window as any).dataLayer.push({
      event: 'Click',
      action: 'click',
      label: row._id,
      openInNewTab: params.event.metaKey || params.event.ctrlKey,
      category: 'table-row',
    });
    this.cellClick.emit({ column, row, value: row[column] });
  }

  public onFilterChanged() {
    const filterModel = this.gridApi.getFilterModel();
    const gridFilters: { [id: string]: string } = {};

    Object.keys(filterModel).forEach((key: string) => {
      gridFilters[key] = this.normalizeFilterValue(key, filterModel[key].filter as string);
    });

    this.filterChanged.emit(gridFilters);
  }

  public normalizeFilterValue(key: string, value: string) {
    if (key === 'firstCheckDate' || 'startDate') {
      value = value.replace(/\//g, '-');
    }
    return value.toLowerCase();
  }

  public onSortChanged() {
    const sortModel = this.gridApi.getSortModel();
    const gridSort: { [id: string]: string } = {};

    sortModel.map((item: any) => {
      gridSort[item.colId] = item.sort;
    });

    this.sortChanged.emit(gridSort);
  }

  public onPageChange(page: number): void {
    this.paginationConfig = { ...this.paginationConfig, currentPage: page };
    this.pageChange.emit(page);
  }

  public getRowNodeId(data: any) {
    return data._id;
  }
}
