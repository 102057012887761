<nb-layout>
  <nb-layout-header fixed *ngIf="this.authService.isLoggedIn()" class="layout-main-header">
    <div class="container d-flex justify-content-between align-items-center">
      <a routerLink="clients" ga-action="click" ga-category="header" ga-label="logo">
        <img width="120px" class="logo" src="assets/icons/{{ theme }}-logo.svg" alt="Shiftpixy Logo" />
      </a>

      <div class="d-none d-md-block">
        <div class="header-right-side">
          <shiftpixy-navigation></shiftpixy-navigation>

          <nb-form-field class="me-4 ms-auto shifters-search">
            <nb-icon nbPrefix icon="search" pack="eva"></nb-icon>
            <input
              type="text"
              data-test="searchByShifterEmailOrNameField"
              [formControl]="searchClientsField"
              nbInput
              fullWidth
              placeholder="Search for Shifter by name or email"
              [nbAutocomplete]="shiftersSearch"
            />
            <nb-autocomplete #shiftersSearch>
              <nb-option-group
                id="searchResults"
                [title]="searchDisplayMessage"
                [class.hidden]="searchClientsField.value === ''"
              >
                <nb-option
                  *ngFor="let shifter of foundShifters$ | async"
                  [value]="shifter.name"
                  (click)="onShifterClick(shifter, $event)"
                  class="pl-4"
                >
                  {{ shifter.name }}
                  <br />
                  ({{ shifter.email }})
                </nb-option>
              </nb-option-group>
            </nb-autocomplete>
          </nb-form-field>
          <div class="me-4 ms-auto flex-shrink-1">
            <button
              nbButton
              ga-action="click"
              ga-category="header"
              ga-label="toggle-theme"
              (click)="toggleTheme()"
              [attr.Data-Test]="'Toggle_Theme'"
            >
              <nb-icon [icon]="isDarkTheme() ? 'moon' : 'moon-outline'"></nb-icon>
            </button>
          </div>
          <div class="flex-shrink-1">
            <button
              nbButton
              appearance="outline"
              (click)="logOut()"
              status="primary"
              ngDefaultControl
              ga-action="click"
              ga-category="header"
              ga-label="log-out"
              [attr.Data-Test]="'Log_Out'"
            >
              Log Out
            </button>
          </div>
        </div>
      </div>

      <div class="d-block d-md-none text-right">
        <button nbButton status="primary" outline (click)="toggleSidebar()">
          <nb-icon icon="menu-outline"></nb-icon>
        </button>
        <nb-sidebar fixed state="collapsed">
          <nb-form-field class="mb-2 shifters-search">
            <nb-icon nbPrefix icon="search" pack="eva"></nb-icon>
            <input
              type="text"
              [formControl]="searchClientsField"
              nbInput
              fullWidth
              placeholder="Search for Shifters"
              [nbAutocomplete]="shiftersSearch"
            />
            <nb-autocomplete #shiftersSearch>
              <nb-option-group
                id="searchResults"
                [title]="searchDisplayMessage"
                [class.hidden]="searchClientsField.value === ''"
              >
                <nb-option
                  *ngFor="let shifter of foundShifters$ | async"
                  [value]="shifter.name"
                  (click)="onShifterClick(shifter, $event)"
                  class="pl-4"
                >
                  {{ shifter.name }}
                  <br />
                  ({{ shifter.email }})
                </nb-option>
              </nb-option-group>
            </nb-autocomplete>
          </nb-form-field>
          <nb-list>
            <nb-list-item *ngFor="let page of rootMenuPages">
              <a
                nbButton
                ghost
                status="primary"
                ga-action="click"
                ga-category="header"
                [attr.ga-label]="page.path"
                [routerLink]="page.path"
                (click)="collapseSidebar()"
              >
                {{ page.title }}
              </a>
            </nb-list-item>

            <nb-list-item>
              <button
                nbButton
                ghost
                status="primary"
                ga-action="click"
                ga-category="header"
                ga-label="toggle-theme"
                (click)="toggleTheme(); collapseSidebar()"
              >
                Toggle theme
              </button>
            </nb-list-item>
            <nb-list-item>
              <button
                nbButton
                ghost
                status="primary"
                (click)="logOut(); collapseSidebar()"
                status="primary"
                ngDefaultControl
                ga-action="click"
                ga-category="header"
                ga-label="log-out"
              >
                Log Out
              </button>
            </nb-list-item>
          </nb-list>
        </nb-sidebar>
      </div>
    </div>
  </nb-layout-header>

  <nb-layout-header subheader *ngIf="client" class="layout-header-menu">
    <div class="container d-flex justify-content-space-between align-items-center px-0">
      <div class="flex-grow-1">
        <button
          nbButton
          status="control"
          class="text-primary border border-primary"
          shape="round"
          size="small"
          [routerLink]="routes.clientsPage"
          [attr.Data-Test]="'Back_Page'"
        >
          <nb-icon icon="arrow-back-outline"></nb-icon>
        </button>
        <a nbButton ghost class="client-name" [routerLink]="routes.clientsDetails">
          <h6 class="text-primary mb-0">Client - {{ client.name }}</h6>
        </a>
      </div>
      <div class="flex-grow-1 subheader-menu">
        <a
          nbButton
          ghost
          [size]="isMobile ? 'small' : 'medium'"
          ga-action="click"
          ga-category="subheader"
          ga-label="profile"
          [routerLink]="routes.clientsDetails"
          routerLinkActive="active-link"
          [attr.Data-Test]="'Profile_Tab'"
        >
          Profile
        </a>
        <a
          nbButton
          ghost
          [size]="isMobile ? 'small' : 'medium'"
          ga-action="click"
          ga-category="subheader"
          ga-label="worksites"
          routerLink="worksites"
          routerLinkActive="active-link"
          [attr.Data-Test]="'Worksite_Tab'"
        >
          Worksites
        </a>
        <a
          nbButton
          ghost
          [size]="isMobile ? 'small' : 'medium'"
          ga-action="click"
          ga-category="subheader"
          ga-label="managers"
          routerLink="managers"
          routerLinkActive="active-link"
          [attr.Data-Test]="'Manager_Tab'"
        >
          Managers
        </a>
        <a
          nbButton
          ghost
          [size]="isMobile ? 'small' : 'medium'"
          ga-action="click"
          ga-category="subheader"
          ga-label="shifters"
          routerLink="shifters"
          routerLinkActive="active-link"
          [attr.Data-Test]="'Shifter_Tab'"
        >
          Shifters
        </a>
      </div>

      <div class="flex-grow-1"></div>
    </div>
  </nb-layout-header>

  <nb-layout-header subheader *ngIf="backButtonObject" class="layout-header-menu">
    <div class="container d-flex justify-content-space-between align-items-center px-0">
      <div class="flex-grow-1">
        <button
          nbButton
          status="control"
          class="text-primary border border-primary"
          shape="round"
          size="small"
          [routerLink]="backButtonObject.route"
          [attr.Data-Test]="'Back_Page'"
        >
          <nb-icon icon="arrow-back-outline"></nb-icon>
        </button>
        <a nbButton ghost class="client-name" [routerLink]="backButtonObject.route">
          <h6 class="text-primary mb-0">{{ backButtonObject.text }}</h6>
        </a>
      </div>
    </div>
  </nb-layout-header>

  <nb-layout-column class="p-0">
    <router-outlet></router-outlet>
  </nb-layout-column>
</nb-layout>
