<button
  nbButton
  appearance="ghost"
  [nbPopover]="filterList"
  nbPopoverTrigger="noop"
  (click)="togglePopover($event)"
  nbPopoverPlacement="bottom"
  nbPopoverClass="filter"
>
  <span>Status</span>
  <nb-icon [icon]="icon"></nb-icon>
</button>

<ng-template #filterList>
  <div class="filter-list p-4">
    <div class="checkboxes">
      <nb-checkbox
        *ngFor="let filter of filters"
        [checked]="selectedFilters[filter.index]"
        (checkedChange)="setFilter(filter)"
        [disabled]="filter.disabled"
      >
        {{ filter.disabled ? 'Disabled' : filter.text }}
      </nb-checkbox>
    </div>
    <div class="mt-3 d-flex justify-content-center">
      <button nbButton class="text-capitalize" appearance="ghost" status="primary" (click)="togglePopover($event)">
        Done
      </button>
    </div>
  </div>
</ng-template>
