<div class="row mt-6" *ngIf="questions">
  <ng-container *ngFor="let question of questions" [formGroup]="form">
    <div *ngIf="question.type" class="mb-3" [ngClass]="question.class">
      <label
        *ngIf="question.type !== 'toggle' && question.type !== 'radio'"
        class="mb-2 d-block fw-bold"
        [attr.for]="(formName || 'base') + question.key"
      >
        {{ question.label }}
        <span *ngIf="question.validators.includes(requiredValidator)">*</span>
      </label>
      <label *ngIf="question.type === 'radio'" class="fw-bold c-block" [attr.for]="(formName || 'base') + question.key">
        {{ question.label }}
        <span *ngIf="question.validators.includes(requiredValidator)">*</span>
      </label>

      <div [ngSwitch]="question.type">
        <input
          nbInput
          *ngSwitchCase="['text', 'number'].includes(question.type) ? question.type : ''"
          [formControl]="form.get(question.key)"
          [id]="(formName || 'base') + question.key"
          [type]="question.type"
          [mask]="question.mask"
          fullWidth
          [placeholder]="question.placeholder || ''"
          [attr.readOnly]="question.readonly"
          [attr.autocomplete]="question.autoComplete"
          [attr.Data-Test]="question.dataTest"
          [attr.inputmode]="question.keyboardType"
          [attr.autocapitalize]="question.autoCapitalize"
          [status]="showErrorMessage(question) ? 'warning' : 'basic'"
        />

        <nb-form-field *ngSwitchCase="'text-w-icon'">
          <nb-icon *ngIf="question.icon" nbPrefix [icon]="question.icon" [pack]="question.iconPack"></nb-icon>
          <input
            nbInput
            [formControl]="form.get(question.key)"
            [id]="(formName || 'base') + question.key"
            [type]="question.type"
            [mask]="question.mask"
            fullWidth
            [placeholder]="question.placeholder || ''"
            [attr.autocomplete]="question.autoComplete"
            [attr.Data-Test]="question.dataTest"
            [attr.inputmode]="question.keyboardType"
            [attr.autocapitalize]="question.autoCapitalize"
            [status]="showErrorMessage(question, question.keyboardType === 'numeric') ? 'warning' : 'basic'"
          />
        </nb-form-field>

        <ng-container *ngSwitchCase="'autocomplete-address'">
          <pixy-autocomplete-address
            [addressForm]="form.get(question.key)"
            [placeholder]="question.placeholder || ''"
            [country]="question.country"
            (addressDetails)="getAddressDetails($event)"
            [showError]="showErrorMessage(question)"
          ></pixy-autocomplete-address>
        </ng-container>

        <ng-container *ngSwitchCase="'date'">
          <input
            nbInput
            fullWidth
            onKeyDown="return false"
            class="hide-caret"
            [placeholder]="question.placeholder || ''"
            [formControl]="form.get(question.key)"
            [nbDatepicker]="datePicker"
            [attr.autocomplete]="question.autoComplete"
            [attr.Data-Test]="question.dataTest"
            [id]="(formName || 'base') + question.key"
            [status]="showErrorMessage(question) ? 'warning' : 'basic'"
          />
          <nb-datepicker #datePicker></nb-datepicker>
        </ng-container>

        <nb-select
          fullWidth
          nbSelect
          [id]="(formName || 'base') + question.key"
          *ngSwitchCase="'select'"
          [formControl]="form.get(question.key)"
          [attr.autocomplete]="question.autoComplete"
          [attr.Data-Test]="question.dataTest"
          [placeholder]="question.placeholder"
          [status]="showErrorMessage(question) ? 'warning' : 'basic'"
        >
          <nb-select-label *ngIf="getSelectedOption(question, 'icon')">
            <nb-icon
              class="me-3"
              nbPrefix
              [icon]="getSelectedOption(question, 'icon')"
              [pack]="getSelectedOption(question, 'iconPack') || 'eva'"
            ></nb-icon>
            {{ getSelectedOption(question, 'value') }}
          </nb-select-label>
          <nb-option *ngFor="let opt of question.options" [value]="opt.key">
            <div class="d-flex align-items-center">
              <nb-icon
                *ngIf="opt.icon"
                class="me-3"
                nbPrefix
                [icon]="opt.icon"
                [pack]="opt.iconPack || 'eva'"
              ></nb-icon>
              {{ opt.value }}
              <span *ngIf="opt.secondaryValue" class="text-primary ms-2">{{ opt.secondaryValue }}</span>
            </div>
          </nb-option>
        </nb-select>

        <nb-select
          fullWidth
          nbSelect
          multiple
          [id]="(formName || 'base') + question.key"
          *ngSwitchCase="'multi-select'"
          [formControl]="form.get(question.key)"
          [attr.autocomplete]="question.autoComplete"
          [attr.Data-Test]="question.dataTest"
          [placeholder]="question.placeholder"
          [status]="showErrorMessage(question) ? 'warning' : 'basic'"
        >
          <nb-option *ngFor="let opt of question.options" [value]="opt.key">
            <div class="d-flex align-items-center">
              <nb-icon
                *ngIf="opt.icon"
                class="me-3"
                nbPrefix
                [icon]="opt.icon"
                [pack]="opt.iconPack || 'eva'"
              ></nb-icon>
              {{ opt.value }}
              <span *ngIf="opt.secondaryValue" class="text-primary ms-2">{{ opt.secondaryValue }}</span>
            </div>
          </nb-option>
        </nb-select>

        <ng-container>
          <nb-select
            nbSelect
            *ngSwitchCase="'url-select'"
            class="col-4 col-md-2 col-lg-1 custom-select"
            [formControl]="form.get(question.key)"
            [id]="(formName || 'base') + question.key"
            [attr.autocomplete]="question.autoComplete"
            [attr.Data-Test]="question.dataTest"
            [placeholder]="question.placeholder"
          >
            <nb-option *ngFor="let opt of question.options" [value]="opt.key">
              <div>
                {{ opt.value }}
              </div>
            </nb-option>
          </nb-select>
          <input
            fullWidth
            nbInput
            *ngSwitchCase="'url-protocol'"
            class="col-4 col-md-2 col-lg-1 protocol"
            [formControl]="form.get(question.key)"
            [id]="(formName || 'base') + question.key"
            [type]="question.type"
            [mask]="question.mask"
            [placeholder]="question.placeholder || ''"
            [readonly]="'readonly'"
            [attr.inputmode]="question.keyboardType"
            [attr.autocapitalize]="question.autoCapitalize"
            [status]="'info'"
          />
          <input
            fullWidth
            nbInput
            *ngSwitchCase="'url-text'"
            [formControl]="form.get(question.key)"
            [id]="(formName || 'base') + question.key"
            [type]="question.type"
            [mask]="question.mask"
            [placeholder]="question.placeholder || ''"
            [attr.autocomplete]="question.autoComplete"
            [attr.Data-Test]="question.dataTest"
            [attr.inputmode]="question.keyboardType"
            [attr.autocapitalize]="question.autoCapitalize"
            [status]="showErrorMessage(question) ? 'warning' : 'basic'"
          />
        </ng-container>

        <nb-toggle
          *ngSwitchCase="'toggle'"
          [formControl]="form.get(question.key)"
          labelPosition="start"
          [attr.Data-Test]="question.dataTest"
        >
          <div class="toggle-text">
            {{ question.label }}
            <span *ngIf="question.validators.includes(requiredValidator)">*</span>
          </div>
        </nb-toggle>

        <nb-radio-group
          *ngSwitchCase="'radio'"
          name="{{ question.key }}"
          [formControl]="form.get(question.key)"
          class="items-rows"
        >
          <nb-radio *ngFor="let opt of question.options" [value]="opt.key" class="radio-opt">
            {{ opt.value }}
          </nb-radio>
        </nb-radio-group>

        <pixy-quick-tag
          *ngSwitchCase="'quick-tag'"
          [positions]="question.positions"
          [formControl]="form.get(question.key)"
          [attr.Data-Test]="question.dataTest"
        ></pixy-quick-tag>
      </div>

      <div *ngIf="showErrorMessage(question)" class="text-danger">
        {{ question.errorMessage || 'This is a required field' }}
      </div>
    </div>
  </ng-container>
</div>
