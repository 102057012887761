import { MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AddressDetails } from '@shiftpixy/data';
import { lastValueFrom } from 'rxjs';

const timezoneApiUrl = 'https://maps.googleapis.com/maps/api/timezone/json?';
const googleMapsApiKey = 'AIzaSyB5FsNxRqD6kVZgbNdss1sXMAQFep_Y_C8';

@UntilDestroy()
@Component({
  selector: 'pixy-autocomplete-address',
  templateUrl: './autocomplete-address.component.html',
  styleUrls: ['./autocomplete-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteAddressComponent implements AfterViewInit, OnChanges {
  @ViewChild('addressInput') addressInput: any;
  @Input() country = 'US';
  @Input() addressForm = new FormControl();
  @Input() placeholder = 'Street Address';
  @Input() showError = false;
  @Output() addressDetails: EventEmitter<AddressDetails> = new EventEmitter();
  afterViewInitWasTriggered = false;
  autocomplete: any;

  constructor(private http: HttpClient, private mapsAPILoader: MapsAPILoader) {}

  ngOnChanges(): void {
    this.updateLocation();
  }

  ngAfterViewInit(): void {
    this.getPlaceAutocomplete();
    this.afterViewInitWasTriggered = true;
  }

  private updateLocation(): void {
    if (this.afterViewInitWasTriggered) {
      this.getPlaceAutocomplete();
    }
  }

  private async getPlaceAutocomplete() {
    await this.mapsAPILoader.load();

    this.autocomplete = new google.maps.places.Autocomplete(this.addressInput.nativeElement, {
      componentRestrictions: { country: this.country },
      types: ['geocode'],
      fields: ['address_components', 'geometry'],
    });

    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      this.emitAddressDetails(this.autocomplete.getPlace());
    });
  }

  private async getAddressDetailsFromPlaceData(place: any): Promise<AddressDetails> {
    const data: { [id: string]: string } = {};
    place.address_components.forEach((component: any) => {
      component.types.forEach((type: any) => {
        data[type] = type === 'administrative_area_level_1' ? component.short_name : component.long_name;
      });
    });
    const streetAddress = `${data['street_number'] || ''} ${data['route'] || ''}`.trim();
    const county = data['administrative_area_level_2'] || '';
    const city = data['locality'] || '';
    const country = data['country'] || '';
    const state = data['administrative_area_level_1'] || '';
    const zipCode = data['postal_code'] || '';
    const latitude = place.geometry.location.lat() || null;
    const longitude = place.geometry.location.lng() || null;
    const timezone = latitude && longitude ? await this.getTimeZoneInformation(latitude, longitude) : '';
    return { streetAddress, county, city, country, state, zipCode, latitude, longitude, timezone };
  }

  private async getTimeZoneInformation(latitude: number, longitude: number): Promise<string> {
    const timezoneRequestUrl = this.getTimeZoneRequestUrl(latitude, longitude);

    const request = this.http.get(timezoneRequestUrl);

    const result = await (<any>lastValueFrom(request));

    return result['timeZoneId'] || '';
  }

  private getTimeZoneRequestUrl(latitude: number, longitude: number): string {
    const timestamp = Math.trunc(new Date().getTime() / 1000);
    return `${timezoneApiUrl}location=${latitude},${longitude}&timestamp=${timestamp}&key=${googleMapsApiKey}`;
  }

  private async emitAddressDetails(place: any): Promise<void> {
    const addressDetails: AddressDetails = await this.getAddressDetailsFromPlaceData(place);
    this.addressDetails.emit(addressDetails);
  }
}
