import { map } from 'rxjs/operators';
import { AddressComponent } from '../address/address.component';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { DateRangeComponent } from '../date-range/date-range.component';
import { DateComponent } from '../date/date.component';
import { InputComponent } from '../input/input.component';
import { RadioComponent } from '../radio/radio.component';
import { SelectComponent } from '../select/select.component';
import { TaggerComponent } from '../tagger/tagger.component';
import { TextareaComponent } from '../textarea/textarea.component';
import { ToggleComponent } from '../toggle/toggle.component';
import { WrapperComponent } from './wrapper.component';

export const urlRegex = new RegExp(
  '^' +
    // protocol identifier (optional)
    // short syntax // still required
    '(?:(?:(?:https?|ftp):)?\\/\\/)' +
    // user:pass BasicAuth (optional)
    '(?:\\S+(?::\\S*)?@)?' +
    '(?:' +
    // IP address exclusion
    // private & local networks
    '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
    '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
    '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
    // IP address dotted notation octets
    // excludes loopback network 0.0.0.0
    // excludes reserved space >= 224.0.0.0
    // excludes network & broadcast addresses
    // (first & last IP address of each class)
    '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
    '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
    '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
    '|' +
    // host & domain names, may end with dot
    // can be replaced by a shortest alternative
    // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
    '(?:' +
    '(?:' +
    '[a-z0-9\\u00a1-\\uffff]' +
    '[a-z0-9\\u00a1-\\uffff_-]{0,62}' +
    ')?' +
    '[a-z0-9\\u00a1-\\uffff]\\.' +
    ')+' +
    // TLD identifier name, may end with dot
    '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)' +
    ')' +
    // port number (optional)
    '(?::\\d{2,5})?' +
    // resource path (optional)
    '(?:[/?#]\\S*)?' +
    '$',
  'i'
);

export const adminEmailList = [
  { key: 'Lucas.Saperi@shiftpixy.com', value: 'Lucas.Saperi@shiftpixy.com' },
  { key: 'Felipe.delima@shiftpixy.com', value: 'Felipe.delima@shiftpixy.com' },
  { key: 'kristina.danbara@shiftpixy.com', value: 'kristina.danbara@shiftpixy.com' },
  { key: 'kamron.ahmed@shiftpixy.com', value: 'kamron.ahmed@shiftpixy.com' },
  { key: 'Julio.Sotelo@shiftpixy.com', value: 'Julio.Sotelo@shiftpixy.com' },
  { key: 'Juan.Mitchell@shiftpixy.com', value: 'Juan.Mitchell@shiftpixy.com' },
  { key: 'dev@shiftpixy.com', value: 'dev@shiftpixy.com' },
];

export const prodAdminEmailList = [
  { key: 'amanda.murphy@shiftpixy.com', value: 'amanda.murphy@shiftpixy.com' },
  { key: 'colby.benbow@shiftpixy.com', value: 'colby.benbow@shiftpixy.com' },
  { key: 'hannah.absher@shiftpixy.com', value: 'hannah.absher@shiftpixy.com' },
  { key: 'jeffrey.ott@shiftpixy.com', value: 'jeffrey.ott@shiftpixy.com' },
  { key: 'denis.monskiy@shiftpixy.com', value: 'denis.monskiy@shiftpixy.com' },
  { key: 'clienthr@shiftpixy.com', value: 'clienthr@shiftpixy.com' },
  { key: 'dev@shiftpixy.com', value: 'dev@shiftpixy.com' },
];

export const payPeriods = [
  {
    key: 'Weekly',
    value: 'Weekly',
  },
  {
    key: 'Bi-Weekly',
    value: 'Bi-Weekly',
  },
  {
    key: 'Semi-Monthly',
    value: 'Semi-Monthly',
  },
  {
    key: 'Monthly',
    value: 'Monthly',
  },
];

export const corporateStructureOptions = {
  us: [
    { key: 'CCorp', value: 'C Corp' },
    { key: 'LLC', value: 'LLC' },
    { key: 'Partnership', value: 'Partnership' },
    { key: 'SoleProprietorship', value: 'Sole Proprietorship' },
    { key: 'SCorp', value: 'S Corp' },
  ],
  ca: [
    { key: 'Corporation', value: 'Corporation' },
    { key: 'GeneralPartnership', value: 'General Partnership' },
    { key: 'LimitedPartnership', value: 'Limited Partnership' },
    { key: 'SoleProprietorship', value: 'Sole Proprietorship' },
  ],
};

export const engagementTypes = [
  {
    key: 'ShiftPixy',
    value: 'ShiftPixy',
  },
  {
    key: 'ReThinkHCM',
    value: 'ReThinkHCM',
  },
  {
    key: 'Client',
    value: 'Client',
  },
  {
    key: 'ShiftableHR',
    value: 'Shiftable HR',
  },
];

export const accessStatuses = [
  {
    key: 'active',
    value: 'Active',
    icon: 'active',
    iconPack: 'pixy',
  },
  {
    key: 'onLeave',
    value: 'On Leave',
    icon: 'onLeave',
    iconPack: 'pixy',
  },
  {
    key: 'terminated',
    value: 'Terminated',
    icon: 'terminated',
    iconPack: 'pixy',
  },
];

export const employmentTypes = [
  {
    key: 'PartTime',
    value: 'Part Time',
  },
  {
    key: 'FullTime',
    value: 'Full Time',
  },
];

export const payGroups = [
  {
    key: 'weekly',
    value: 'Weekly',
  },
  {
    key: 'bi-weekly',
    value: 'Bi-Weekly',
  },
  {
    key: 'semi-monthly',
    value: 'Semi-Monthly',
  },
  {
    key: 'monthly',
    value: 'Monthly',
  },
];

export const payTypes = [
  {
    key: 'Hourly',
    value: 'Hourly',
  },
  {
    key: 'Salary',
    value: 'Salary',
  },
];

export const payMethods = [
  {
    key: 'Live Check',
    value: 'Live Check',
  },
  {
    key: 'Direct Deposit Only',
    value: 'Direct Deposit Only',
  },
  {
    key: 'Pay Card Only',
    value: 'Pay Card Only',
  },
];

export const onboardingTypes = [
  {
    key: 'New',
    value: 'New',
  },
  {
    key: 'Transitional',
    value: 'Transitional',
  },
];

export const genders = [
  {
    key: 'M',
    value: 'Male',
  },
  {
    key: 'F',
    value: 'Female',
  },
  {
    key: 'U',
    value: 'Declined to Respond',
  },
];

export const layoutClass = 'col-12 col-md-6 col-lg-4';

export const wrapperConfig = {
  wrappers: [
    {
      name: 'nebular',
      component: WrapperComponent,
    },
  ],
  types: [
    {
      name: 'address',
      component: AddressComponent,
      wrappers: ['nebular'],
      defaultOptions: {
        className: layoutClass,
        templateOptions: {
          required: true,
          label: 'Address',
          labelClass: 'mb-2',
          error: 'Please select a valid address from the menu',
          placeholder: 'Select your address',
        },
      },
    },
    {
      name: 'checkbox',
      component: CheckboxComponent,
      wrappers: ['nebular'],
      defaultOptions: {
        className: 'col-12',
        templateOptions: {
          required: true,
          labelClass: 'me-3',
          wrapperClass: 'd-flex align-items-center flex-wrap',
          class: 'd-flex ms-2',
        },
      },
    },
    {
      name: 'textarea',
      component: TextareaComponent,
      wrappers: ['nebular'],
      defaultOptions: {
        className: layoutClass,
        templateOptions: {
          labelClass: 'mb-2',
          error: 'Please enter a value',
          placeholder: 'Enter content here',
        },
      },
    },
    {
      name: 'date',
      component: DateComponent,
      wrappers: ['nebular'],
      defaultOptions: {
        className: layoutClass,
        templateOptions: {
          required: true,
          label: 'Date',
          labelClass: 'mb-2',
          placeholder: 'Select Date',
          error: 'Please select a date',
        },
      },
    },
    {
      name: 'date-range',
      component: DateRangeComponent,
      wrappers: ['nebular'],
      defaultOptions: {
        className: layoutClass,
        templateOptions: {
          required: true,
          label: 'Select Date Range',
          labelClass: 'mb-2',
          placeholder: 'Select Date Range',
          error: 'Please select a date range',
        },
      },
    },
    {
      name: 'input',
      component: InputComponent,
      wrappers: ['nebular'],
      defaultOptions: {
        className: layoutClass,
        templateOptions: {
          required: true,
          labelClass: 'mb-2',
          type: 'text',
        },
      },
    },
    {
      name: 'firstName',
      component: InputComponent,
      wrappers: ['nebular'],
      defaultOptions: {
        className: layoutClass,
        templateOptions: {
          required: true,
          labelClass: 'mb-2',
          type: 'text',
          label: 'First Name',
          placeholder: 'First Name',
          error: 'This is a required field',
        },
      },
    },
    {
      name: 'lastName',
      component: InputComponent,
      wrappers: ['nebular'],
      defaultOptions: {
        className: layoutClass,
        templateOptions: {
          required: true,
          labelClass: 'mb-2',
          type: 'text',
          label: 'Last Name',
          placeholder: 'Last Name',
          error: 'This is a required field',
        },
      },
    },
    {
      name: 'phone',
      component: InputComponent,
      wrappers: ['nebular'],
      defaultOptions: {
        className: layoutClass,
        templateOptions: {
          required: true,
          labelClass: 'mb-2',
          type: 'tel',
          label: 'Phone Number',
          error: 'Please enter a valid phone number',
          mask: '(000) 000-0000',
          placeholder: 'Phone Number',
        },
      },
    },
    {
      name: 'ssn',
      component: InputComponent,
      wrappers: ['nebular'],
      defaultOptions: {
        className: layoutClass,
        templateOptions: {
          required: true,
          labelClass: 'mb-2',
          type: 'tel',
          label: 'Social Security',
          placeholder: 'Social security number',
          error: 'Please enter a valid social security number',
          mask: '000-00-0000',
        },
      },
    },
    {
      name: 'email',
      component: InputComponent,
      wrappers: ['nebular'],
      defaultOptions: {
        className: layoutClass,
        templateOptions: {
          required: true,
          labelClass: 'mb-2',
          type: 'email',
          label: 'Email Address',
          placeholder: 'Email address',
          error: 'Please enter a valid email address',
          pattern: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
        },
      },
    },
    {
      name: 'radio',
      component: RadioComponent,
      wrappers: ['nebular'],
      defaultOptions: {
        className: 'col-12',
        templateOptions: {
          required: true,
          label: 'Choose option',
          labelClass: '',
          wrapperClass: 'd-flex align-items-center flex-wrap',
          class: 'd-flex ms-2',
          error: 'Please choose an option',
          placeholder: 'Choose option',
        },
      },
    },
    {
      name: 'select',
      component: SelectComponent,
      wrappers: ['nebular'],
      defaultOptions: {
        className: layoutClass,
        templateOptions: {
          required: true,
          label: 'Select option',
          labelClass: 'mb-2',
          error: 'Please select an option',
          placeholder: 'Select option',
        },
      },
    },
    {
      name: 'tagger',
      component: TaggerComponent,
      wrappers: ['nebular'],
      defaultOptions: {
        className: 'col-12',
        templateOptions: {
          required: true,
          labelClass: 'mb-2',
        },
        hooks: {
          onInit: (field: any) => {
            return field.formControl.valueChanges.pipe(map((value) => (field.parent.model.positions = value)));
          },
        },
      },
    },
    {
      name: 'toggle',
      component: ToggleComponent,
      wrappers: ['nebular'],
      defaultOptions: {
        className: 'col-12',
        templateOptions: {
          required: true,
          wrapperClass: 'd-flex align-items-center flex-wrap',
          error: 'Please make a selection',
          class: 'ms-2',
        },
      },
    },
  ],
};
