import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NbPopoverDirective } from '@nebular/theme';
import { ActionBarIcon } from '@shiftpixy/data';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pixy-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionBarComponent implements OnInit, OnDestroy {
  @Input() public actionIcons: ActionBarIcon[];
  @Input() public emailOptions: any;
  @Input() public rowItem: any;
  @ViewChild('autoInput') public input: any;
  @ViewChildren(NbPopoverDirective) public popovers: any;
  public CONFIRM_TIMEOUT = 1750;
  public currentAction: ActionBarIcon;
  public state: 'show-actions' | 'enter-email' | 'pending' | 'complete' = 'show-actions';
  public filteredOptions$: Observable<string[]>;
  public email: FormControl = new FormControl('', [Validators.email, Validators.required]);
  private subscription: Subscription;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {}
  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public handleClick(action: ActionBarIcon, event: any): void {
    this.currentAction = action;
    (window as any).dataLayer.push({
      event: 'Click',
      action: 'click',
      label: action.title,
      openInNewTab: event.metaKey || event.ctrlKey,
      category: 'action-bar',
    });

    if (action.title === 'Email') {
      this.state = 'enter-email';
      this.filteredOptions$ = of(this.emailOptions);
      this.cdr.detectChanges();
    } else {
      this.doAction();
    }
  }

  public returnToActions(): void {
    this.state = 'show-actions';
    this.cdr.detectChanges();
  }

  private filter(value: string): string[] {
    return this.emailOptions.filter((optionValue: any) => optionValue.toLowerCase().includes(value.toLowerCase()));
  }

  public getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(map((filterString) => this.filter(filterString)));
  }

  public onChange(): void {
    this.filteredOptions$ = this.getFilteredOptions(this.input.nativeElement.value);
    this.cdr.detectChanges();
  }

  public onSelectionChange($event: any): void {
    this.filteredOptions$ = this.getFilteredOptions($event);
    this.cdr.detectChanges();
  }

  public doAction(): void {
    if (this.email.invalid && this.currentAction.title === 'Email') {
      return;
    }

    this.state = 'pending';
    if (typeof this.currentAction.callback === 'function') {
      this.subscription = this.currentAction.callback(this.rowItem)?.subscribe(
        () => {
          this.state = 'complete';
          this.cdr.detectChanges();
          setTimeout(() => {
            this.state = 'show-actions';
            this.cdr.markForCheck();
          }, this.CONFIRM_TIMEOUT);
        },
        (error) => {
          console.log('Error', error);
          this.state = 'show-actions';
          this.cdr.markForCheck();
        }
      );
    } else {
      this.state = 'show-actions';
      this.cdr.detectChanges();
    }
  }
}
