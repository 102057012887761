import { ComponentType } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { DataTableColumn } from '@shiftpixy/data';
@Component({
  selector: 'pixy-modal-trigger',
  templateUrl: './modal-trigger.component.html',
  styleUrls: ['./modal-trigger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalTriggerComponent implements OnInit {
  @Input() itemsList: any = [];
  @Input() addedItemsList: any = [];
  @Input() itemTitle = '';
  @Output() selectedItems: EventEmitter<any> = new EventEmitter<any>();
  @Input() tableColumns: DataTableColumn[] = [];
  @Input() modalHeaderText: '';
  @Input() excludeFromSearchList: any[];
  @Input() modalType: ComponentType<any>;
  @Input() addModal = false;
  @Input() disabled = false;
  constructor(private dialogService: NbDialogService) {}

  ngOnInit(): void {}

  public open() {
    this.dialogService
      .open(this.modalType, {
        context: {
          items: this.itemsList,
          tableColumns: this.tableColumns,
          searchPlaceholder: this.itemTitle,
          selectedItems: this.addedItemsList,
          headerText: this.modalHeaderText,
          excludeFromSearchList: this.excludeFromSearchList,
        },
      })
      .onClose.subscribe((itemsAdded) => {
        if (itemsAdded) {
          this.selectedItems.emit(itemsAdded.map((item: any) => item._id));
        }
      });
  }
}
