<div class="container-table w-100 h-100" [nbSpinner]="isLoading" nbSpinnerSize="giant" nbSpinnerStatus="primary">
  <div class="w-100 h-100">
    <div
      *ngIf="rowData?.length > 0 && (checkboxSelection || deleteSelection || isModalAction)"
      class="w-100 h-100 d-flex justify-content-end my-2"
    >
      <button
        *ngIf="checkboxSelection && !deleteSelection && isModalAction && selectedActionLabel"
        nbButton
        size="small"
        status="primary"
        [appearance]="'outline'"
        [disabled]="!selectedRows.length"
        class="me-2"
        (click)="rowsSelectionAction()"
      >
        {{ selectedActionLabel }}
      </button>
      <button
        *ngIf="checkboxSelection && deleteSelection && !isModalAction"
        nbButton
        size="small"
        status="warning"
        [appearance]="'outline'"
        [disabled]="!selectedRows.length"
        class="me-2"
        (click)="onDeleteClicked()"
      >
        Delete
      </button>
    </div>

    <ag-grid-angular
      class="ag-theme-alpine w-100"
      [domLayout]="domLayout"
      [rowData]="rowData | pagination: paginationConfig"
      [suppressCsvExport]="true"
      [suppressExcelExport]="true"
      [columnDefs]="columnDefs"
      rowSelection="multiple"
      suppressRowClickSelection
      (gridReady)="onGridReady($event)"
      [defaultColDef]="defaultColDef"
      (selectionChanged)="onSelectionChanged()"
      (cellClicked)="onCellClicked($event)"
      (selectedRowsAction)="onDeleteClicked()"
      (filterChanged)="onFilterChanged()"
      (sortChanged)="onSortChanged()"
      [immutableData]="true"
      [getRowNodeId]="getRowNodeId"
      [frameworkComponents]="frameworkComponents"
      [attr.checkboxSelection]="checkboxSelection"
      noRowsOverlayComponent="customNoRowsOverlay"
    ></ag-grid-angular>

    <div class="row justify-content-center mt-4 mb-6">
      <pixy-pagination [id]="paginationConfig.id" (pageChange)="onPageChange($event)"></pixy-pagination>
    </div>
  </div>
</div>
