import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { FeatureFlagService } from '../../../services/feature-flag/feature-flag.service';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { StorageService } from '../../../services/storage/storage.service';

export interface LoginFormData {
  username: String;
  password: String;
}

export const SIGNIN_ENDPOINT = 'internaluser/signin';
export const SIGNIN_AZURE_ENDPOINT = 'user/signin/exchange';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private features: any = {};
  constructor(
    private featureFlagService: FeatureFlagService,
    private http: HttpClient,
    private router: Router,
    private storage: StorageService,
    private navigationService: NavigationService
  ) {}

  login(postData: LoginFormData) {
    this.storage.setAdminEmail(postData.username.toString());
    this.featureFlagService.setFeatureFlags();
    return this.http.post(environment.apiUrl + SIGNIN_ENDPOINT, postData).pipe(
      tap((resp: any) => this.storage.setToken(resp.message.token, resp.message.expiresAt)),
      map((resp: any) => resp.message),
      catchError((error: Error) => {
        throw error;
      })
    );
  }

  async logout() {
    this.router.navigate(['login']).then(async () => {
      this.storage.clear();
      this.storage.removeClientId();
      await Auth.signOut({ global: true });
    });
  }

  isLoggedIn() {
    const expiryTime = new Date(this.storage.getTokenExpireKey());
    const currentTime = new Date();
    const isTokenValid = currentTime > expiryTime ? false : true;

    if (isTokenValid) {
      this.featureFlagService.setFeatureFlags();

      return true;
    } else {
      this.storage.removeToken();
      return false;
    }
  }

  loginWithSSO() {
    return Auth.federatedSignIn({ customProvider: environment.auth.providers.cognito.customProvider });
  }

  validateAzureLogin(fragment: string): void {
    if (!fragment) {
      return;
    }
    const azureAccessToken = fragment.split('access_token=')[1].split('&')[0];

    const data = {
      token: azureAccessToken,
      profile: 'admin',
    };

    this.http.post(environment.apiUrl + SIGNIN_AZURE_ENDPOINT, data).subscribe((response: any) => {
      this.cognitoGetUser(azureAccessToken);
      this.storage.setToken(response.message.token, response.message.expiresAt);
    });
  }

  cognitoGetUser(azureAccessToken: string) {
    this.http
      .post(
        environment.auth.providers.cognito.apiUrl,
        { AccessToken: azureAccessToken },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-amz-json-1.1',
            'X-Amz-Target': 'AWSCognitoIdentityProviderService.GetUser',
          }),
        }
      )
      .subscribe((result: any) => {
        const userEmail = result.Username.replace(environment.auth.providers.cognito.customProvider + '_', '');
        this.storage.setAdminEmail(userEmail);
        this.featureFlagService.setFeatureFlags();
        this.navigateToDefaultPage();
      });
  }

  public navigateToDefaultPage() {
    if (this.storage.getCachedRoute()) {
      this.router.navigate([this.storage.getCachedRoute()]);
    } else {
      this.featureFlagService.updated.subscribe((updated) => {
        if (updated) this.router.navigate([this.navigationService.getDefaultRoute() || '']);
      });
    }
  }

  public isFeatureEnabled(featureName: string): boolean {
    this.featureFlagService.setFeatureFlags();

    const feature = this.features[featureName];
    if (feature?.hasOwnProperty('userWithId')) {
      return feature.userWithId?.toLowerCase().includes(this.storage.getAdminEmail()?.toLowerCase());
    }
    return feature?.hasOwnProperty('default');
  }
}
