<nb-card class="">
  <nb-card-header class="py-5 d-flex flex-row align-items-center justify-content-between">
    <h5>{{ headerText }}</h5>
    <button nbButton [appearance]="'outline'" size="small" status="primary" (click)="cancel()">
      <nb-icon icon="close"></nb-icon>
    </button>
  </nb-card-header>
  <nb-card-body>
    <div class="py-5">
      <label class="py-1">Recipients</label>
      <nb-select
        fullWidth
        multiple
        placeholder="Recipients"
        [appearance]="'outline'"
        [size]="'small'"
        shape="rectangle"
        [status]="emailListStatus ? 'primary' : 'danger'"
        [formControl]="emailList"
      >
        <nb-option *ngFor="let item of selectedItems" [value]="item">
          {{ item.email }}
        </nb-option>
      </nb-select>
    </div>

    <div>
      <nb-form-field class="">
        <label class="py-1">Title</label>
        <input
          fullWidth
          [status]="titleFieldStatus ? 'primary' : 'danger'"
          nbInput
          [formControl]="titleField"
          [placeholder]="'Title'"
        />
      </nb-form-field>
      <nb-form-field class="py-5">
        <label class="py-1">Message</label>
        <textarea
          fullWidth
          [status]="messageFieldStatus ? 'primary' : 'danger'"
          nbInput
          [formControl]="messageField"
          [placeholder]="'Message'"
        ></textarea>
      </nb-form-field>
      <div class="pb-5">
        <nb-checkbox
          [status]="sendAppNotificationStatus || sendMailStatus ? 'primary' : 'danger'"
          [formControl]="sendMailField"
        >
          Email
        </nb-checkbox>
        <nb-checkbox
          [status]="sendMailStatus || sendAppNotificationStatus ? 'primary' : 'danger'"
          [formControl]="sendAppNotificationField"
        >
          ShiftPixy App Inbox + Notification
        </nb-checkbox>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer class="py-5 d-flex justify-content-end align-items-center">
    <div *ngIf="sendingMessage" class="pending" [ngClass]="{ completed: messageSent }">
      <span class="status">{{ messageSent ? 'Message Sent' : 'Waiting...' }}</span>
    </div>
    <button nbButton [appearance]="'outline'" class="me-4" (click)="cancel()">Cancel</button>
    <button nbButton status="primary" (click)="submit()">Submit</button>
  </nb-card-footer>
</nb-card>
