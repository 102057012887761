import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FeatureFlagService } from '../services/feature-flag/feature-flag.service';
import { NavigationService } from '../services/navigation/navigation.service';
import { StorageService } from '../services/storage/storage.service';

@UntilDestroy()
@Component({
  selector: 'shiftpixy-redirect',
  template: '<div></div>',
})
export class RedirectComponent implements OnInit {
  constructor(
    private router: Router,
    public storage: StorageService,
    private featureFlagService: FeatureFlagService,
    private navigationService: NavigationService
  ) {}

  public ngOnInit(): void {
    this.featureFlagService.setFeatureFlags();
    this.storage.adminEmailSet.subscribe(() => {
      this.checkFeatureRedirect();
    });

    this.featureFlagService.updated.subscribe((updated: boolean) => {
      if (updated) {
        this.checkFeatureRedirect();
      }
    });
  }

  private checkFeatureRedirect() {
    const defaultRoute = this.navigationService.getDefaultRoute();
    if (defaultRoute) {
      this.router.navigate([defaultRoute]);
    }
  }
}
